import React from "react";

const CopySvg = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_774_16864)">
        <path
          d="M10.1048 13.8125C11.7644 13.8125 13.1152 12.4617 13.1152 10.8021V3.54175H14.3548C15.4293 3.54175 16.3027 4.41502 16.3027 5.48958V15.0521C16.3027 16.1266 15.4293 17 14.3548 17H5.50069C4.42613 17 3.55273 16.1266 3.55273 15.0521V13.8125H10.1048Z"
          fill="#E015A2"
        />
        <path
          d="M0.719444 1.94796C0.719444 0.871971 1.59142 0 2.66727 0H10.1048C11.1808 0 12.0527 0.871971 12.0527 1.94796V10.802C12.0527 11.878 11.1808 12.75 10.1048 12.75H2.66727C1.59142 12.75 0.719444 11.878 0.719444 10.802V1.94796Z"
          fill="#E015A2"
        />
      </g>
      <defs>
        <clipPath id="clip0_774_16864">
          <rect
            width="17"
            height="17"
            fill="white"
            transform="matrix(-1 0 0 1 17 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CopySvg;
