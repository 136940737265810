export function hasChildren(item) {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}

export function uniqueID() {
  return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
}
function getSafeRanges(dangerous) {
  let safeRanges = [];
  let ancestorContainer = dangerous.commonAncestorContainer;
  while (ancestorContainer.nodeType != 1) {
    ancestorContainer = ancestorContainer.parentNode;
  }

  // Function to add ranges for text nodes
  const addRange = (node, start, end) => {
    if (!node || node.textContent.trim() === "") return;
    let range = document.createRange();
    range.setStart(node, start);
    range.setEnd(node, end);
    safeRanges.push(range);
  };

  // Get the start and end container text nodes and their respective offsets
  let startContainer = dangerous.startContainer,
    endContainer = dangerous.endContainer,
    startOffset = dangerous.startOffset,
    endOffset = dangerous.endOffset;

  // If the start and end containers are the same, add the range and return
  if (startContainer === endContainer) {
    addRange(startContainer, startOffset, endOffset);
    return safeRanges;
  }

  // Add range for the start container
  addRange(startContainer, startOffset, startContainer.length || 0);

  // Traverse the nodes between start and end containers
  let currentNode = startContainer;
  while (currentNode && currentNode !== endContainer) {
    // Move to the next node, deeply traversing the child nodes
    currentNode = getNextNode(currentNode, ancestorContainer);
    if (currentNode && currentNode.nodeType === 3) {
      // Node is a text node
      let start = 0; // Start at the beginning of the text node
      let end = currentNode.length; // End at the last position of the text node
      if (currentNode === endContainer) end = endOffset; // If it's the end container, set end to the selection end
      addRange(currentNode, start, end);
    }
  }

  return safeRanges;
}

function getNextNode(node, stopAt) {
  // If there are child nodes, return the first child
  if (node.firstChild) return node.firstChild;

  // Otherwise, return the next sibling or recurse up the parent nodes to find the next sibling
  while (node) {
    if (node === stopAt) return null;
    if (node.nextSibling) return node.nextSibling;
    node = node.parentNode;
  }
}

function highlightRange(range, i, color) {
  const newNode = document.createElement("span");
  newNode.setAttribute("style", `background-color: ${color}; display: inline;`);
  range.surroundContents(newNode);
}
export function highlightSelection(color) {
  const userSelection = window.getSelection();
  for (let i = 0; i < userSelection.rangeCount; i++) {
    const range = userSelection.getRangeAt(i);
    const safeRanges = getSafeRanges(range);
    for (let j = 0; j < safeRanges.length; j++) {
      if (safeRanges[j].toString() !== "") {
        removeExistingHighlights(safeRanges[j], color);
        highlightRange(safeRanges[j], j, color);
      }
    }
  }
}

function removeExistingHighlights(range, color) {
  const span = document.createElement("span");
  span.appendChild(range.extractContents());

  // Remove all existing spans within the range
  const existingSpans = span.querySelectorAll("span");
  existingSpans.forEach((existingSpan) => {
    existingSpan.style.backgroundColor = "inherit";
    // existingSpan.removeAttribute("id");
  });
  span.style.backgroundColor = color;
  range.deleteContents();
  range.insertNode(span);
}

export function formatText(text) {
  return text.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
}
