import React from "react";

const CommentsSVG = () => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.666992 9.01187C0.666992 4.62195 4.17533 0.666016 9.01699 0.666016C13.7503 0.666016 17.3337 4.54684 17.3337 8.98683C17.3337 14.1362 13.1337 17.3327 9.00033 17.3327C7.63366 17.3327 6.11699 16.9655 4.90033 16.2477C4.47533 15.989 4.11699 15.797 3.65866 15.9473L1.97533 16.448C1.55033 16.5816 1.16699 16.2477 1.29199 15.797L1.85033 13.9276C1.94199 13.6689 1.92533 13.3934 1.79199 13.1764C1.07533 11.8578 0.666992 10.414 0.666992 9.01187ZM7.91699 9.01187C7.91699 9.60442 8.39199 10.0801 8.98366 10.0885C9.57533 10.0885 10.0503 9.60442 10.0503 9.02021C10.0503 8.42766 9.57533 7.95194 8.98366 7.95194C8.40033 7.9436 7.91699 8.42766 7.91699 9.01187ZM11.7587 9.02021C11.7587 9.60442 12.2337 10.0885 12.8253 10.0885C13.417 10.0885 13.892 9.60442 13.892 9.02021C13.892 8.42766 13.417 7.95194 12.8253 7.95194C12.2337 7.95194 11.7587 8.42766 11.7587 9.02021ZM5.14199 10.0885C4.55866 10.0885 4.07533 9.60442 4.07533 9.02021C4.07533 8.42766 4.55033 7.95194 5.14199 7.95194C5.73366 7.95194 6.20866 8.42766 6.20866 9.02021C6.20866 9.60442 5.73366 10.0801 5.14199 10.0885Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default CommentsSVG;
