import React from "react";
import Appbar from "./../../../../UI/Navbars/Secondary/Secondary";
import MyLibraryNotes from "./Notes/Notes";
import MyLibraryFlashcard from "./Flashcards/Flashcard";
import NotesSvg from "../../../../../assets/images/Subject/NotesTabSvg";
import FlashcardsSvg from "../../../../../assets/images/Subject/FlashcardsTabSvg";


const MyLibrary = () => {
  const myLibraryTabs = [
    {
      name: "notes",
      label: "Notes",
      value: 0,
      icon:NotesSvg,
      content: <MyLibraryNotes />,
    },
    {
      name: "flashcards",
      label: "Flashcards",
      value: 1,
      icon:FlashcardsSvg,
      content: <MyLibraryFlashcard />,
    },
  ];
  return <Appbar tabs={myLibraryTabs} location={"myLibrary"} />;
};

export default MyLibrary;
