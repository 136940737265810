import LearningResources from "./Tabs/LearningResources/LearningResources";
import MyLibrary from "./Tabs/MyLibrary/MyLibrary";
import KeySvg from "../../../assets/images/Subject/KeySvg";
import LibrarSvg from "../../../assets/images/Subject/LibrarySvg";
const primaryTabs = [
  {
    value: 0,
    name: "octilearnlibrary",
    label: "OctiLearn Library",
    icon: KeySvg,
    content: <LearningResources />,
  },
  {
    value: 1,
    name: "myLibrary",
    label: "My Library",
    icon: LibrarSvg,
    content: <MyLibrary />,
  },
];
export default primaryTabs;
