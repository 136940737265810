import Syllabus from './Tabs/Syllabus/Syllabus';
import Notes from './Tabs/Notes/Notes';
import Analytics from './Tabs/Analytics/Analytics';
import FlashCards from './Tabs/Flashcards/FlashCards';
import SyllabasSvg from '../../../../../assets/images/Subject/Syllabas';
import NotesSvg from '../../../../../assets/images/Subject/NotesTabSvg';
import FlashcardsSvg from '../../../../../assets/images/Subject/FlashcardsTabSvg';
import Simulations from './Tabs/Simulations/Simulations';
import Assessment from './Tabs/Assessment/Assessment';
import SimulationSvg from '../../../../../assets/images/Subject/SimulationSvg';
import AssessmentSvg from '../../../../../assets/images/Subject/AssessmentSvg';
import AnalaticsSvg from '../../../../../assets/images/Subject/AnalyticsSvg';

const tabs = [
  {
    name: 'syllabus',
    label: 'Syllabus',
    value: 0,
    icon: SyllabasSvg,
    location: 'octilearnlibrary',
    content: <Syllabus />,
  },

  {
    name: 'notes',
    label: 'Notes',
    value: 1,
    icon: NotesSvg,
    location: 'octilearnlibrary',
    content: <Notes />,
  },

  {
    name: 'flashcards',
    label: 'Flashcards',
    icon: FlashcardsSvg,
    location: 'octilearnlibrary',
    value: 2,
    content: <FlashCards />,
  },

  {
    name: 'assessment',
    label: 'Assessments',
    location: 'octilearnlibrary',
    value: 3,
    icon: AssessmentSvg,
    content: <Assessment />,
  },

  {
    name: 'analytics',
    label: 'Analytics',
    location: 'octilearnlibrary',
    value: 4,
    icon: AnalaticsSvg,
    content: <Analytics />,
  },

  {
    name: 'simulations',
    label: 'Simulations',
    location: 'octilearnlibrary',
    value: 5,
    icon: SimulationSvg,
    content: <Simulations />,
  },
];

export default tabs;
