import { styled } from '@mui/system';
import Button from '../../../../../UI/Button/ButtonComp';
import Stack from './../../../../../UI/Stack/Stack';
import palette from './../../../../../../config/palette';
import { PaletteRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';

export const StackWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  padding: theme.spacing(4),
  borderRadius: theme.spacing(4),
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  boxShadow: '0px 4px 13px 0px rgba(0, 0, 0, 0.06)',
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(2.5),
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    margin: '20px 10px',
    padding: theme.spacing(2),
    paddingRight: '0px',
    borderRadius: '12px',
  },
}));

export const NotesCourseName = styled(Stack)(({ theme }) => ({
  color: 'var(--main-text, #251038)',
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  width: '100%',
}));
export const HeaderButton = styled(Button)(({ theme, isActive }) => ({
  textTransform: 'capitalize',
  fontFamily: 'Inter',
  fontWeight: '400',
  color: isActive ? 'white' : '#11263C',
  cursor: 'pointer',
  background: isActive ? palette.colors.primaryModified : 'white',
  paddingTop: '0.2rem',
  paddingBottom: '0.2rem',
  height: '40px',
  fontSize: '12px',
  fontFeatureSettings: "'clig' off, 'liga' off",

  // minWidth: "145px",
  '&:hover': {
    color: isActive ? 'white' : 'black',
    background: isActive
      ? palette.colors.primaryModified
      : palette.colors.selectedColor,
  },
  [theme.breakpoints.down('sm')]: {
    height: '60px',
    width: '155px',
  },
}));

export const HeaderDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const SearchDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: ' space-between',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
}));
export const TagsDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  overflowX: 'auto',
}));

export const TagsButton = styled(Button)(({ theme, isselected }) => ({
  textTransform: 'capitalize',

  borderRadius: '50px',
  marginRight: '0.4rem',
  marginLeft: '0.4rem',
  paddingTop: '0.2rem',
  paddingBottom: '0.2rem',

  [theme.breakpoints.down('lg')]: {
    margin: theme.spacing(1),
  },
  minWidth: 'max-content',
  width: 'max-content',
  color: isselected ? 'white' : '#624BA2',
  fontFamily: 'Inter',
  fontSize: '14px',
  margin: '10px',
  padding: '3px 8px',
  fontWeight: isselected ? '600' : '400',
  minWidth: '50px',
  backgroundColor: isselected
    ? palette.colors.primaryModified
    : 'rgba(98, 75, 162, 0.20)',
  '&:hover': {
    color: 'unselected',
    backgroundColor: isselected
      ? palette.colors.primaryModified
      : palette.colors.lightColor,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '10px',
    margin: '3px',
  },
}));
export const PhysicsImageContainer = styled('div')(({ theme }) => ({
  height: '170px',
  width: '300px',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    display: 'none !important',
  },
}));
export const PhysicsImgae = styled('img')(({ theme }) => ({
  width: '300px',
  height: '270px',
  objectFit: 'contain',
  marginTop: '-50px',
  overflow: 'hidden',
  objectPosition: 'top-right',
  [theme.breakpoints.down('md')]: {
    display: 'none !important',
  },
}));
export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'top',
  width: '100%',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    paddingRight: '9px',
  },
}));

export const LeftContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginTop: '0px',
  justifyContent: 'space-evenly',
  width: '100%',
}));
export const SearchBarWrapper = styled('div')(({ theme }) => ({
  display: 'none',
  width: '20px',
  height: '30px',
  padding: '3px 8px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  flexShrink: 0,
  cursor: 'pointer',
  borderRadius: '100px',
  border: '1px solid var(--Pink-New-E01512, #E015A2)',
  background: 'var(--Pink-New-E01512, #E015A2)',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
  },
}));
export const SearchBarText = styled(Typography)(({ theme }) => ({
  color: 'var(--violet, #624BA2)',
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '180%' /* 25.2px */,
  marginRight: '10px',
}));

export const DividerWrapper = styled('span')(() => ({
  marginTop: '2px',
}));
