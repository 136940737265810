import { styled } from "@mui/system";
import { Button, Typography } from "@mui/material";

export const BlurredModalContent = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  paddingTop: "20px",
  width: "80vw",
  maxWidth: "500px",
  // height:'30vh',
  borderRadius: "8px",
  boxShadow: "0px 4px 19px 0px rgba(0, 0, 0, 0.26)",
});

export const ModalTextWrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

export const BlurredBackground = styled("div")({
  width: "100%",
  height: "100%",

  backgroundColor: "rgba(217, 217, 217, 0.30)",
  backdropFilter: "blur(9.5px)",
  zIndex: "99999",
});
export const ModalText = styled(Typography)(({ theme }) => ({
  color: "#251038",
  padding: "10px 30px",
  textAlign: "center",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Inter",
  fontSize: "22px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
}));

export const ModalTextWarning = styled(Typography)({
  padding: "10px 30px",
  marginBottom: "20px",
  color: "var(--main-text, #251038)",
  textAlign: "center",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
});

export const ModalButtons = styled(Button)(({ theme, left }) => ({
  background: left ? "#624BA2" : "",
  color: left ? "#ffff" : "#624BA2",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  width: "100%",
  height: "50px",
  borderRadius: "0px",
  borderBottomLeftRadius: left ? "8px" : "0px",
  borderBottomRightRadius: left ? "0px" : "8px",
  "&:hover": {
    color: "#ffff",
    background: "#624BA2",
  },
}));

export const ModalStack = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "5px",
}));

export const Icon = styled('img')(({ opacity }) => ({
  opacity: opacity ? opacity : 1,
}));
