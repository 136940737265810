import { Modal } from "@mui/material";
import React from "react";
import {
  BlurredBackground,
  BlurredModalContent,
  ModalText,
  ModalButtons,
  ModalStack,
  ModalTextWrapper,
  ModalTextWarning,
  Icon
} from "./mobalModal.style";
import GRAPHICS from '../../assets/images/assessment';
import Divider from "@mui/material/Divider";

export default function MobileModal({
  open,
  succes,
  reset,
  close,
  modaltext,
  isDelete,
  warningtext,
  cancelText,
  isCopy,
  okText,
}) {
  return (
    <Modal open={open} sx={{ zIndex: "99999" }}>
      <BlurredBackground>
        <BlurredModalContent>
          <ModalTextWrapper>
            <div onClick={()=> close()} style={{
              textAlign: "right",
              width: "100%",
              marginRight : "40px"
            }}>
          <Icon src={GRAPHICS.CloseIcon} alt="Close" />
          </div>
            {isCopy ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="126"
                height="126"
                viewBox="0 0 126 126"
                fill="none"
              >
                <circle opacity="0.1" cx="63" cy="63" r="63" fill="#E015A2" />
                <path
                  opacity="0.3"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M57.0856 73.3776H70.0757C71.0547 73.3776 71.8665 72.6125 71.8665 71.69C71.8665 70.7674 71.0547 70.0251 70.0757 70.0251H57.0856C56.1066 70.0251 55.2947 70.7674 55.2947 71.69C55.2947 72.6125 56.1066 73.3776 57.0856 73.3776ZM65.1566 58.775H57.0856C56.1066 58.775 55.2947 59.54 55.2947 60.4625C55.2947 61.385 56.1066 62.1275 57.0856 62.1275H65.1566C66.1357 62.1275 66.9476 61.385 66.9476 60.4625C66.9476 59.54 66.1357 58.775 65.1566 58.775ZM82.1976 56.8077C82.7588 56.8016 83.37 56.795 83.9254 56.795C84.5225 56.795 85 57.245 85 57.8075V75.8974C85 81.4776 80.2004 86 74.2785 86H55.2708C49.0623 86 44 81.2525 44 75.4026V51.1475C44 45.5675 48.8235 41 54.7694 41H67.5207C68.1415 41 68.6191 41.4725 68.6191 42.035V49.28C68.6191 53.3975 72.2248 56.7725 76.5946 56.795C77.6154 56.795 78.5152 56.8021 79.3026 56.8084C79.9153 56.8132 80.46 56.8175 80.9404 56.8175C81.2805 56.8175 81.721 56.8128 82.1976 56.8077ZM82.856 53.5235C80.8933 53.5303 78.5794 53.5235 76.915 53.5078C74.2741 53.5078 72.0986 51.458 72.0986 48.9695V43.0385C72.0986 42.0688 73.3474 41.5873 74.0614 42.287C75.3551 43.5544 77.1335 45.2971 78.903 47.0313C80.6643 48.7575 82.4173 50.4754 83.6751 51.7078C84.3726 52.3895 83.8615 53.5213 82.856 53.5235Z"
                  fill="#E015A2"
                />
              </svg>
            ) : isDelete ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="107"
                height="107"
                viewBox="0 0 107 107"
                fill="none"
              >
                <circle
                  opacity="0.1"
                  cx="53.4177"
                  cy="53.4177"
                  r="53.4177"
                  fill="#E015A2"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M37.4668 50.3761V54.6792C37.4668 61.8088 43.2465 67.5885 50.3761 67.5885H54.6792C61.8088 67.5885 67.5885 61.8088 67.5885 54.6792V50.3761C67.5885 43.2465 61.8088 37.4668 54.6792 37.4668H50.3761C43.2465 37.4668 37.4668 43.2465 37.4668 50.3761Z"
                  stroke="#E015A2"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M52.5269 52.5293V61.1355"
                  stroke="#E015A2"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M52.5269 47.1476C51.9338 47.1476 51.4512 46.665 51.4512 46.0719C51.4512 45.4787 51.9338 44.9961 52.5269 44.9961C53.1201 44.9961 53.6027 45.4787 53.6027 46.0719C53.6027 46.665 53.1201 47.1476 52.5269 47.1476Z"
                  fill="#E015A2"
                />
                <path
                  d="M52.5275 43.9219C53.7158 43.9219 54.6791 44.8852 54.6791 46.0734C54.6791 47.2617 53.7158 48.225 52.5275 48.225C51.3393 48.225 50.376 47.2617 50.376 46.0734C50.376 44.8852 51.3393 43.9219 52.5275 43.9219Z"
                  fill="#E015A2"
                />
              </svg>
            ) : (
              ""
            )}
            <ModalText>{modaltext}</ModalText>
            <ModalTextWarning>
              {warningtext
                ? warningtext
                : "If you choose yes, your comment will be deleted permanently."}
            </ModalTextWarning>
          </ModalTextWrapper>

          <Divider />
          <ModalStack>
            <ModalButtons
              left={true}
              onClick={() => {
                succes();
                close();
              }}
            >
              {isDelete ? "Delete" : okText ? okText : "Yes"}
            </ModalButtons>
            <ModalButtons
              left={false}
              onClick={() => {
                reset();
                close();
              }}
            >
              {cancelText ? cancelText : "Cancel"}
            </ModalButtons>
          </ModalStack>
        </BlurredModalContent>
      </BlurredBackground>
    </Modal>
  );
}
