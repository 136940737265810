import { ListItemButton, List } from '@mui/material';
import pallette from './../../../config/palette';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';

export const StyledListContainer = styled(List)(({ theme }) => ({
  width: '100%',
  maxWidth: '360',

  '& .MuiTypography-root': {
    padding: 0,
    fontSize: '13px',
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  '& *': {
    padding: 0,
  },
}));
export const StyledListContainerMobile = styled(List)(({ theme }) => ({
  width: '100%',
  maxWidth: '360',
  '& .MuiTypography-root': {
    padding: 0,
    fontSize: '13px',
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  '& *': {
    padding: 0,
  },
}));

export const StyledItem = styled(ListItemButton)(
  ({ theme, padding, layer, data, id }) => {
    return {
      backgroundColor:
        id === data?.id && data?.isExpanded
          ? ` ${pallette.colors.selectedColor}`
          : layer !== 1
          ? '#F8F8FC'
          : '#FFF',
      borderLeft:
        id === data?.id &&
        data?.isExpanded &&
        `3px solid ${pallette.colors.primaryModified} `,

      '& .ListItemButtonWrapper-expandIconWrapper .MuiSvgIcon-root': {
        color: pallette.colors.yellow,
      },
      minHeight: '55px',
      paddingLeft: padding,
      display: 'flex',
      '&:hover': {
        backgroundColor: 'rgba(98, 75, 162, 0.1)',
      },
      '& .ListItemButtonWrapper-content': {
        marginLeft: theme.spacing(1),
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    };
  }
);
export const StyledItemMobile = styled(ListItemButton)(
  ({ theme, layer, padding, active, selected, open }) => {
    return {
      background:
        selected && layer === 1 && open
          ? `rgba(56, 25, 144, 0.20) !important`
          : selected && layer === 2 && open
          ? 'rgba(56, 25, 144, 0.15) !important'
          : selected && layer === 3
          ? 'rgba(56, 25, 144, 0.10) !important'
          : '#fff !important',
      borderTopLeftRadius: layer === 1 ? '10px' : '0px',
      borderTopRightRadius: layer === 1 ? '10px' : '0px',

      minHeight: '70px',
      paddingLeft: padding,

      borderBottom: '1px solid rgba(229, 99, 189, 0.2)',

      cursor: 'default',
      borderLeft: active ? `3px solid ${pallette.colors.bgColor} ` : null,

      '&:hover': {
        backgroundColor: 'rgba(98, 75, 162, 0.1) !important',
      },

      '& .ListItemButtonWrapper-content': {
        marginLeft: theme.spacing(1),
      },
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
      },
    };
  }
);

export const StyledListBtn = styled(ListItemButton)(({ theme, selected }) => ({
  height: '24px',
  lineHeight: '18px',
  fontVariant: 'small-caps',
  color: '#251038',
  textTransform: 'uppercase',

  opacity: selected ? 1 : 0.2,
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
}));

export const Wrapper = styled(Box)(({ theme, height }) => ({
  height: height,
  overflowY: 'auto',
  width: '100%',
  maxWidth: 360,

  bgcolor: '#fff',
  '&::-webkit-scrollbar': {
    width: '2px',
  },

  /* Track */
  '&::-webkit-scrollbar-track': {
    background: 'rgba(98, 75, 162, 0.1)',
  },

  /* Handle */
  '&::-webkit-scrollbar-thumb': {
    background: '#624BA2',
  },

  /* Handle on hover */
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#624BA2',
  },
}));
export const StyledListItem = styled(ListItemText)(({ theme }) => ({
  '& *': {
    fontSize: '13px',
    fontWeight: 700,
  },
}));
export const StyledListItemMobile = styled(ListItemText)(({ theme }) => ({
  '& *': {
    fontSize: '14px',
    fontWeight: 700,
  },
}));
