import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const Percentage = ({ value }) => {
  return (
    <div>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          variant="determinate"
          value={Math.floor(value) || 0}
          sx={{
            color: 'white',
            background: '#6953A6',
            borderRadius: '100%',
          }}
        />

        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            sx={{
              background: '#6953A6',
              color: 'white',
              width: '90%',
              height: '90%',
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '13px',
              fontWeight: 600,
            }}
          >
            {value || 0}%
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default Percentage;
