import React from "react";

const FlashcardsSvg = ({ color, isMobile, isSelected }) => {

  return (
    <div style={{ marginRight: "0.5rem", marginTop: '2px' }}>
      {isMobile? <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
<path d="M14.2503 11.8705C14.8577 11.8705 15.8283 11.3836 15.8283 10.6763V2.28067C15.8283 1.57338 15.3359 1 14.7285 1H4.83027C4.22287 1 3.73047 1.57338 3.73047 2.28067" stroke={color} stroke-opacity="0.7"/>
<path d="M11.8723 3.15767H2.08723C1.14567 3.15767 0.5 4.01319 0.5 4.91268V13.6978C0.5 14.5972 1.14567 15.4528 2.08723 15.4528H11.8723C12.8138 15.4528 13.4595 14.5972 13.4595 13.6978V4.91268C13.4595 4.01319 12.8138 3.15767 11.8723 3.15767Z" stroke={color} stroke-opacity="0.7"/>
</svg>: ''
}
    </div >
  );
};

export default FlashcardsSvg;

// KeySvg.defaultProps = {
//   color: "#ADB4C5",
// };
