import React from "react";
import RenderEachNoteBlock from "./RenderEachNoteBlock";
import Alert from "../../../../../UI/Alert/Alert";
import ErrorBoundary from "./../../../../../../Errors/ErrorBoundary";
import Loader from "./../../../../../UI/Loader/Loader";

const NoteContent = ({
  subjectName,
  handleResetNotes,
  handleDelete,
  getNotes,
  deleteSingleNote,
  selectedFromList,
  notes,
  course,
  unit,
  setDataLength,
}) => {

  const [groups, setGroups] = React.useState([]);
  const [loading, setLoading] = React.useState(true); 
  
  const buildCombinations = () => {
    let combinations = [];
    let tempNotes = [...notes];
    tempNotes.forEach((note) => {
      const comboAlreadyExists = combinations.find(
        (obj) =>
          obj.chapterId === note.chapterId && obj.snackId === note.snackId
      );
      if (!comboAlreadyExists) {
        combinations.push({ chapterId: note.chapterId, snackId: note.snackId });
      }
    });
    return combinations;
  };

  const groupedContent = (arr) => {

    const groupedContent = arr.map((each) => {
      let obj = {};

      const groupedNotes = notes.filter(
        (note) =>
          note.chapterId === each.chapterId && note.snackId === each.snackId
      );
      if (each.snackId) {
        const getSnack = notes.find((item) => item.snackId === each.snackId);
        obj.snackId = getSnack?.snackId;
        obj.snackName = getSnack?.snackName;
      }

      const getChap = (obj.chapterId = notes.find(
        (item) => item.chapterId === each.chapterId
      ));
      groupedNotes.map((note) => ({ ...note, selected: selectedFromList }));

      obj.chapterId = getChap?.chapterId;
      obj.chapterName = getChap?.chapterName;
      obj.content = groupedNotes;
      return obj;
    });
    setDataLength(groupedContent.sort((a, b) => a.snackId - b.snackId));
    setGroups(groupedContent.sort((a, b) => a.snackId - b.snackId));
    return true;
  };

  React.useEffect(() => {
    const combos = buildCombinations();
    if (combos.length === 0) {
      // case if no combo found
      setGroups([]);
      setLoading(false);
    } else {
      groupedContent(combos);
      setLoading(false); 
    }
  }, [notes, selectedFromList]);

  return (
    <div>
      <ErrorBoundary>
        {groups.length > 0 ? (
          <>
            {groups.map((group, index) => (
              <RenderEachNoteBlock
                subjectName={subjectName}
                handleResetNotes={handleResetNotes}
                handleDelete={handleDelete}
                getNotes={getNotes}
                deleteSingleNote={deleteSingleNote}
                selectedFromList={selectedFromList}
                key={index}
                unit={unit}
                {...group}
              />
            ))}
          </>
        ) : (
          <Loader/>
        )}
      </ErrorBoundary>
    </div>
  );
};

export default NoteContent;
