import React from "react";

const AddQuestion = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.1" cx="15" cy="15" r="15" fill="#E015A2" />
      <path
        d="M17.5779 14.9089L15.5743 14.9089C15.5092 14.9089 15.4565 14.8561 15.4565 14.791L15.4565 12.7875C15.4565 12.462 15.1926 12.1982 14.8672 12.1982C14.5418 12.1982 14.2779 12.462 14.2779 12.7875L14.2779 14.791C14.2779 14.8561 14.2251 14.9089 14.16 14.9089L12.1565 14.9089C11.831 14.9089 11.5672 15.1727 11.5672 15.4982C11.5672 15.8236 11.831 16.0875 12.1565 16.0875L14.16 16.0875C14.2251 16.0875 14.2779 16.1402 14.2779 16.2053L14.2779 18.2089C14.2779 18.5343 14.5418 18.7982 14.8672 18.7982C15.1926 18.7982 15.4565 18.5343 15.4565 18.2089V16.2053C15.4565 16.1402 15.5092 16.0875 15.5743 16.0875L17.5779 16.0875C17.9033 16.0875 18.1672 15.8236 18.1672 15.4982C18.1672 15.1727 17.9033 14.9089 17.5779 14.9089Z"
        fill="#E015A2"
      />
    </svg>
  );
};

export default AddQuestion;
