import { Box } from "@mui/material";
import React from "react";

const StarSvg = ({ show, copy, isMobile }) => {
  return (

    <>
      {isMobile ? <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
        <path d="M13.2657 10.53C13.0499 10.7496 12.9507 11.0673 12.9999 11.3787L13.7407 15.6837C13.8032 16.0486 13.6566 16.4179 13.3657 16.6287C13.0807 16.8475 12.7016 16.8738 12.3907 16.6988L8.6999 14.6775C8.57156 14.6057 8.42906 14.5673 8.28323 14.5629H8.0574C7.97906 14.5751 7.9024 14.6014 7.8324 14.6416L4.14073 16.6725C3.95823 16.7687 3.75156 16.8029 3.54906 16.7688C3.05573 16.6708 2.72656 16.1773 2.8074 15.6566L3.54906 11.3516C3.59823 11.0375 3.49906 10.7181 3.28323 10.495L0.274063 7.4325C0.0223965 7.17612 -0.0651035 6.79113 0.0498965 6.44375C0.161563 6.09725 0.446563 5.84438 0.79073 5.7875L4.9324 5.15662C5.2474 5.1225 5.52406 4.92125 5.66573 4.62375L7.49073 0.695C7.53406 0.6075 7.5899 0.527 7.6574 0.45875L7.7324 0.3975C7.77156 0.352 7.81656 0.314375 7.86656 0.28375L7.9574 0.24875L8.09906 0.1875H8.4499C8.76323 0.221625 9.03906 0.4185 9.18323 0.7125L11.0324 4.62375C11.1657 4.90987 11.4249 5.1085 11.7241 5.15662L15.8657 5.7875C16.2157 5.84 16.5082 6.09375 16.6241 6.44375C16.7332 6.79463 16.6391 7.17962 16.3824 7.4325L13.2657 10.53Z" fill= {show ? "#624ba2":"#ADB4C5"} />
      </svg> : <svg

        width="20"
        height="22"
        viewBox="0 0 20 22"
        fill={show ? "#624ba2" : "none"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="20" height="21" transform="translate(0 0.0664062)" fill="white" />
        <path d="M14.9337 12.5964C14.7179 12.816 14.6187 13.1337 14.6679 13.4452L15.4087 17.7502C15.4712 18.115 15.3245 18.4843 15.0337 18.6952C14.7487 18.9139 14.3695 18.9402 14.0587 18.7652L10.3679 16.7439C10.2395 16.6722 10.097 16.6337 9.9512 16.6293H9.72536C9.64703 16.6415 9.57037 16.6678 9.50037 16.708L5.8087 18.7389C5.6262 18.8352 5.41953 18.8693 5.21703 18.8352C4.7237 18.7372 4.39453 18.2437 4.47537 17.723L5.21703 13.418C5.2662 13.1039 5.16703 12.7845 4.9512 12.5614L1.94203 9.49891C1.69037 9.24253 1.60287 8.85753 1.71787 8.51016C1.82953 8.16366 2.11453 7.91078 2.4587 7.85391L6.60037 7.22303C6.91537 7.18891 7.19203 6.98766 7.3337 6.69016L9.1587 2.76141C9.20203 2.67391 9.25787 2.59341 9.32537 2.52516L9.40036 2.46391C9.43953 2.41841 9.48453 2.38078 9.53453 2.35016L9.62537 2.31516L9.76703 2.25391H10.1179C10.4312 2.28803 10.707 2.48491 10.8512 2.77891L12.7004 6.69016C12.8337 6.97628 13.0929 7.17491 13.392 7.22303L17.5337 7.85391C17.8837 7.90641 18.1762 8.16016 18.292 8.51016C18.4012 8.86103 18.307 9.24603 18.0504 9.49891L14.9337 12.5964Z" stroke="#ADB4C5" />
      </svg>
      }



    </>

  );
};

export default StarSvg;
