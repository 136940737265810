import React from "react";
import { PropTypes } from "prop-types";

const CommentSvg = ({ fillColor, isActive }) => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="10"
          cy="10"
          r="10"
          fill={isActive ? "#624BA2" : "#EEF0F1"}
        />
        <mask
          id="path-2-outside-1_9701_5840"
          maskUnits="userSpaceOnUse"
          x="5"
          y="5"
          width="10"
          height="10"
          fill="black"
        >
          <rect fill="white" x="5" y="5" width="10" height="10" />
          <path d="M10.008 6C7.684 6 6 7.89885 6 10.006C6 10.679 6.196 11.3721 6.54 12.005C6.604 12.1092 6.612 12.2414 6.568 12.3655L6.3 13.2629C6.24 13.4792 6.424 13.6395 6.628 13.5754L7.436 13.335C7.656 13.2629 7.828 13.355 8.032 13.4792C8.616 13.8237 9.344 14 10 14C11.984 14 14 12.4657 14 9.99399C14 7.86279 12.28 6 10.008 6" />
        </mask>
        <path
          d="M10.008 6C7.684 6 6 7.89885 6 10.006C6 10.679 6.196 11.3721 6.54 12.005C6.604 12.1092 6.612 12.2414 6.568 12.3655L6.3 13.2629C6.24 13.4792 6.424 13.6395 6.628 13.5754L7.436 13.335C7.656 13.2629 7.828 13.355 8.032 13.4792C8.616 13.8237 9.344 14 10 14C11.984 14 14 12.4657 14 9.99399C14 7.86279 12.28 6 10.008 6"
          fill={isActive ? "#624BA2" : "#11263C"}
        />
        <path
          d="M6.54 12.005L5.66138 12.4825L5.67407 12.5059L5.68799 12.5285L6.54 12.005ZM6.568 12.3655L5.62541 12.0316L5.61702 12.0553L5.60982 12.0794L6.568 12.3655ZM6.3 13.2629L5.34182 12.9767L5.33901 12.9861L5.33638 12.9956L6.3 13.2629ZM6.628 13.5754L6.34287 12.6169L6.33554 12.6191L6.32825 12.6213L6.628 13.5754ZM7.436 13.335L7.72113 14.2935L7.73435 14.2896L7.74746 14.2853L7.436 13.335ZM8.032 13.4792L7.51197 14.3335L7.5239 14.3405L8.032 13.4792ZM10.008 5C7.09743 5 5 7.38149 5 10.006H7C7 8.41621 8.27057 7 10.008 7V5ZM5 10.006C5 10.8697 5.24994 11.7255 5.66138 12.4825L7.41862 11.5275C7.14206 11.0186 7 10.4884 7 10.006H5ZM5.68799 12.5285C5.56964 12.3359 5.58498 12.1457 5.62541 12.0316L7.51059 12.6995C7.63902 12.337 7.63836 11.8824 7.39201 11.4815L5.68799 12.5285ZM5.60982 12.0794L5.34182 12.9767L7.25818 13.5491L7.52618 12.6517L5.60982 12.0794ZM5.33638 12.9956C5.21199 13.4441 5.33187 13.9315 5.68665 14.259C6.03046 14.5765 6.50608 14.6619 6.92775 14.5294L6.32825 12.6213C6.54592 12.553 6.82754 12.5903 7.04335 12.7896C7.27013 12.9989 7.32801 13.298 7.26362 13.5302L5.33638 12.9956ZM6.91313 14.5339L7.72113 14.2935L7.15087 12.3765L6.34287 12.6169L6.91313 14.5339ZM7.74746 14.2853C7.70295 14.2999 7.64693 14.3102 7.58453 14.3096C7.52345 14.3091 7.47533 14.2983 7.44586 14.2893C7.41917 14.281 7.40953 14.2747 7.42406 14.2822C7.43991 14.2904 7.46433 14.3044 7.51202 14.3334L8.55198 12.625C8.37273 12.5159 7.82614 12.1548 7.12454 12.3847L7.74746 14.2853ZM7.5239 14.3405C8.27748 14.7851 9.18437 15 10 15V13C9.50363 13 8.95452 12.8624 8.5401 12.6179L7.5239 14.3405ZM10 15C12.4571 15 15 13.0939 15 9.99399H13C13 11.8375 11.5109 13 10 13V15ZM15 9.99399C15 7.33425 12.8557 5 10.008 5V7C11.7043 7 13 8.39133 13 9.99399H15Z"
          fill="white"
          mask="url(#path-2-outside-1_9701_5840)"
        />
      </svg>
    </>
  );
};

export default CommentSvg;
CommentSvg.propTypes = {
  fillColor: PropTypes.string,
  isActive: PropTypes.bool,
};

CommentSvg.defaultProps = {
  fillColor: "#251038",
};
