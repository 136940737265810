import React from 'react';
import {
  AciveBar,
  CircleShadow,
  ProgressBarContainer,
  Value,
} from '../Assessment.style';

const ProgressBar = ({ value, totalValue }) => {
  let percentage = value ? Math.floor((value * 100) / totalValue) : 0;

  return (
    <>
      <ProgressBarContainer>
        <AciveBar percentage={percentage}>
          <CircleShadow>
            <Value>{percentage || 0}%</Value>
          </CircleShadow>
        </AciveBar>
      </ProgressBarContainer>
    </>
  );
};

export default ProgressBar;
