import React from "react";

const CheckSvg = () => {
  return (
    <svg
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3824 2.55678L6.59584 10.3427C6.01924 10.9195 5.0839 10.9195 4.50676 10.3427L0.487302 6.323C-0.0895174 5.74629 -0.0895174 4.81084 0.487302 4.23403C1.06423 3.6571 1.9995 3.6571 2.57617 4.23381L5.55161 7.20928L12.2932 0.467689C12.8701 -0.10924 13.8054 -0.108803 14.3821 0.467689C14.9589 1.04451 14.9589 1.97963 14.3824 2.55678Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckSvg;
