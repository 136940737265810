import React from 'react';
import TypographyCompo from '../../../../../../UI/Typography/TypographyCompo';
import NotesContent from './NotesContent';

const NotesChapterLoop = ({
  id,
  name,
  notes,
  filteredTags,
  selectedTag,
  selectedFromList,
  addToLibraryHandler,
  completedNotes,
  setCompletedNotes,
  ...props
}) => {
  return (
    <React.Fragment>
      {notes.length > 0 ? (
        <>
          {notes.map((note, index) => {
            const completed = completedNotes?.find((item) => {
              if (item?.snackId === note?.combination?.snackId) {
                return true;
              }

              return false;
            });

            return (
              <NotesContent
                key={index}
                chapterId={id}
                addToLibraryHandler={(payload, selectedCategory) => {
                  addToLibraryHandler({
                    origin: payload,
                    selectedCategory,
                    content: note,
                    tag: selectedTag,
                  });
                  return true;
                }}
                tags={filteredTags}
                setCompletedNotes={setCompletedNotes}
                data={note.notes}
                completedNotes={completedNotes}
                title={note.topic}
                snackId={note?.combination?.snackId}
                completed={completed}
                selection={selectedFromList}
                tagSelection={selectedTag}
                name={name}
                isInLibrary={note.combination.isInLibrary}
              />
            );
          })}
        </>
      ) : null}
    </React.Fragment>
  );
};

export default NotesChapterLoop;
