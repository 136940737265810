import { styled } from '@mui/system';
import PaperComp from '../Paper/Paper';
import countries from '../../../data/countries.json';
import { Autocomplete, outlinedInputClasses, TextField } from '@mui/material';

export const formattedDefaultData = countries.map((country) => {
  return {
    value: country.value,
    label: country.label,
  };
});

export const Wrapper = styled('div')(({ theme, width }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  position: 'relative',
  fontFamily: 'Satoshi',
  fontWeight: 500,
  color: '#11263C',
}));

export const CustomAutocompletePaper = styled(PaperComp)(({ theme }) => ({
  width: '100%',
  '& .MuiAutocomplete-listbox': {
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f8f8fc',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(224,21,162, 1)',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(224,21,162, 0.8)',
    },
  },
}));

export const StyledPaper = styled('div')(
  ({ selectopen, theme, error, background }) => ({
    width: '100%',
    borderRadius: '10px',
    background: error ? '#FDEEEE' : background ? background : '#f8f8fc',
    height: background ? '30px' : theme.spacing(6.25),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 400,
    fontSize: '14px',

    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: background
        ? 'none'
        : `1px solid ${theme.palette.secondary.main} !important`,
    },

    '.MuiOutlinedInput-notchedOutline': {
      width: background && '100%',
      border: background && 'none',
    },

    '.MuiAutocomplete-hasPopupIcon.css-5r88sx-MuiAutocomplete-root .MuiOutlinedInput-root':
      {
        paddingRight: background ? '15px' : '39px',
      },
  })
);

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
}));

export const Select = styled(Autocomplete)(({ theme, background }) => ({
  width: '100%',
  paddingLeft: background ? '0' : theme.spacing(2.5),
  background: 'transparreent',
  border: 'none',
  outline: 'none',
  height: '70%',
  color: '#2D3748',
  fontFamily: 'inherit',
  display: 'flex',
  alignItems: 'center',
}));

export const DropDownContainer = styled(PaperComp)(({ theme }) => ({
  zIndex: 1000,
  width: '100%',
  background: '#f8f8fc',
  position: 'absolute',
  overflow: 'hidden',
  left: 0,
  right: 0,
}));

export const Inner = styled('ul')(({ theme }) => ({
  height: '100%',
  maxHeight: 'calc(254px - 19px - 22px)',
  overflowY: 'auto',
  listStyleType: 'none',
  padding: 0,
}));

export const ListItem = styled('li')(({ theme }) => ({
  width: '100%',
  padding: '10px',
  paddingLeft: '20px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '&:hover': {
    cursor: 'pointer',
    background: '#F6E9F6 !important',
    borderLeft: '3px solid rgba(224,21,162, 1)',
  },
}));

export const Divider = styled('li')(({ theme }) => ({
  width: '100%',
  height: '1px',
  background: 'rgba(221, 219, 224, 0.3)',
}));

export const Icon = styled('div')(({ theme, open, isProfile }) => ({
  transform: `${open ? 'rotateX(180deg)' : ''}`,
  transition: 'all 0.2s',
  width: isProfile && '20px',
  height: isProfile && '20px',
}));

export const Placeholder = styled('span')(({ theme }) => ({
  color: '#ADB4C5',
}));

export const ErrorMessage = styled('small')(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: theme.palette.error.main,
}));

export const LabelMessage = styled('p')(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));
