import { Box, styled } from "@mui/material";

export const TextSpan = styled("span")(({ theme }) => ({
  color: "white",
  marginLeft: "1rem",
}));

export const MobileBox = styled(Box)(({ theme, style }) => ({
  ...style,
  cursor: "pointer",
  display: "block",
}));
