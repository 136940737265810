import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isQuestion: false,
};

const assessment = createSlice({
  name: 'assessment',
  initialState,
  reducers: {
    updateTopBarZIndex: (state, action) => {
      state.isQuestion = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const assessmentAction = assessment.actions;

export const assessmentReducer = assessment.reducer;
