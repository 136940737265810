import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import constants from './../../../config/constants';
import MobileDetector from '../../../helpers/mobileDetector';
import { CustomLink, CustomTypography } from './BreadCrumb.style';

import capitalize from '../../../helpers/capitalize';
import Typography from '../../UI/Typography/TypographyCompo';
import Separator from '../../../assets/images/Subject/BreadCrumpSeparator';
const BreadCrumb = ({
  baseView = true,
  setBaseView,
  flashcards,
  showStack,
  showSimulation,
  setShowSimulation,
  isFlashcard,
}) => {
  const isMobile = MobileDetector();

  const { name, tab, id, value } = useParams();
  const [selectedTab, setSelectedTab] = useState('');
  const getLocation = () => {
    const url = window.location.href;
    const pathname = new URL(url).pathname;
    const segments = pathname.split('/');
    const secondLastParam = segments[segments.length - 2];
    return secondLastParam;
  };

  let location = getLocation();
  useEffect(() => {
    if (location === 'octilearnlibrary') {
      if (tab == 0) {
        setSelectedTab('Syllabus');
      } else if (tab == 1) {
        setSelectedTab('Notes');
      } else if (tab == 2) {
        setSelectedTab('Flashcard Stack');
      } else if (tab == 3) {
        setSelectedTab('Assessment');
      } else if (tab == 4) {
        setSelectedTab('Analytics');
      } else {
        setSelectedTab('Simulations');
      }
    } else {
      if (tab == 0) {
        setSelectedTab('Notes');
      } else {
        setSelectedTab('Flashcard Stack');
      }
    }
  }, [name, tab, location]);

  return (
    <div role="presentation">
      <Breadcrumbs
        color={'#11263CB2'}
        aria-label="breadcrumb"
        separator={<Separator />}
      >
        <CustomLink
          to="/dashboard"
          text={constants.courses}
          isMobile={isMobile}
        />
        <CustomLink
          to={`/dashboard/${name}/${id}/${value}/octilearnlibrary/0`}
          text={capitalize(name)}
          isMobile={isMobile}
        />
        <CustomLink
          to={`/dashboard/${name}/${id}/${value}/${location}/0`}
          text={
            location === 'octilearnlibrary' ? 'Octilearn Library' : 'My Library'
          }
          isMobile={isMobile}
        />

        {baseView && !showStack ? (
          <CustomTypography
            last={true}
            sx={{ cursor: showSimulation ? 'pointer' : '' }}
            onClick={() => {
              if (showSimulation) {
                setShowSimulation(false);
              }
            }}
          >
            {capitalize(selectedTab)}
          </CustomTypography>
        ) : (
          <CustomTypography
            variant="body2"
            last={true}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              if (isFlashcard) {
                setBaseView(true);
              }
            }}
          >
            {capitalize(selectedTab)}
          </CustomTypography>
        )}

        {showSimulation && (
          <CustomTypography last={true} variant="body2">
            {'Simulation View'}
          </CustomTypography>
        )}

        {!baseView && (
          <CustomTypography last={true}>
            {flashcards?.length === 1 ? 'Flashcard' : 'Flashcards'}
          </CustomTypography>
        )}
      </Breadcrumbs>
    </div>
  );
};
export default BreadCrumb;
