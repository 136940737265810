import React, { useEffect, useState } from 'react';
import MyLibFlashCard from './MyLibFlashCard';
import api from '../../../../../../Services';
import createResponses from '../../../../../../helpers/createResponses';
import Loader from '../../../../../UI/Loader/Loader';
import MyLibFlashCardView from './MyLibFlashCardView/MyLibFlashCardView';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import useUserResponseAwait from '../../../hooks/useUserResponseAwait';
import debounce from 'lodash.debounce';
import { useParams } from 'react-router-dom';
import GridComp from '../../../../../UI/Grid/Grid';
import CardComp from '../../../../../UI/Card/Card';
import LeftSide from '../../../LeftSideBar/LeftSide';
import { Box, useMediaQuery } from '@mui/material';
import FlashCardHeader from '../../../Headers/LearningResources/FlashCard/FlashCard';
import Alert from '../../../../../UI/Alert/Alert';
import BreadCrumb from '../../../BreadCrumb';
import MobileBottomBar from '../../../../../mobileBottomBar/MobileBottomBar';
import MobileCourseIndicator from '../../../../../mobileBottomBar/MobileCourseIndicator';
import MobileDetector from '../../../../../../helpers/mobileDetector';
import CourseInfo from '../../../../../coursoInfo/courseInfo.jsx';

const MyLibraryFlashcard = () => {
  const params = useParams();

  const { id: courseId } = params;
  const isMobile = MobileDetector();
  const _1000px = useMediaQuery('(min-width: 1000px)');
  const _750px = useMediaQuery('(max-width: 750px)');
  const left = _1000px ? 3 : _750px ? 12 : 4;
  const right = _1000px ? 9 : _750px ? 12 : 8;
  const { awaitingUserResponse, toggleLoading } = useUserResponseAwait();
  const { enqueueSnackbar } = useSnackbar();
  const { createLoading, initialLoading } = createResponses;
  const { showMyLibFlashcard } = useSelector((state) => state.breadCrumbStats);
  const [baseView, setBaseView] = React.useState(true);
  const [flashcards, setFlashcards] = React.useState([]);
  const [allflashcards, setallFlashcards] = React.useState([]);
  const [hierarchyData, setHeirarchyData] = React.useState(null);
  const [selection, setSelection] = React.useState({});
  const [selectedStack, setSelectedStack] = React.useState();
  const [loading, setLoading] = React.useState(initialLoading);
  const [getFlashcardStacks, setGetFlashcardStacks] = React.useState(false);
  const [fcStackId, setFcStackId] = React.useState(null);
  const [selectedTag, setSelectedTag] = React.useState({ title: 'all' });
  const [allTags, setAllTags] = React.useState([]);
  const [stackId, setStackId] = React.useState(null);
  const [isUnit, setIsUnit] = React.useState(null);
  const [isChapter, setIsChapter] = React.useState(null);
  const [title, setTitle] = React.useState('');
  const [showUnit, setShowUnit] = React.useState(null);
  const [showChapter, setShowChapter] = React.useState(null);
  const [breadCrumbFC, setBreadCrumbFC] = React.useState(null);
  const [totalFC, setTotalFc] = React.useState(null);
  const [openMobileDropDown, SetOpenMobileDropDown] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(null);
  const [activeItem, setActiveItem] = React.useState('');
  const [attemptedCards, setAttemptedCards] = React.useState([]);
  const [structuredData, setStructuredData] = useState([]);

  useEffect(() => {
    if (selectedData?.layer === 1 && selectedData?.isExpanded === false) {
      setFlashcards([]);
    }
  }, [selectedData]);

  const handleOpenMobileDropDown = () => {
    SetOpenMobileDropDown(!openMobileDropDown);
  };

  const handleTagSelection = (_, item) => {
    handleFlashcardCall(selection, item);

    setSelectedTag(item);
  };

  let loaFlashcardsStacks = () => {
    //towork here
  };

  const loadFlashcards = async (id, type, tagId) => {
    const token = localStorage.getItem('auth');
    let temp = await api.getFlashcardsFromLibrary(token, type, id, tagId);
    return temp;
  };

  const handleFlashcardCall = async (payload, criteria) => {
    const { id, type } = payload;

    const tagId = criteria?.id;
    setSelection(payload);
    loadFlashcards(id, type, tagId)
      .then((el) => {
        if (!el.error) {
          setFlashcards(el?.data);
          setAttemptedCards(el?.data[0]?.doneFlashcards);
        }
      })
      .catch((err) => {
        console.error('error', err);
      });
  };
  function handleSelection(e) {
    if (e.type === 'unit') {
      setSelectedData({
        ...e,
        unitname: e.name,
        type: 'unit',
        active: 'unit',
      });
    } else if (e.type === 'chapter') {
      setSelectedData((prevData) => ({
        ...prevData,
        chaptername: e.name,
        type: 'chapter',
        active: 'chapter',
      }));
    } else {
      setSelectedData((prevData) => ({
        ...prevData,
        snackname: e.name,
        type: 'snack',
        active: 'snack',
      }));
    }
  }
  const listItemClickHandler = (selectedListItem, courseInfo) => {
    setActiveItem(selectedListItem.label);
    handleSelection(selectedListItem);
    setBaseView(true);

    if (selectedListItem.isExpanded && selectedListItem?.type === 'unit') {
      handleFlashcardCall(selectedListItem, selectedTag);
    }
    if (selectedListItem.isExpanded && selectedListItem?.type !== 'unit') {
      handleFlashcardCall(selectedListItem, selectedTag);
    }

    if (selectedListItem.layer === 2 && !selectedListItem.isExpanded) {
      const firstLevelData = structuredData?.filter((unit) => {
        return unit.id === selectedListItem.unitId;
      });
      firstLevelData[0].nested = firstLevelData[0]?.childrenElements;
      handleFlashcardCall(firstLevelData[0], selectedTag);
    }
  };
  const loadFlashcardsHandler = (id, type) => {
    setLoading(createLoading(true, 'flash-cards-init'));
    loadFlashcards(id, type)
      .then((el) => {
        setLoading(initialLoading);
      })
      .catch((err) => console.error(err));
  };
  const getMenuState = (getMenu) => {
    if (getMenu === true) {
      setGetFlashcardStacks(getMenu);
      setBaseView(true);
    }
  };
  React.useEffect(() => {
    if (getFlashcardStacks === true) {
      loadFlashcardsHandler(selection.id, selectedTag);
      handleFlashcardCall({ id: selection.id, type: selection.type });
    }
  }, [getFlashcardStacks]);

  function handleClick(stack) {
    setBreadCrumbFC(stack?.flashcards);

    setFcStackId(stack.stackId);
    setBaseView(false);
    setSelectedStack(stack);

    if (stack?.selectedResource === 'unit') {
      setStackId(stack?.chapterId);
      setIsUnit(true);
      setTitle(stack?.chapterName);

      const unit = stack?.unitName;
      setShowUnit(unit);
    } else if (stack?.selectedResource === 'chapter') {
      setIsChapter(true);
      setStackId(stack?.snackId);

      const unit = stack?.unitName;
      const chapter = stack?.chapterName;
      setShowUnit(unit);
      setShowChapter(chapter);

      setTitle(stack?.snackName);
    } else {
      setStackId(stack?.tagName);

      setTitle(stack?.tagName);

      const unit = stack?.unitName;
      const chapter = stack?.chapterName;
      setShowUnit(unit);
      setShowChapter(chapter);
    }
  }

  useEffect(() => {
    if (baseView) {
      setShowUnit('');
      setShowChapter('');
    }
  }, [baseView]);

  const flashCardButtonApiCall = async (response, flashcardId) => {
    let token = localStorage.getItem('auth');
    let temp = api.myLibraryFlashcardButtonStatus(token, response, flashcardId);
    return temp;
  };
  const flashCardButtonApiHandler = (payload) => {
    const { response, id } = payload;
    flashCardButtonApiCall(response, id)
      .then((el) => {
        if (!el.error) {
        } else {
          enqueueSnackbar(el.response, {
            variant: 'error',
          });
        }
        toggleLoading(false);
      })
      .catch((error) => {
        toggleLoading(false);
        enqueueSnackbar(error, {
          variant: 'error',
        });
      });
  };

  const deleteFlashcardStackApiCall = async (fcStackId, isUnit, isChapter) => {
    let token = localStorage.getItem('auth');
    let temp = api.deleteFlashcardStack(token, fcStackId, isUnit, isChapter);
    return temp;
  };
  const deleteFlashcardStackHandler = (Id, isUnit, isChapter) => {
    let stackId = Id ? Id : fcStackId;

    deleteFlashcardStackApiCall(stackId, isUnit, isChapter)
      .then((el) => {
        if (!el.error) {
          fetchLibraryHeirarchyForFlahcards();
          let remainingcards = isUnit
            ? flashcards.filter((card) => card.chapterId != stackId)
            : isChapter
            ? flashcards.filter((card) => card.snackId != stackId)
            : flashcards.filter((card) => card.tagName != stackId);

          setFlashcards(remainingcards);

          setallFlashcards(remainingcards);

          enqueueSnackbar(` ${el.response}`, {
            variant: 'success',
          });
          setBaseView(true);
        } else {
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: 'error',
        });
      });
  };

  const sideEffectHandler = React.useCallback(
    debounce((response) => {
      flashCardButtonApiHandler(response);
    }, 2000),
    []
  );

  const fetchLibraryHeirarchyForFlahcards = async () => {
    let token = localStorage.getItem('auth');
    await api
      .getLibraryHierarchyForflashcards(token, courseId)
      .then((resp) => setHeirarchyData(resp))
      .catch((err) => console.error(err));
  };
  React.useEffect(() => {
    fetchLibraryHeirarchyForFlahcards();
  }, []);

  React.useEffect(() => {
    if (flashcards) {
      let totalFlashcards = flashcards?.map((item) => item?.flashcards);
      totalFlashcards = totalFlashcards?.flat();

      setTotalFc(totalFlashcards);
    }
  }, [flashcards]);

  return (
    <>
      <Box
        sx={{
          padding: { xs: '15px 20px', md: '0px 0px' },
          marginBottom: '110px',
        }}
      >
        {loading.value ? (
          <Loader />
        ) : (
          <>
            <GridComp item container xs={12} sx={{ marginBottom: '20px' }}>
              <BreadCrumb
                baseView={baseView}
                setBaseView={setBaseView}
                flashcards={breadCrumbFC}
                showStack={true}
                isFlashcard={true}
              />

              <FlashCardHeader
                activeTag={selectedTag}
                allTags={allTags}
                setAllTags={setAllTags}
                baseView={baseView}
                left={left}
                right={right}
                handleSelection={handleTagSelection}
                flashcards={totalFC}
                showUnit={showUnit}
                showChapter={showChapter}
              />
            </GridComp>
            {flashcards.length < 1 && (
              <MobileCourseIndicator
                courseId={courseId}
                tab={'My Library Flash Cards'}
              />
            )}

            <GridComp container spacing={4}>
              <GridComp
                item
                xs={left}
                sx={{ display: { xs: 'none', sm: 'block' } }}
              >
                <CardComp nopadding={true}>
                  <LeftSide
                    selectedFromList={selection}
                    onListClick={(e, i) => {
                      listItemClickHandler(e, i);
                    }}
                    isLibrary={true}
                    isFlashcard={true}
                    // getMenuStats={getMenuStats}
                    hierarchyData={hierarchyData}
                    handleOpenMobileDropDown={() => {}}
                    selectedData={selectedData}
                    setStructuredData={setStructuredData}
                  />
                </CardComp>
              </GridComp>

              {baseView ? (
                <GridComp item xs={right}>
                  {flashcards.length > 0 ? (
                    <MyLibFlashCard
                      clicked={handleClick}
                      MyLibraryFlashCardData={flashcards}
                      loadFlashCardStacks={loaFlashcardsStacks}
                      deleteFlashcardStack={deleteFlashcardStackHandler}
                      stackId={stackId}
                      setStackId={setStackId}
                      isUnit={isUnit}
                      setIsUnit={setIsUnit}
                      isChapter={isChapter}
                      setIsChpater={setIsChapter}
                      selectedTag={selectedTag}
                    />
                  ) : (
                    <>
                      {isMobile ? (
                        ''
                      ) : (
                        <>
                          {flashcards?.length === 0 ? (
                            <CourseInfo courseId={courseId} tab="Flashcards" />
                          ) : (
                            <Alert
                              severity={'info'}
                              title="Please select a topic"
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </GridComp>
              ) : (
                <GridComp item xs={right}>
                  <MyLibFlashCardView
                    isMyLibrary={true}
                    doneFlashCards={attemptedCards}
                    awaitingUserResponse={awaitingUserResponse}
                    deleteFlashcardStack={deleteFlashcardStackHandler}
                    sideEffect={({ response, id }) => {
                      toggleLoading(true);
                      sideEffectHandler({ response, id });
                    }}
                    flashCardIdSideEffect={(e) => {
                      return;
                    }}
                    controllerData={selectedStack.flashcards}
                    getMenuState={getMenuState}
                    stackId={stackId}
                    isUnit={isUnit}
                    isChapter={isChapter}
                    title={title}
                    loading={awaitingUserResponse?.loading}
                  />
                </GridComp>
              )}
            </GridComp>
          </>
        )}
      </Box>

      <MobileBottomBar
        selectionText="Click here to get started "
        openMobileDropDown={openMobileDropDown}
        handleOpenMobileDropDown={handleOpenMobileDropDown}
        showData={flashcards.length < 1}
        selectedData={selectedData}
        leftSideMenu={
          <LeftSide
            selectedFromList={selection}
            onListClick={(e, i) => {
              listItemClickHandler(e, i);
            }}
            isLibrary={true}
            isFlashcard={true}
            isSimulation={false}
            // getMenuStats={getMenuStats}
            hierarchyData={hierarchyData}
            handleOpenMobileDropDown={handleOpenMobileDropDown}
            selectedData={selectedData}
          />
        }
      />
    </>
  );
};

export default MyLibraryFlashcard;
