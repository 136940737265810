import React, { useState } from 'react';
import FlashCardView from './../../../../../../UI/FlashCardView/FlashCardView';
import GridComp from './../../../../../../UI/Grid/Grid';
import { Container, LeftGrid } from './MyLibFlashCardView.style';
import IconButton from '@mui/material/IconButton';
import StarSvg from './../../../../Headers/LearningResources/icons/StarSvg';
import TypographyCompo from './../../../../../../UI/Typography/TypographyCompo';
import Modal from './../../../../../../UI/Modal/Modal';
import FlashcardDeleteModel from './FlashcardDeleteModel/FlashcardDeleteModel';
import { capitalize } from '@mui/material';

const MyLibFlashCardView = ({
  deleteFlashcardStack,
  sideEffect,
  flashCardIdSideEffect,
  controllerData,
  getMenuState,
  awaitingUserResponse,
  stackId,
  isUnit,
  isChapter,
  title,
  loading,
  doneFlashCards,
  isMyLibrary,
}) => {
  const [show, setShow] = useState(true);
  const [openDeleteModel, setOpenDeleteModel] = React.useState(false);
  const saveHandler = () => {
    setOpenDeleteModel(true);
  };
  return (
    <Container>
      <GridComp container spacing={3}>
        <GridComp item xs={12}>
          <LeftGrid>
            <TypographyCompo variant="h6">{capitalize(title)}</TypographyCompo>
            <IconButton
              color="primary"
              aria-label="add to shopping cart"
              onClick={saveHandler}
            >
              <StarSvg show={show} />
            </IconButton>
          </LeftGrid>

          <FlashCardView
            isMyLibrary={isMyLibrary}
            doneFlashCards={doneFlashCards}
            sideEffect={sideEffect}
            flashCardIdSideEffect={flashCardIdSideEffect}
            getMenuState={getMenuState}
            awaitingUserResponse={awaitingUserResponse}
            controllerData={controllerData}
            loading={loading}
          />
        </GridComp>
      </GridComp>

      <Modal open={openDeleteModel} setOpen={setOpenDeleteModel}>
        <FlashcardDeleteModel
          // deleteFlashcardStack={deleteFlashcardStack}
          setOpen={setOpenDeleteModel}
          setShow={setShow}
          deleteFlashcardStack={() => {
            {
              deleteFlashcardStack(stackId, isUnit, isChapter);
            }
          }}
        />
      </Modal>
    </Container>
  );
};

export default MyLibFlashCardView;
