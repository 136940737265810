import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    isHighlighting: false,

};




const commentResourcesHighlight = createSlice({
    name: "commentResourcesHighlight",
    initialState,
    reducers: {
        toggleHighlightState: (state) => {
            state.isHighlighting = !state.isHighlighting;
            return state;
        },
        highlightHandler: (state, action) => {
            state.isHighlighting = action.payload;
            return state;
        },
    },
});
export const CommentResourcesHighlightActions =
    commentResourcesHighlight.actions;

export const CommentResourcesHighlightReducer =
    commentResourcesHighlight.reducer;
