import { styled } from "@mui/material";

export const DivWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  cursor: "pointer",
  height: theme.spacing(42),
  [theme.breakpoints.down("sm")]: {
    height: theme.spacing(35),
  },
}));

export const FirstDiv = styled("div")(
  ({ theme, paddingTop, isOctilearnLib }) => ({
    width: "100%",
    height: "267px",
    background: "#fff",
    borderRadius: theme.spacing(2),
    boxShadow: "0px 4px 13px 0px rgba(0, 0, 0, 0.06)",
    zIndex: 3,
    position: "relative",
    paddingTop: paddingTop,
    [theme.breakpoints.down("sm")]: {
      height: isOctilearnLib ? "230px" : "250px",
    },
  })
);

export const SecondDiv = styled("div")(({ theme }) => ({
  width: "95%",
  height: "200px",
  background: "#ebedf1",
  borderRadius: theme.spacing(2),
  zIndex: 2,
  top: "-180px",
  background: "#F9F9F9",
  position: "relative",
  marginLeft: "2.5%",
  boxShadow: "0px 4px 13px 0px rgba(0, 0, 0, 0.06)",
  [theme.breakpoints.down("sm")]: {
    height: "150px",
    top: "-190px",
    width: "90%",
    marginLeft: "5%",
    borderRadius: "10px",
  },
}));
