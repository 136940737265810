import React from 'react';

const ArrowDownIcon = ({ ...props }) => {
  return (
    <svg
      width={props?.isProfile ? '17' : '9'}
      height={props?.isProfile ? '13' : '6'}
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 1.06123L7.93874 1.39168e-07L4.5 3.43874L1.06126 1.04104e-06L1.09284e-06 1.06123L4.5 5.56123L9 1.06123Z"
        fill={props?.isProfile ? '#624BA2' : '#E015A2'}
      />
    </svg>
  );
};

export default ArrowDownIcon;
