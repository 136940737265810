import React from "react";

export default function ThreeDotsSvg({ hideFill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <circle cx="15" cy="15" r="15" fill={`${hideFill ? "" : "#E015A2"}`} />
      <rect x="13" y="8" width="4" height="4" rx="2" fill="white" />
      <rect x="13" y="13" width="4" height="4" rx="2" fill="white" />
      <rect x="13" y="18" width="4" height="4" rx="2" fill="white" />
    </svg>
  );
}
