import React from "react";
import NotesHeading from "./NotesHeading";
import RenderAccordionData from "./RenderAccordionData";

const RenderEachNoteBlock = (props) => {
  return (
    <React.Fragment>
      <NotesHeading
        chapterId={props.chapterId}
        unitId={props.unit.id}
        snackId={props.snackId}
        unit={props.unit.name}
        chapter={props.chapterName}
        snack={props.snackName}
        subjectName={props.subjectName}
        handleResetNotes={props.handleResetNotes}
        handleDelete={props.handleDelete}
        selection={props.selectedFromList}
        

      />
      {props.content.length > 0 && (
        <RenderAccordionData
          getNotes={props.getNotes}
          deleteSingleNote={props.deleteSingleNote}
          content={props.content.sort((a, b) => a.tagId - b.tagId)}
        />
      )}
    </React.Fragment>
  );
};

export default RenderEachNoteBlock;
