import { hotjar } from "react-hotjar";
const mixpanel = require("mixpanel-browser");

let env_check =
  process.env.REACT_APP_ENVIORNMENT === "production" ||
  process.env.REACT_APP_ENVIORNMENT === "stage";
if (env_check) {
  mixpanel.init(process.env?.REACT_APP_MIXPANEL_KEY, { track_pageview: false });
  hotjar.initialize("3842553", "6");
}

let actions = {
  identify: (id) => {
    if (env_check) {
      mixpanel.identify(id);
      hotjar.identify("USER_ID", { userProperty: id });
    }
  },
  track: (name, props) => {
    if (env_check) {
      mixpanel.track(name, props);
      hotjar.event(name);
    }
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
