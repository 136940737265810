import { styled } from "@mui/material";

export const TableHeading = styled("td")(({ theme }) => ({
  padding: "7px 20px",
  borderBottom: "2px solid black",
  color: "#11263C",
  fontSize: "12px",
  fontFamily: "Inter",
}));

export const TableData = styled("td")(({ color, border }) => ({
  background: "rgba(98, 75, 162, 0.10)",
  color: color,
  fontSize: "12px",
  fontWeight: 500,
  fontFamily: "Inter",
  padding: "30px",
  fontFamily: "Inter",
  borderBottom: border ? `1px solid ${color}` : "",
}));

export const TableDataNames = styled("td")(({ theme, isDouble }) => ({
  textAlign: "start !important",
  display: "flex",
  padding: "30px 0",
  color: "#11263C",
  fontSize: "14px",
  fontFamily: "Inter",
  justifyContent: "start",
  alignItems: "start",
  flexDirection: "column",
  gap: "10px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));
