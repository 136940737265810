import React from "react";

const SimulationSvg = ({ color, isMobile, isSelected }) => {
  return (
    <div style={{ marginRight: "0.5rem", marginTop: "2px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="17"
        viewBox="0 0 14 17"
        fill="none"
      >
        <path
          d="M12.625 9.58253C13.4583 9.10141 13.4583 7.89859 12.625 7.41747L2.875 1.7883C2.04167 1.30718 1 1.90858 1 2.87083L1 14.1292C1 15.0914 2.04167 15.6928 2.875 15.2117L12.625 9.58253Z"
          stroke={color}
          stroke-opacity="0.5"
          stroke-width="1.5"
        />
      </svg>
    </div>
  );
};

export default SimulationSvg;
