import React from "react";

const NotesSvg = ({ color, isMobile, isSelected }) => {

  return (
    <div style={{ marginRight: "0.5rem", marginTop: '2px' }}>
      {isMobile ? <svg xmlns="http://www.w3.org/2000/svg" width="25" height="16" viewBox="0 0 25 16" fill="none">
        <path d="M24.5833 1.71731H22.0542C22.0108 1.44926 21.9131 1.19242 21.7668 0.961888C21.6206 0.731355 21.4288 0.531785 21.2026 0.374912C20.9765 0.21804 20.7206 0.107032 20.4501 0.0484155C20.1795 -0.0102013 19.8997 -0.0152435 19.6271 0.0335857L12.5 1.3027L5.375 0.0335857C5.10226 -0.0155441 4.82224 -0.0107299 4.55142 0.0477453C4.28061 0.106221 4.02448 0.217175 3.79812 0.374073C3.57175 0.530972 3.37973 0.730644 3.23336 0.961331C3.08699 1.19202 2.98923 1.44906 2.94583 1.71731H0.416667C0.30616 1.71731 0.200179 1.7603 0.122039 1.83683C0.0438987 1.91336 0 2.01716 0 2.12539V15.5919C0 15.7002 0.0438987 15.8039 0.122039 15.8805C0.200179 15.957 0.30616 16 0.416667 16H24.5833C24.6938 16 24.7998 15.957 24.878 15.8805C24.9561 15.8039 25 15.7002 25 15.5919V2.12539C25 2.01716 24.9561 1.91336 24.878 1.83683C24.7998 1.7603 24.6938 1.71731 24.5833 1.71731ZM0.833333 15.1838V2.53346H2.91667V13.9596C2.91665 14.0552 2.95089 14.1477 3.0134 14.2211C3.07592 14.2945 3.16275 14.3441 3.25875 14.3612L7.87917 15.1838H0.833333ZM12.0833 15.1022L3.75 13.6193V2.04092C3.74997 1.86157 3.79018 1.68441 3.86777 1.52197C3.94537 1.35952 4.05846 1.21576 4.19905 1.10084C4.33964 0.985923 4.50429 0.902662 4.68136 0.856945C4.85843 0.811227 5.04359 0.80417 5.22375 0.836272L12.0833 2.05765V15.1022ZM12.9167 2.05683L19.7763 0.835457C19.9565 0.803342 20.1417 0.810416 20.3188 0.85618C20.496 0.901944 20.6607 0.985278 20.8013 1.10028C20.9419 1.21529 21.0549 1.35916 21.1325 1.5217C21.21 1.68425 21.2502 1.8615 21.25 2.04092V13.6193L12.9167 15.1022V2.05683ZM24.1667 15.1838H17.1208L21.7413 14.3612C21.8373 14.3441 21.9241 14.2945 21.9866 14.2211C22.0491 14.1477 22.0833 14.0552 22.0833 13.9596V2.53346H24.1667V15.1838Z" fill={color} fill-opacity="0.7" />
        <path d="M10.9991 4H5.99908V5H10.9991V4Z" fill={color} fill-opacity="0.7" />
        <path d="M10.9991 7.0005H5.99908V8.0005H10.9991V7.0005Z" fill={color} fill-opacity="0.7" />
        <path d="M19.0032 4H14.0032V5H19.0032V4Z" fill={color} fill-opacity="0.7" />
        <path d="M19.0032 7.0005H14.0032V8.0005H19.0032V7.0005Z" fill={color} fill-opacity="0.7" />
      </svg> : ''
      }
    </div >
  );
};

export default NotesSvg;

// KeySvg.defaultProps = {
//   color: "#ADB4C5",
// };
