import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import QuestionCard from '../components/QuestionCard';
import { CircularProgress, LinearProgress, Pagination } from '@mui/material';
import capitalize from '../../../../../../../../helpers/capitalize';
import GRAPHICS from '../../../../../../../../assets/images/assessment';

import {
  AssessmentCompletionModal,
  AssessmentCompletionWrapper,
  AssessmentLoaderWrapper,
  AssessmentModalHeading,
  BtnSection,
  ClickAwayWrapper,
  PagniationInfo,
  PercentageSpan,
  PinkText,
  ResultBackWrapper,
  ResultCorrectScores,
  ResultIncorrectScores,
  ResultProgrssWrapper,
  ResultScoresContent,
  ResultScoresContentWrapper,
  ResultScoresHeading,
  ResultScoresWrapper,
  ReviewCardWrapper,
  ReviewHeading,
  ReviewSubmitButton,
  ReviewSubmitSection,
  ReviewText,
  SubmitSection,
} from '../Assessment.style';

const ResultScreen = ({
  allQuestions,
  userAttemptedQuestins,
  course,
  newSelection,
}) => {
  const { name, id, value } = useParams();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 10; // Number of questions per page

  const totalQuestions = userAttemptedQuestins?.length;
  const totalPages = Math.ceil(totalQuestions / questionsPerPage);

  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;

  const reviewQuestions = allQuestions?.filter((item) => {
    return userAttemptedQuestins?.some((q) => item?.id === q?.questionId);
  });

  const currentQuestions = reviewQuestions?.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );

  const userCorrectAnswers = userAttemptedQuestins?.filter((item) => {
    return item?.isCorrect;
  });

  const percentage =
    userCorrectAnswers?.length > 0
      ? Math.floor(
          (userCorrectAnswers?.length * 100) / userAttemptedQuestins?.length
        )
      : 0;

  const userWrongQuestions =
    userAttemptedQuestins?.length - userCorrectAnswers?.length;

  setTimeout(() => {
    setLoading(false);
  }, 3000);

  return (
    <div>
      {loading && (
        <AssessmentCompletionWrapper>
          <AssessmentCompletionModal>
            <AssessmentLoaderWrapper>
              <LinearProgress
                sx={{
                  backgroundColor: 'white',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#E015A2',
                  },
                }}
              />
            </AssessmentLoaderWrapper>

            <img src={GRAPHICS.Submitted} alt="Submit" />

            <AssessmentModalHeading>
              Submitting Assessment!
            </AssessmentModalHeading>
          </AssessmentCompletionModal>
        </AssessmentCompletionWrapper>
      )}

      <ReviewSubmitSection>
        <SubmitSection>
          <BtnSection>
            <ClickAwayWrapper>
              <ReviewHeading>
                <PinkText>Submission Result</PinkText>
              </ReviewHeading>

              <ReviewText>
                {capitalize(newSelection?.type)}: {newSelection?.label}
              </ReviewText>
            </ClickAwayWrapper>

            <Link to={`/dashboard/${name}/${id}/${value}/octilearnlibrary/4`}>
              <ReviewSubmitButton>
                View Performance Analytics
              </ReviewSubmitButton>
            </Link>
          </BtnSection>
          <div
            style={{
              width: '60%',
              maxHeight: '120px',
            }}
          >
            <img
              src={course?.img}
              alt="img"
              style={{
                width: '100%',
                height: '100%',
                maxHeight: '120px',
                objectFit: 'cover',
              }}
            />
          </div>
        </SubmitSection>
      </ReviewSubmitSection>

      <ResultScoresWrapper>
        <ResultCorrectScores>
          <ResultScoresContentWrapper>
            <ResultScoresHeading>Correct Answer</ResultScoresHeading>

            <ResultScoresContent>
              You answered {userCorrectAnswers?.length} out of {totalQuestions}{' '}
              questions correctly.
            </ResultScoresContent>
          </ResultScoresContentWrapper>

          <ResultProgrssWrapper>
            <div style={{ position: 'relative', paddingRight: '10px' }}>
              <svg
                width="40"
                height="40"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="9.5" cy="9.5" r="9.5" fill="#5FA4A8" />
              </svg>
              <PercentageSpan customLeft={percentage == 100}>
                {percentage < 1 ? '0' + percentage : percentage}%
              </PercentageSpan>

              <CircularProgress
                style={{
                  color: '#2A7A7E',
                  width: '50px',
                  height: '50px',
                  position: 'absolute',
                  left: -6,
                  top: -6,
                  border: '5px',
                }}
                thickness={6}
                value={percentage}
                variant="determinate"
              />
            </div>
          </ResultProgrssWrapper>
        </ResultCorrectScores>

        <ResultIncorrectScores>
          <ResultScoresContentWrapper>
            <ResultScoresHeading>Incorrect Answers</ResultScoresHeading>

            <ResultScoresContent>
              You answered {userWrongQuestions} out of {totalQuestions}{' '}
              questions incorrectly.
            </ResultScoresContent>
          </ResultScoresContentWrapper>

          <ResultProgrssWrapper>
            <div style={{ position: 'relative', paddingRight: '10px' }}>
              <PercentageSpan customLeft={100 - percentage == 100}>
                {100 - percentage < 1
                  ? '0' + 100 - percentage
                  : 100 - percentage}
                %{' '}
              </PercentageSpan>

              <svg
                width="40"
                height="40"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="9.5" cy="9.5" r="9.5" fill="#E563BD" />
              </svg>

              <CircularProgress
                style={{
                  color: '#AE0C7C',
                  width: '50px',
                  height: '50px',
                  position: 'absolute',
                  left: -6,
                  top: -6,
                  border: '5px',
                }}
                thickness={6}
                value={100 - percentage}
                variant="determinate"
              />
            </div>
          </ResultProgrssWrapper>
        </ResultIncorrectScores>
      </ResultScoresWrapper>

      <ReviewCardWrapper>
        {currentQuestions &&
          currentQuestions.map((item, index) => {
            const userAnswer = userAttemptedQuestins?.find((question) => {
              return question?.questionId === item?.id;
            });

            return (
              <QuestionCard
                key={item.id}
                item={item}
                resultCard={true}
                index={index}
                totalQuestions={reviewQuestions?.length}
                userAttemptedQuestins={userAttemptedQuestins}
                isCorrect={userAnswer?.isCorrect}
                selectedOption={userAnswer?.selectedOption}
              />
            );
          })}
      </ReviewCardWrapper>

      <ResultBackWrapper>
        <PagniationInfo>
          Showing {indexOfFirstQuestion + 1} to {indexOfLastQuestion} of{' '}
          {totalQuestions} Questions
        </PagniationInfo>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, page) => setCurrentPage(page)}
          showFirstButton
          showLastButton
        />
      </ResultBackWrapper>
    </div>
  );
};

export default ResultScreen;
