import PropTypes from "prop-types";
import React from "react";
import ErrorBoundary from "./../../../Errors/ErrorBoundary";

const TextEditorRenderOutput = ({
  data,
  parentStyles,
  contentStyles,
  noteId,
  isFlashCard,
}) => {
  const { html } = JSON.parse(data);
  return (
    <ErrorBoundary>
      <div
        style={{ ...parentStyles, wordBreak: "break" }}
        id={noteId}
        className={
          isFlashCard ? "note-container-flashcard-mobile" : "note-container"
        }
      >
        <div dangerouslySetInnerHTML={{ __html: html }} style={contentStyles} />
      </div>
    </ErrorBoundary>
  );
};

TextEditorRenderOutput.propTypes = {
  contentStyles: PropTypes.any,
  html: PropTypes.string,
  parentStyles: PropTypes.any,
};

export default TextEditorRenderOutput;

TextEditorRenderOutput.defaultProps = {
  data: {
    html: "",
  },
};
