import React, { useState } from 'react';
import { Box, Divider } from '@mui/material';
import capitalize from '../../../helpers/capitalize';
import MobileDetector from '../../../helpers/mobileDetector';
import {
  ConceptTag,
  ConceptTagWrapper,
  ContentWrapper,
  Div,
  Image,
  Level,
  Title,
} from './Simulation.style';

const SimulationCard = ({
  title,
  imgSrc,
  conceptTags,
  src,
  newSelection,
  setShowSimulation,
  setSimulationSrc,
  isMobile,
}) => {
  const [readMore, setReadMore] = useState(false);

  return (
    <>
      <Div
        onClick={() => {
          setShowSimulation(true);
          setSimulationSrc(src);
        }}
        isMobile={isMobile}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image src={imgSrc} alt="Simulation img" />
        </Box>
        <ContentWrapper>
          <Level>
            {capitalize(newSelection?.type)} {isMobile ? ': ' : ' | '}
            {capitalize(newSelection?.label)}
          </Level>

          <Title>
            {title && title?.length > 20 ? (
              <span>
                {readMore === true ? (
                  <span>
                    {title.slice(0)}{' '}
                    <span
                      style={{
                        fontSize: '8px',
                        color: 'blue',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setReadMore(false);
                      }}
                    >
                      Read less
                    </span>{' '}
                  </span>
                ) : (
                  <span>
                    {title.slice(0, 20).concat('...')}{' '}
                    <span
                      style={{
                        fontSize: '8px',
                        color: 'blue',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setReadMore(true);
                      }}
                    >
                      Read more
                    </span>{' '}
                  </span>
                )}
              </span>
            ) : (
              title
            )}
          </Title>
          <Divider
            sx={{
              width: '96%',
              borderColor: 'rgba(191, 56, 149, 0.13)',
              margin: '7px 0px',
            }}
          />
          <ConceptTagWrapper>
            {conceptTags?.map((item) => {
              return <ConceptTag>#{capitalize(item?.title)}</ConceptTag>;
            })}
          </ConceptTagWrapper>
        </ContentWrapper>
      </Div>
    </>
  );
};

export default SimulationCard;
