import { styled } from "@mui/system";
import BoxComp from "./../../../../../../UI/Box/Box";
import IconButton from "@mui/material/IconButton";
import { LeakAddTwoTone } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";

export const Stack = styled(BoxComp)(({ theme, commentId }) => {
  let left;

  const width = window.innerWidth;

  const handleResize = () => {
    const commentBoxElement = document.getElementById(commentId);

    if (commentBoxElement) {
      left = commentBoxElement.getBoundingClientRect();

      if (width < 450) {
        if (left?.left > 200) {
          commentBoxElement.style.left = "-250px";
        } else if (left?.left < 0) {
          commentBoxElement.style.left = "-100px";
        } else {
          commentBoxElement.style.left = "0px";
        }
      } else {
        if (left?.left > 450) {
          commentBoxElement.style.left = "-300px";
        } else {
          commentBoxElement.style.left = "0px";
        }
      }
    }
  };

  handleResize();

  window.addEventListener("resize", handleResize);

  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    zIndex: 1000,
    left: left > 450 ? "-330px" : "0px",
  };
});
export const Box = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  background: "#251038",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  borderRadius: "5px",
  padding: theme.spacing(0, 1, 0, 1),
  height: "50px",
  width: "100px",
  [theme.breakpoints.down("sm")]: {},
}));

export const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
}));
export const CommentBox = styled("div")(
  ({ theme, showComments, top, left, commentId }) => {
    return {
      top: top < 20 ? "60px" : "-150px",
      position: "relative",
      height: "120px",
      width: "40vw",
      maxWidth: "343px",
      // overflow: "auto",
      border: "1px solid white",
      background: "#251038",
      borderRadius: "10px",
      left: left > 350 ? "-300px" : "60px",
      marginBottom: theme.spacing(1.2),
      display: showComments ? "block" : "none",
      color: "white",
      padding: "2px",
      [theme.breakpoints.down("sm")]: {
        height: "80px",
        marginBottom: "0px",
        zIndex: "99",
      },
    };
  }
);

export const HighlightBox = styled("div")(
  ({ theme, showHighlight, left, top }) => ({
    top: top < 40 ? "60px" : "-110px",
    border: "1px solid white",
    position: "relative",
    height: "40px",
    width: "200px",
    borderRadius: "10px",
    border: "none",
    background: "#251038",
    right: left < 90 ? "0%" : "70%",
    marginBottom: theme.spacing(1.2),
    display: showHighlight ? "block" : "none",
    padding: "2px",
    [theme.breakpoints.down("sm")]: {},
  })
);

export const CustomIconWrapper = styled("div")(({ theme }) => ({
  width: "20px",
  height: "20px",
  background: "#AAA5B1",
  display: "flex",
  opacity: "0.9",
  borderRadius: "4px",
  justifyContent: "center",
  alignItems: "center",
}));

export const HighlightBoxShape = styled("div")(({ theme }) => ({
  width: 0,
  height: 0,
  borderLeft: "20px solid transparent",
  borderRight: "20px solid transparent",
  borderTop: "20px solid #251038",
  position: "absolute",
  left: "10px",
  bottom: "-10px",
  [theme.breakpoints.down("sm")]: {
    borderTop: "20px solid #251038",
  },
}));

export const CommentBoxShape = styled("div")(({ theme, input }) => ({
  width: "2em",
  height: "2em",
  position: "relative",
  pointerEvents: "none",
  top: input ? "-40px" : "-10px",
  left: "20px",
  [theme.breakpoints.down("sm")]: {
    top: "-8px",
  },
}));

export const WrapperDiv = styled("div")(({ theme, input }) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "end",
  height: "37px",
  padding: "0px",

  [theme.breakpoints.down("sm")]: {
    height: "29px",
  },
}));
