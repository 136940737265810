import { styled } from "@mui/system";
import { Link } from "react-router-dom";

export const CustomTypography = styled("p")(({ theme, last }) => ({
  color: "#11263CB2",
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: last ? "700" : "400",
  lineHeight: "0.5",
}));

export const CustomLink = ({ to, text, isMobile }) => (
  <Link to={to} style={{ textDecoration: "none" }}>
    <CustomTypography>{text}</CustomTypography>
  </Link>
);
