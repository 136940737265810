import React from 'react';
import {
  GuideContent,
  QuickGuide,
  QuickGuideWrapper,
  WelcomeHeading,
  WelcomeScreenWrapper,
} from '../Assessment.style';
import GRAPHICS from '../../../../../../../../assets/images/assessment';
import { useSelector } from 'react-redux';

const WelcomeScreen = ({ title }) => {
  return (
    <WelcomeScreenWrapper>
      <WelcomeHeading>
        Welcome to The {title?.charAt(0).toUpperCase() + title?.slice(1)}{' '}
        Assessment Section!
      </WelcomeHeading>

      <img src={GRAPHICS.Octopus} alt="Img" />

      <QuickGuideWrapper>
        <QuickGuide>Quick Guide</QuickGuide>
        <img src={GRAPHICS.Info} alt="Info" />
      </QuickGuideWrapper>
      <GuideContent>
        <p>
          Select a topic from the tree on the left to begin attempting
          questions.
        </p>

        <p>
          You may also attempt questions on a snack (sub-topic) by selecting it
          from the tree.
        </p>

        <p>
          Use the date filter to access past exam questions from a specific
          period.
        </p>
      </GuideContent>
    </WelcomeScreenWrapper>
  );
};

export default WelcomeScreen;
