import React, { useState } from 'react';
import BreadCrumb from './../../../BreadCrumb';
import MyLibraryNotesHeader from './../../../Headers/MyLibrary/Notes/Notes';
import NotesContent from './NotesContent';
import GridComp from './../../../../../UI/Grid/Grid';
import CardComp from './../../../../../UI/Card/Card';
import LeftSide from './../../../LeftSideBar/LeftSide';
import { Box, useMediaQuery } from '@mui/material';
import api from '../../../../../../Services';
import responseFunctions from '../../../../../../helpers/createResponses';
import Loader from '../../../../../UI/Loader/Loader';
import Alert from '../../../../../UI/Alert/Alert';
import ErrorBoundary from './../../../../../../Errors/ErrorBoundary';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { myLibraryActions } from './../../../../../../redux/reducers/myLibrary';
import { Mixpanel } from '../../../../../../helpers/Mixpanel';
import MobileBottomBar from '../../../../../mobileBottomBar/MobileBottomBar';
import MobileCourseIndicator from '../../../../../mobileBottomBar/MobileCourseIndicator';
import MobileDetector from '../../../../../../helpers/mobileDetector';
import Header from '../../../Headers/LearningResources/Notes/Notes';
import CourseInfo from '../../../../../coursoInfo/courseInfo';

const MyLibraryNotes = () => {
  const params = useParams();
  const { id: courseId } = params;
  const { enqueueSnackbar } = useSnackbar();
  const { createError, createLoading, initialError, initialLoading } =
    responseFunctions;
  const dispatch = useDispatch();
  const isMobile = MobileDetector();
  const [course, setCourse] = React.useState({});
  const [selectedFromList, setSelectedFromList] = React.useState({});
  const [filteredData, setFilteredData] = React.useState([]);
  const [error, setError] = React.useState(initialError);
  const [loading, setLoading] = React.useState(initialLoading);
  const [notes, setNotes] = React.useState([]);
  const _1000px = useMediaQuery('(min-width: 1000px)');
  const _830px = useMediaQuery('(max-width: 830px)');
  const [subjectName, setSubjectName] = React.useState('');
  const [showNotes, setShowNotes] = React.useState(true);
  const [hierarchyData, setHeirarchyData] = React.useState(null);
  const [unit, setUnit] = React.useState({ id: '', name: '' });
  const [isHierarchy, setIsHierarchy] = React.useState(false);
  const [allTags, setAllTags] = React.useState([]);
  const [selectedTag, setSelectedTag] = React.useState({ title: 'all' });
  const [loaderMsg, setLoaderMsg] = useState(true);
  const [openMobileDropDown, SetOpenMobileDropDown] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [activeItem, setActiveItem] = useState('');
  const [structuredData, setStructuredData] = useState([]);

  const handleOpenMobileDropDown = () => {
    SetOpenMobileDropDown(!openMobileDropDown);
  };

  function handleSelection(e) {
    if (e.type === 'unit') {
      setSelectedData({
        ...e,
        unitname: e.name,
        type: 'unit',
        active: 'unit',
      });
    } else if (e.type === 'chapter') {
      setSelectedData((prevData) => ({
        ...prevData,
        ...e,
        chaptername: e.name,
        type: 'chapter',
        active: 'chapter',
      }));
    } else {
      setSelectedData((prevData) => ({
        ...prevData,
        ...e,
        snackname: e.name,
        type: 'snack',
        active: 'snack',
      }));
    }
  }

  // ************* single note delete start **************
  const deleteSingleNoteApi = async (id) => {
    const token = localStorage.getItem('auth');
    let temp = await api.deleteNote(id, token);
    return temp;
  };

  const handleSignleDeleteNote = (category, singleNoteData) => {
    setShowNotes(true);
    deleteSingleNoteApi(singleNoteData.id)
      .then((el) => {
        if (!el.error) {
          let array = [];
          for (let i = 0; i < notes.length; i++) {
            if (parseInt(singleNoteData.noteId) !== parseInt(notes[i].noteId)) {
              array.push(notes[i]);
            }
          }
          dispatch(myLibraryActions.filteredNotes(array));
          setNotes(array);
          setFilteredData(array);
          fetchNotesHeirarchy();
          setShowNotes(false);
          enqueueSnackbar('Selected data deleted successfully', {
            variant: 'success',
            autoHideDuration: 4000,
            anchorOrigin: isMobile
              ? { vertical: 'top', horizontal: 'center' }
              : { vertical: 'bottom', horizontal: 'left' },
          });
        } else {
          enqueueSnackbar('Edits failed, please try again!', {
            variant: 'error',
            autoHideDuration: 6000,
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar(`${error} please try again!`, {
          variant: 'error',
          autoHideDuration: 6000,
        });
      });
  };

  const fetchNotesHeirarchy = async () => {
    let token = localStorage.getItem('auth');
    await api
      .getHierarchyForMyLibrary(token, courseId)
      .then((resp) => {
        if (resp.data.length) {
          setIsHierarchy(true);
        } else {
          setIsHierarchy(false);
        }
        setLoaderMsg(false);

        setHeirarchyData(resp);
      })
      .catch((err) => console.error(err));
  };
  // **************** Single note delete function end ***************************
  // const getNotesFromApi = async (id, type) => {
  //   let token = localStorage.getItem("auth");
  //   let temp = await api.getNotesFromDbLearningResources({ id, type }, token);
  //   return temp;
  // };
  const handleDataIfTrue = (el, e) => {
    let temp = el.data;

    let update = [];
    switch (e.type) {
      case 'unit': {
        update = temp.filter((each) => each);
        setUnit({ id: e.id, name: e.name });
        break;
      }
      case 'chapter': {
        update = temp.filter((each) => each.chapterId === e.id);
        break;
      }
      case 'snack': {
        update = temp.filter((each) => each.snackId === e.id);
        break;
      }
      default:
        break;
    }
    setNotes(update);
    if (update.length > 0 && selectedTag.title !== 'all') {
      const filteredDataWithTag = update.filter(
        (item) => item.tagId === selectedTag.id
      );
      setFilteredData(filteredDataWithTag);
    } else {
      setFilteredData(update);
    }

    setSelectedFromList(e);
    setShowNotes(false);
    // switch (e.type) {
    //   case "unit":
    //     setDataType("unit");
    //     break;
    //   case "chapter":
    //     setDataType("chapter");
    //     break;

    //   default:
    //     break;
    // }
  };
  const selectFromListHandler = async (e) => {
    setActiveItem(e.label);
    handleSelection(e);
    const { id, type, name } = e;
    setShowNotes(true);
    setSubjectName(name);
    if (e.nested && !e.isExpanded) {
      if (e.layer === 2) {
        const unitData = structuredData.filter((unit) => {
          return unit.id === e.unitId;
        });
        fetchTagByunitId(unitData[0].id, unitData[0]);
        setShowNotes(false);
      } else {
        setSelectedFromList((prevState) => ({
          ...prevState,
          isExpanded: false,
        }));
        setShowNotes(false);
      }
      return;
    }

    if (type === 'unit') {
      fetchTagByunitId(id, e);
      setShowNotes(false);
    }
    if (type === 'chapter') {
      fetchTagByChapterId(id, e);
    }
    if (type === 'snack') {
      fetchTagBySnackId(id, e);
    }
  };
  const fetchTagByunitId = async (unitId, selection) => {
    setNotes([]);

    await api
      .tagsByUnitId({ unitId, courseId })
      .then((resp) => {
        handleDataIfTrue(resp, selection);
      })
      .catch((err) => console.error(err));
  };
  const fetchTagByChapterId = async (chapterId, selection) => {
    setNotes([]);

    await api
      .tagsByChapterId({ chapterId, courseId })
      .then((resp) => {
        handleDataIfTrue(resp, selection);
      })
      .catch((err) => console.error(err));
  };
  const fetchTagBySnackId = async (snackId, selection) => {
    await api
      .tagsBySnackId({ snackId, courseId })
      .then((resp) => {
        handleDataIfTrue(resp, selection);
      })
      .catch((err) => console.errror(err));
  };

  const callResetNotesApi = async (data) => {
    let token = localStorage.getItem('auth');
    let temp = await api.restNotesFromDB(token, data, 'snack');
    return temp;
  };

  const resetMyLibraryNotes = (unitId, chapterId, snackId) => {
    callResetNotesApi({ unitId, chapterId, snackId })
      .then((el) => {
        if (!el.error) {
          getNotes();
          enqueueSnackbar(`Selected Snack Restored Successfully`, {
            variant: 'success',
            autoHideDuration: 6000,
            anchorOrigin: isMobile
              ? { vertical: 'top', horizontal: 'center' }
              : { vertical: 'bottom', horizontal: 'left' },
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(`${error}please try again!`, {
          variant: 'error',
          autoHideDuration: 6000,
        });
      });
  };

  const fetchLibraryApi = async () => {
    let token = localStorage.getItem('auth');
    let temp = await api.fetchNotesFromLibrary(token);
    return temp;
  };

  const filterNotesThroughCourseId = (notes) => {
    const filtered = notes.filter((note) => note.courseId === +courseId);
    return filtered;
  };

  const getNotes = (category) => {
    //fetching all notes from DB
    setLoading(createLoading(true, 'load-notes'));

    fetchLibraryApi()
      .then((el) => {
        setSelectedFromList({});
        //filtering notes with same course id
        const filterData = filterNotesThroughCourseId(el.data);
        if (!el.error) {
          if (el.data.length > 0) {
            setError(initialError);
          } else if (el.data.length === 0) {
            setError(
              createError(true, 'No notes found in the library!', 'info')
            );
          }

          filterData.sort((a, b) => a.id - b.id);

          dispatch(myLibraryActions.filteredNotes(filterData));

          if (category && category === 'delete') {
            setNotes(filterData);
          }
        } else {
          setError(createError(true, 'An error occured', 'info'));
        }
        setLoading(createLoading(false, 'load-notes'));
        if (category && category === 'delete') {
          return filterData;
        }
      })
      .catch((err) => console.error(err));
  };

  const deleteMyLibraryApi = async (snackId) => {
    let token = localStorage.getItem('auth');
    let temp = await api.deleteMyLibraryNotes(token, snackId, 'snack');
    return temp;
  };

  const deleteMylibraryData = (snackId) => {
    deleteMyLibraryApi(snackId)
      .then((el) => {
        if (!el.error) {
          let array = [];
          for (let i = 0; i < notes.length; i++) {
            if (parseInt(snackId) !== parseInt(notes[i].snackId)) {
              array.push(notes[i]);
            }
          }
          dispatch(myLibraryActions.filteredNotes(array));
          setNotes(array);
          setFilteredData(array);
          fetchNotesHeirarchy();
          setShowNotes(false);
          enqueueSnackbar(`Selected Snack deleted successfully `, {
            variant: 'success',
            autoHideDuration: 4000,
            anchorOrigin: isMobile
              ? { vertical: 'top', horizontal: 'center' }
              : { vertical: 'bottom', horizontal: 'left' },
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err, {
          variant: 'error',
          autoHideDuration: 6000,
        });
      });
  };

  const handleDelete = (snackId) => {
    deleteMylibraryData(snackId);
    Mixpanel.track('Deleted Notes from My Library', {
      page: 'My Library Notes',
    });
  };

  const deleteSingleNote = (singleNoteData) => {
    handleSignleDeleteNote('delete', singleNoteData);
  };
  const handleResetNotes = (unitId, chapterId, snackId) => {
    resetMyLibraryNotes(unitId, chapterId, snackId);
  };
  const handleTagSelection = (_, tag) => {
    setSelectedTag(tag);
  };

  const handleTagChange = React.useCallback(() => {
    if (Array.isArray(notes) && notes.length > 0) {
      const filteredData = notes.filter((note) => {
        return selectedTag.title === 'all' || note.tagId === selectedTag.id;
      });
      setFilteredData(filteredData);
    }
  }, [selectedTag]);
  React.useEffect(() => {
    handleTagChange();
  }, [handleTagChange]);
  React.useEffect(() => {
    fetchNotesHeirarchy();
  }, []);

  React.useEffect(() => {
    if (selectedData?.layer === 1 && selectedData.isExpanded === false) {
      setShowNotes(true);
    }
  }, [selectedData]);
  return (
    <>
      <Box
        sx={{
          padding: { xs: '15px 25px', md: '0px 0px' },
          paddingBottom: { xs: '100px', sm: '0px' },
        }}
      >
        {loading.value ? (
          <Loader />
        ) : (
          <>
            {error.value ? (
              <>
                <Alert severity={error.severity} message={error.message} />
              </>
            ) : isHierarchy ? (
              <>
                <>
                  <BreadCrumb />

                  <Header
                    _1000px={_1000px}
                    _830px={_830px}
                    allTags={allTags}
                    setAllTags={setAllTags}
                    activeTag={selectedTag}
                    handleSelection={handleTagSelection}
                  />

                  {showNotes && (
                    <MobileCourseIndicator
                      courseId={courseId}
                      tab="My Library Notes"
                    />
                  )}

                  <GridComp container spacing={2}>
                    <GridComp
                      item
                      xs={_1000px ? 3 : _830px ? 12 : 4}
                      sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                      <CardComp nopadding={true}>
                        <LeftSide
                          setStructuredData={setStructuredData}
                          selectedFromList={selectedFromList}
                          onListClick={selectFromListHandler}
                          hierarchyData={hierarchyData}
                          getCourseInfo={(e) => setCourse(e)}
                          isLibrary={true}
                          handleOpenMobileDropDown={() => {}}
                          selectedData={selectedData}
                        />
                      </CardComp>
                    </GridComp>

                    <GridComp item xs={_1000px ? 9 : _830px ? 12 : 8}>
                      {selectedFromList.isExpanded ? (
                        <ErrorBoundary>
                          <CardComp
                            nopadding={true}
                            style={{ paddingBottom: '2rem' }}
                          >
                            {showNotes ? (
                              <Loader />
                            ) : (
                              <NotesContent
                                unit={unit}
                                subjectName={subjectName}
                                handleResetNotes={handleResetNotes}
                                getNotes={getNotes}
                                deleteSingleNote={deleteSingleNote}
                                handleDelete={handleDelete}
                                selectedFromList={selectedFromList}
                                notes={filteredData}
                                course={course}
                                showNotes={showNotes}
                              />
                            )}
                          </CardComp>
                        </ErrorBoundary>
                      ) : (
                        <>
                          {isMobile ? (
                            <></>
                          ) : (
                            <CourseInfo tab="My Library Notes" />
                          )}
                        </>
                      )}
                    </GridComp>
                  </GridComp>
                </>
              </>
            ) : loaderMsg ? (
              <Loader />
            ) : (
              <>
                {' '}
                <Alert
                  severity={'info'}
                  title={'Your Notes Collection is Empty!'}
                />
              </>
            )}
          </>
        )}
      </Box>

      <MobileBottomBar
        selectionText="Click here to get started "
        openMobileDropDown={openMobileDropDown}
        handleOpenMobileDropDown={handleOpenMobileDropDown}
        showData={showNotes}
        selectedData={selectedData}
        leftSideMenu={
          <LeftSide
            selectedFromList={selectedFromList}
            onListClick={selectFromListHandler}
            hierarchyData={hierarchyData}
            getCourseInfo={(e) => setCourse(e)}
            isLibrary={true}
            isSimulation={false}
            active={activeItem}
            handleOpenMobileDropDown={handleOpenMobileDropDown}
            selectedData={selectedData}
          />
        }
      />
    </>
  );
};

export default MyLibraryNotes;
