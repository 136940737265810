import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  coursesLoading: false,
};

const dashboard = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.coursesLoading = action.payload;
    },
  },
});

export const dashboardActions = dashboard.actions;

export const dashboardReducer = dashboard.reducer;
