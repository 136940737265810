import Percentage from './Percentage';
import { Radar } from 'react-chartjs-2';
import React, { useState, useEffect } from 'react';
import { PinkText } from '../../Assessment/Assessment.style';
import capitalize from '../../../../../../../../helpers/capitalize';
import GRAPHICS from '../../../../../../../../assets/images/assessment';

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Box, TableRow } from '@mui/material';
import {
  ChpaterListWrapper,
  ConceptualRtingText,
  GraphWrapper,
  LeftSideWrapper,
  QuestionText,
  SnackPercentage,
  SnackText,
  TitleBorder,
  WrapperContainer,
} from '../Analytics.style';
import {
  TableData,
  TableDataNames,
  TableHeading,
} from './chapterAnalyticsStyle';

import {
  BreakdownWrapper,
  TableExpend,
  TableExpendWrapper,
} from '../Analytics.style';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const ChapterAnalytics = ({
  names,
  attemptedAnswer,
  showSnacks,
  newSelection,
  setShowSnacks,
  analyticsData,
  chapterData,
}) => {
  const percentagedData =
    names &&
    analyticsData &&
    attemptedAnswer &&
    attemptedAnswer?.map((value, index) => {
      return Math.floor(
        (attemptedAnswer[index] / analyticsData[index]?.attemptedQuestions) *
          100 || 0
      );
    });

  const data = {
    labels: names,
    datasets: [
      {
        label: 'Analytics',
        data: percentagedData,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  const calculatedData =
    names &&
    attemptedAnswer &&
    analyticsData &&
    names
      ?.map((item, index) => ({
        name: item,
        percentage:
          (attemptedAnswer[index] / analyticsData[index]?.attemptedQuestions) *
            100 || 0,
      }))
      .sort((a, b) => b.percentage - a.percentage);

  const [pointImage, setPointImage] = useState([]);

  useEffect(() => {
    if (!percentagedData) return;

    const drawCanvas = () => {
      const images = [];
      const promises = percentagedData?.map((percentage, index) => {
        return new Promise((resolve) => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const radius = 13;
          canvas.width = 2 * radius;
          canvas.height = 2 * radius;

          ctx.beginPath();
          ctx.arc(radius, radius, radius, 0, 2 * Math.PI);
          ctx.fillStyle = '#624BA2';
          ctx.fill();

          ctx.fillStyle = '#FFFFFF';
          ctx.font = '20px';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(`${percentage ? percentage : 0}%`, radius, radius);

          const imageDataURL = canvas.toDataURL();
          const image = new Image();
          image.src = imageDataURL;

          image.onload = () => {
            images[index] = image;
            resolve();
          };
        });
      });

      Promise.all(promises).then(() => {
        const sortedImages = percentagedData.map(
          (percentage, index) => images[index]
        );
        setPointImage(sortedImages);
      });
    };

    drawCanvas();
  }, [attemptedAnswer, names, analyticsData]);

  return (
    <Box>
      <WrapperContainer>
        <LeftSideWrapper>
          <ConceptualRtingText>
            Your conceptual ranking for{' '}
            <TitleBorder>{capitalize(newSelection?.label) || ''}</TitleBorder>
          </ConceptualRtingText>

          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            {calculatedData &&
              calculatedData?.map((item, index) => {
                return (
                  <ChpaterListWrapper>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: {
                          xs: 'flex-start',
                          sm: 'space-between',
                        },
                        borderBottom: '0.5px solid #624BA2',
                      }}
                    >
                      <SnackText>
                        {index + 1}. {capitalize(item?.name)}{' '}
                      </SnackText>{' '}
                      <SnackPercentage>
                        {'('}
                        {Math.floor(item?.percentage || 0)}%{')'}
                      </SnackPercentage>{' '}
                    </Box>
                  </ChpaterListWrapper>
                );
              })}
          </Box>
        </LeftSideWrapper>

        <GraphWrapper>
          <Radar
            data={data}
            options={{
              scale: {
                display: false,
                min: 0,
                max: 100,

                ticks: {
                  stepSize: 10,
                  display: false,
                },
              },

              scales: {
                r: {
                  pointLabels: {
                    padding: 10,
                    color: '#624ba2',
                    fontFamily: 'Inter',
                    font: {
                      size: 14,
                      weight: 500,
                    },
                  },

                  ticks: {
                    display: false,
                  },
                },
              },

              elements: {
                point: {
                  pointStyle: pointImage,
                },
              },
              plugins: {
                tooltip: {
                  enabled: false,
                },
                legend: {
                  display: false,
                },
              },
            }}
          />
        </GraphWrapper>
      </WrapperContainer>

      <Box style={{ width: '100%', overflow: 'auto', marginTop: '20px' }}>
        <QuestionText>Questions</QuestionText>

        <table
          style={{
            width: '100%',
            overflow: 'auto',
            textAlign: 'center',
          }}
        >
          <TableRow style={{ padding: '20px 0px' }}>
            <TableHeading>Topic Name</TableHeading>
            <TableHeading>Total</TableHeading>
            <TableHeading>Remaining</TableHeading>
            <TableHeading>Attempted</TableHeading>
            <TableHeading>Correct</TableHeading>
            <TableHeading>Incorrect</TableHeading>
            <TableHeading>Percentage</TableHeading>
          </TableRow>

          {/* When we click on chapter this will show the chapter analytics */}

          <TableRow>
            <TableDataNames>
              {capitalize(newSelection?.label)}

              {showSnacks === false && (
                <TableExpendWrapper onClick={() => setShowSnacks(true)}>
                  <TableExpend>
                    <PinkText>Expand Breakdown</PinkText>
                  </TableExpend>

                  <img src={GRAPHICS.Expend} alt="expend" />
                </TableExpendWrapper>
              )}
            </TableDataNames>

            <TableData color="#624BA2">{chapterData?.totalQuestions}</TableData>

            <TableData color='"#11263C"'>{chapterData?.remaining}</TableData>
            <TableData color="#624BA2">
              {chapterData?.attemptedQuestions}
            </TableData>
            <TableData color="#2A7A7E" border={true}>
              {chapterData?.correct}
            </TableData>

            <TableData color="#C20F0F" border={true}>
              {chapterData?.incorrect}
            </TableData>

            <TableData color="">
              <Percentage
                value={Math.floor(
                  (chapterData?.correct * 100) / chapterData?.attemptedQuestions
                )}
              />
            </TableData>
          </TableRow>

          {/* When we click on expend breakdown then this will show the nested snacks analytics */}

          {showSnacks && (
            <>
              <TableRow>
                <td colSpan={7}>
                  <BreakdownWrapper
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '5px',
                      borderTop: ' 1px solid rgba(170, 165, 177, 0.21)',
                      borderBottom: ' 1px solid rgba(170, 165, 177, 0.21)',
                      margin: '20px 0px',
                      padding: '15px 0px',
                    }}
                    onClick={() => setShowSnacks(false)}
                  >
                    <PinkText fontSize="12px" fontWeight="400">
                      This is the breakdown of your score from chapter{' '}
                      {capitalize(newSelection?.label)}
                    </PinkText>

                    <img src={GRAPHICS.ExpendClose} alt="close" />
                  </BreakdownWrapper>
                </td>
              </TableRow>

              {analyticsData &&
                analyticsData?.map((item, index) => {
                  return (
                    <TableRow>
                      <TableDataNames>{names[index]}</TableDataNames>
                      <TableData color="#624BA2">
                        {item?.totalQuestions}
                      </TableData>
                      <TableData color="#11263C">{item?.remaining}</TableData>
                      <TableData color="#624BA2">
                        {item?.attemptedQuestions}
                      </TableData>
                      <TableData color="#2A7A7E" border={true}>
                        {item?.correct}
                      </TableData>

                      <TableData color="#C20F0F" border={true}>
                        {item?.incorrect}
                      </TableData>

                      <TableData color="">
                        <Percentage
                          value={Math.floor(
                            (attemptedAnswer[index] /
                              analyticsData[index]?.attemptedQuestions) *
                              100 || 0
                          )}
                        />
                      </TableData>
                    </TableRow>
                  );
                })}
            </>
          )}
        </table>
      </Box>
    </Box>
  );
};

export default ChapterAnalytics;
