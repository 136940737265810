import { Modal } from '@mui/material';
import {
    BlurredBackground,
    BlurredModalContent,
    ModalText,
    ModalButtons,
    ModalStack,
    ModalTextWrapper,
    ModalTextWarning,
} from './mobalModal.style';
import Divider from "@mui/material/Divider";
import palette from '../../config/palette'
import {
    buildStyles,
    CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { ProgressBarTyporgraphy } from '../Containers/Subject/Tabs/MyLibrary/Notes/ThreeDotMenu/Models/DeleteModal/DeleteModel.style';

export default function MobileRestoreModal({ open, snack, handleResetNotes, setOpen, snackId, chapterId,
    unitId }) {

    const deleteHandler = () => {
        handleResetNotes(unitId, chapterId, snackId);
        setOpen(false);
    };


    return (
        <Modal open={open} sx={{ zIndex: '99999' }} >
            <BlurredBackground>
                <BlurredModalContent>
                    <ModalTextWrapper>

                        <ModalText>
                            Are you sure you want restore The data for Snack "{snack}"?
                        </ModalText>
                        <ModalTextWarning color={palette.colors.unselected}>
                            You will lose all editing in snack if you click restore.
                        </ModalTextWarning>
                    </ModalTextWrapper>

                    <Divider />
                    <ModalStack >
                        <ModalButtons onClick={() => { deleteHandler() }}>
                            Restore
                        </ModalButtons>
                        <ModalButtons onClick={() => { setOpen(false) }}>
                            Cancel
                        </ModalButtons>
                    </ModalStack>
                </BlurredModalContent>
            </BlurredBackground>
        </Modal>
    )
}
