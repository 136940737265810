import React, { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import Stack from '../../../../../UI/Stack/Stack';
import Typography from '../../../../../UI/Typography/TypographyCompo';
import ToolTip from '../../../../../UI/Tooltip/ToolTip';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HighlightSvg from '../../../../../../assets/images/Subject/HighlightSvg';
import CommentSvg from '../../../../../../assets/images/Subject/CommentSvg';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import pallete from '../../../../../../config/palette';
import SearchBar from '../../../../../UI/SearchBar/SearchBar';
import physicsSvg from '../../../../../../assets/images/courses/physics.webp';
import biologySvg from '../../../../../../assets/images/courses/biology.webp';
import mathSvg from '../../../../../../assets/images/courses/maths.webp';
import ChmistrySvg from '../../../../../../assets/images/courses/chemistry.webp';
import { myLibraryHighlightActions } from './../../../../../../redux/reducers/myLibraryHighlight';
import {
  StackWrapper,
  HeaderButton,
  ExpandButton,
  HeaderDiv,
  SearchDiv,
  TagsDiv,
  TagsButton,
  PhysicsImgae,
  PhysicsImageContainer,
  Container,
  LeftContainer,
  SearchBarWrapper,
  NotesCourseName,
  SearchBarText,
  DividerWrapper,
} from './Notes.style';
import api from './../../../../../../Services';
import Loader from './../../../../../UI/Loader/Loader';
import { learningResourcesHighlightActions } from './../../../../../../redux/reducers/learningResourcesHighlight';
import { learningResourcesNotesListControllerActions } from '../../../../../../redux/reducers/learningResourcesNotesListController';
import { Grid } from '@mui/material';
import { CommentResourcesHighlightActions } from '../../../../../../redux/reducers/commentResourceHiglight';
import MobileDetector from '../../../../../../helpers/mobileDetector';
import SearchBarSvg from '../icons/SearchBar';
import { display } from '@mui/system';
import constants from '../../../../../../config/constants';

const Header = ({
  allTags,
  setAllTags,
  handleSelection,
  activeTag,
  hideSearch,
  isSimulation,
  tab,
}) => {
  const isMobile = MobileDetector();
  const expandAllIsTrue = useSelector(
    (state) => state.learningResourcesNotesListController.value
  );
  const { name, id } = useParams();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [searchedData, setSearchedData] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [activeState, setActiveState] = useState({ title: 'all' });
  const isHighlightingActive = useSelector(
    (state) => state.learningResources.isHighlighting
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const expandHandler = () => {
    setIsExpanded((prevValue) => !prevValue);
    dispatch(learningResourcesNotesListControllerActions.toggleValue());
  };

  const getTagsFromDbB = async (token) => {
    if (isSimulation) {
      let temp = await api.getAllConceptTags(token);
      return temp;
    } else {
      let temp = await api.getAllTags(token);
      return temp;
    }
  };
  const getTagsApi = async () => {
    let token = localStorage.getItem('auth');
    getTagsFromDbB(token)
      .then((el) => {
        if (el.error) {
          return;
        }
        const { data } = el;
        setAllTags([...data]);
        setShowLoader(false);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getTagsApi();
    setShowLoader(true);
  }, []);

  let subjectImage;
  switch (name) {
    case 'physics':
      subjectImage = physicsSvg;
      break;
    case 'biology':
      subjectImage = biologySvg;
      break;
    case 'math':
      subjectImage = mathSvg;
      break;
    case 'chemistry':
      subjectImage = ChmistrySvg;
      break;
    default:
      subjectImage = physicsSvg;
      break;
  }

  const selectTagHandler = (e, item) => {
    handleSelection(e, item);
  };
  const isCommentHiglightingActive = useSelector(
    (state) => state.commentResources.isHighlighting
  );

  const dataHandler = (e) => {
    setSearchedData(e.target.value);

    dispatch(myLibraryHighlightActions.searchedValueState(e.target.value));
  };

  //Getting COURSES Image inside our LEARNING RESOURCE>> NOTES
  useEffect(() => {
    const getImageURL = async () => {
      try {
        const token = localStorage.getItem('auth');
        const response = await api.getCourses(token);
        const { data } = response;
        const course = data.find((course) => course.id === parseInt(id));

        if (course) {
          setImageURL(course.img || '');
        }
      } catch (error) {
        console.error(error);
      }
    };

    getImageURL();
  }, [id]);
  return (
    <div>
      <StackWrapper>
        <Container>
          <LeftContainer>
            <NotesCourseName>
              {name?.charAt(0).toUpperCase() + name?.slice(1)} {tab}
            </NotesCourseName>

            {isMobile ? (
              ''
            ) : (
              <SearchDiv>
                {tab !== 'Flashcards' && tab !== 'Simulations' && (
                  <SearchBar
                    searchedValue={searchedData}
                    searchHandler={dataHandler}
                  />
                )}
              </SearchDiv>
            )}
          </LeftContainer>

          <SearchBarWrapper>
            <SearchBarSvg />
          </SearchBarWrapper>
        </Container>

        <TagsDiv container spacing={0}>
          {showLoader ? (
            <Loader />
          ) : (
            <>
              <SearchBarText
                sx={{
                  display: { xs: 'none', sm: 'flex' },
                }}
              >
                Filter by Tag
              </SearchBarText>
              <TagsButton
                isselected={!activeState.id && activeState.title === 'all'}
                onClick={(e) => {
                  setActiveState({ title: 'all' });
                  selectTagHandler(e, { title: 'all' });
                }}
                size="small"
                variant={'text'}
              >
                All
              </TagsButton>
              {allTags.map((item, index) => (
                <Grid sm={2} item>
                  <TagsButton
                    isselected={item.id === activeState.id}
                    variant="text"
                    size="small"
                    key={index}
                    onClick={(e) => {
                      setActiveState(item);
                      selectTagHandler(e, item);
                    }}
                  >
                    {item.title}
                  </TagsButton>
                </Grid>
              ))}
            </>
          )}
        </TagsDiv>
        {tab !== 'Flashcards' && tab !== 'Simulations' && (
          <>
            <Divider light style={{ marginBottom: '-12px' }} />
            <HeaderDiv>
              <Stack
                direction="row"
                spacing={{ xs: 3, sm: 3, md: 3 }}
                sx={{
                  width: '100%',
                  overflowX: 'auto',
                  justifyContent: 'space-between', // Set justifyContent to space-between
                }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    width: '100%',
                    justifyContent: { xs: 'center', sm: 'start' },
                  }}
                >
                  {/* New Stack for grouping buttons */}
                  <ToolTip title="Mark text to highlight">
                    <HeaderButton
                      isActive={isHighlightingActive}
                      variant="text"
                      startIcon={
                        <HighlightSvg
                          fillColor="#251038"
                          isActive={isHighlightingActive}
                          ismobile={isMobile}
                        />
                      }
                      onClick={(e) => {
                        isCommentHiglightingActive &&
                          dispatch(
                            CommentResourcesHighlightActions.toggleHighlightState()
                          );
                        dispatch(
                          learningResourcesHighlightActions.toggleHighlightState()
                        );
                      }}
                    >
                      {isMobile
                        ? constants.HighlightTextMobile
                        : constants.HighlightText}
                    </HeaderButton>
                  </ToolTip>
                  <Divider orientation="vertical" variant="string" flexItem />
                  <ToolTip title="Mark text to Comment">
                    <HeaderButton
                      variant="text"
                      startIcon={
                        <CommentSvg
                          fillColor="#251038"
                          isActive={isCommentHiglightingActive}
                          ismobile={isMobile}
                        />
                      }
                      onClick={() => {
                        isHighlightingActive &&
                          dispatch(
                            learningResourcesHighlightActions.toggleHighlightState()
                          );
                        dispatch(
                          CommentResourcesHighlightActions.toggleHighlightState()
                        );
                      }}
                      isActive={isCommentHiglightingActive}
                    >
                      {isMobile
                        ? constants.CommentTextMobile
                        : constants.CommentText}
                    </HeaderButton>
                  </ToolTip>
                </Stack>
     
              </Stack>
            </HeaderDiv>

            <DividerWrapper>
              <Divider light />
            </DividerWrapper>
          </>
        )}
      </StackWrapper>
    </div>
  );
};

export default Header;
