import { Modal } from '@mui/material';
import React, { useEffect, useState } from 'react'
import {
    BlurredBackground,
    BlurredModalContent,
    ModalText,
    ModalButtons,
    ModalStack,
    ModalTextWrapper,
    ModalTextWarning,
} from './mobalModal.style';
import Divider from "@mui/material/Divider";
import palette from '../../config/palette'
import {
    buildStyles,
    CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { ProgressBarTyporgraphy } from '../Containers/Subject/Tabs/MyLibrary/Notes/ThreeDotMenu/Models/DeleteModal/DeleteModel.style';

export default function MobileUndoModal({ open, snack, subjectName, setOpen, handleDelete, snackId }) {

    const [value, setValue] = useState(5);
    const secondStyle = {
        width: 70,
        height: 70,
    };

    useEffect(() => {
        const progresBarTimer = setInterval(() => {
            setValue(value - 1);
        }, 1000);
        if (value < 0) {
            handleDelete(snackId);
            clearTimeout(progresBarTimer);
            setOpen(false);
        }
        return () => clearTimeout(progresBarTimer);
    }, [open, value]);


    return (
        <Modal open={open} sx={{ zIndex: '99999' }} >
            <BlurredBackground>
                <BlurredModalContent>
                    <ModalTextWrapper>
                        <div style={secondStyle}>
                            <CircularProgressbarWithChildren
                                value={value}
                                counterClockwise={true}
                                maxValue={5}
                                background={true}
                                strokeWidth={2}
                                styles={buildStyles({
                                    pathColor: palette.colors.secondaryModified,
                                    backgroundColor: "#f9e5f5",
                                    pathTransitionDuration: 2.5,
                                })}
                            >
                                <ProgressBarTyporgraphy variant="body1">
                                    <strong>{value} sec </strong>
                                </ProgressBarTyporgraphy>
                            </CircularProgressbarWithChildren>
                        </div>

                        <ModalText>
                            The snack <span style={{ color: "#624ba2" }}>{snack.toLowerCase()}</span> is being deleted
                        </ModalText>
                        <ModalTextWarning color={palette.colors.unselected}>
                            <em>You have 5 seconds to undo your action </em>
                        </ModalTextWarning>


                    </ModalTextWrapper>

                    <Divider />
                    <ModalStack >

                        <ModalButtons onClick={() => { setOpen(false) }}>
                            Cancel
                        </ModalButtons>
                    </ModalStack>
                </BlurredModalContent>
            </BlurredBackground>
        </Modal>
    )
}
