import React from "react";

const LibrarySvg = ({ color, isMobile }) => {
  return (
    <div style={{ marginRight: "0.5rem", marginTop: "0.2rem" }}>
      {isMobile ? <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19" fill="none">
        <path d="M15.1197 19H2.90536C1.78299 19 0.869629 18.0866 0.869629 16.9643V3.39275C0.869629 2.27039 1.78299 1.35703 2.90536 1.35703H11.0483C11.2282 1.35703 11.4008 1.42852 11.5281 1.55578C11.6553 1.68303 11.7268 1.85563 11.7268 2.0356C11.7268 2.21557 11.6553 2.38817 11.5281 2.51543C11.4008 2.64268 11.2282 2.71418 11.0483 2.71418H2.90536C2.53078 2.71418 2.22678 3.01886 2.22678 3.39275V16.9643C2.22678 17.3382 2.53078 17.6428 2.90536 17.6428H15.1197C15.4943 17.6428 15.7983 17.3382 15.7983 16.9643V10.1785C15.7983 9.99854 15.8698 9.82594 15.997 9.69869C16.1243 9.57143 16.2969 9.49994 16.4769 9.49994C16.6568 9.49994 16.8294 9.57143 16.9567 9.69869C17.084 9.82594 17.1554 9.99854 17.1554 10.1785V16.9643C17.1554 18.0866 16.2421 19 15.1197 19Z" fill={color} />
        <path d="M17.6605 0.50001L17.6597 0.500009C17.3845 0.499212 17.112 0.553052 16.8578 0.658405C16.6036 0.763758 16.3729 0.918528 16.179 1.11374L16.1778 1.11494L7.24086 10.0512C7.2407 10.0513 7.24055 10.0515 7.24039 10.0516C7.2206 10.0717 7.20565 10.096 7.19668 10.1228C7.19667 10.1228 7.19665 10.1229 7.19663 10.1229L5.83972 14.1937L17.6605 0.50001ZM17.6605 0.50001C18.2168 0.500736 18.7502 0.722108 19.1436 1.11557C19.5369 1.50902 19.7581 2.04244 19.7587 2.5988L19.7587 2.59961C19.7595 2.87466 19.7056 3.14712 19.6004 3.40122L20.0623 3.59258M17.6605 0.50001L20.0623 3.59258M20.0623 3.59258L19.6004 3.40122C19.4951 3.65532 19.3405 3.88603 19.1455 4.07997L19.1445 4.08095L10.2063 13.0192C10.1862 13.0393 10.162 13.054 10.1364 13.0626L10.2945 13.5369L10.1364 13.0626L6.06544 14.4196C6.06539 14.4196 6.06535 14.4196 6.0653 14.4196C6.03388 14.43 6.00019 14.4315 5.96799 14.4239L5.85321 14.9085L5.96799 14.4239C5.93574 14.4162 5.90625 14.3998 5.88282 14.3763C5.85939 14.3529 5.84295 14.3234 5.83534 14.2911C5.82774 14.2589 5.82924 14.2252 5.83968 14.1938L20.0623 3.59258Z" stroke={color} />
      </svg> : <svg
        width="13"
        height="15"
        viewBox="0 0 15 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.79167 11.8757H9.3296C9.67162 11.8757 9.95524 11.5992 9.95524 11.2656C9.95524 10.9321 9.67162 10.6637 9.3296 10.6637H4.79167C4.44966 10.6637 4.16603 10.9321 4.16603 11.2656C4.16603 11.5992 4.44966 11.8757 4.79167 11.8757ZM7.61119 6.59632H4.79167C4.44966 6.59632 4.16603 6.8729 4.16603 7.20642C4.16603 7.53995 4.44966 7.80839 4.79167 7.80839H7.61119C7.95321 7.80839 8.23683 7.53995 8.23683 7.20642C8.23683 6.8729 7.95321 6.59632 7.61119 6.59632ZM13.5643 5.88503C13.7603 5.88285 13.9739 5.88047 14.1678 5.88047C14.3764 5.88047 14.5432 6.04316 14.5432 6.24653V12.7868C14.5432 14.8042 12.8665 16.4393 10.7978 16.4393H4.15769C1.98883 16.4393 0.220367 14.7229 0.220367 12.6079V3.83866C0.220367 1.82126 1.90541 0.169922 3.98251 0.169922H8.43703C8.65392 0.169922 8.82075 0.34075 8.82075 0.544117V3.16349C8.82075 4.65213 10.0804 5.87234 11.6069 5.88047C11.9635 5.88047 12.2778 5.88304 12.5529 5.8853C12.7669 5.88705 12.9572 5.88861 13.1251 5.88861C13.2439 5.88861 13.3978 5.88689 13.5643 5.88503ZM13.7943 4.69769C13.1086 4.70013 12.3003 4.69769 11.7189 4.69199C10.7963 4.69199 10.0363 3.95092 10.0363 3.05123V0.906924C10.0363 0.556319 10.4726 0.382237 10.722 0.635226C11.1737 1.09325 11.7946 1.72293 12.4125 2.34964C13.0281 2.97403 13.6408 3.59545 14.0804 4.04122C14.324 4.2877 14.1455 4.69687 13.7943 4.69769Z"
          fill={color}
        />
      </svg>}
    </div>
  );
};

export default LibrarySvg;

LibrarySvg.defaultProps = {
  color: "#ADB4C5",
};
