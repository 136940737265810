import React from 'react';
import FlashCardView from './../../../UI/FlashCardView/FlashCardView';
import { Box } from '@mui/material';
import { FlashcardOpenBox } from './FlashcardOpen.style';

const FlashcardOpen = ({
  selectedStack,
  doneFlashCards,
  sideEffect,
  flashCardIdSideEffect,
  getMenuState,
  awaitingUserResponse,
  loading,
  setCompletedFC,
  title,
}) => {
  return (
    <FlashcardOpenBox>
      <FlashCardView
        doneFlashCards={doneFlashCards}
        awaitingUserResponse={awaitingUserResponse}
        controllerData={selectedStack.questions}
        sideEffect={sideEffect}
        flashCardIdSideEffect={flashCardIdSideEffect}
        getMenuState={getMenuState}
        loading={loading}
        setCompletedFC={setCompletedFC}
        title={title}
      />
    </FlashcardOpenBox>
  );
};

export default FlashcardOpen;
