import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  data: {
    tagId: '',
    expanded: false,
    index: null,
    snackId: null,
  },
};

const notesSnackDropdown = createSlice({
  name: 'notessnackdropdown',
  initialState,
  reducers: {
    setDataforSnack: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const notesSnackDropdownActions = notesSnackDropdown.actions;

export const notesSnackDropdownReducer = notesSnackDropdown.reducer;
