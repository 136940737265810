import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

const ToolTips = ({
  children,
  title,
  arrow,
  backgroundColor,
  color,
  placement,
  open,
  show,
  arrowColor = '#624BA2',
  margin,
  isNotes,
}) => {
  return (
    <Tooltip
      sx={{
        display: show ? 'none' : 'flex',
      }}
      title={title}
      placement={placement}
      open={open}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: isNotes ? [0, 10] : [0, 0],
            },
          },
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            padding: '10px',
            backgroundColor: { backgroundColor },
            color: { color },
            margin: margin,
          },
        },
        arrow: {
          sx: {
            color: arrowColor,
          },
        },
      }}
      arrow={arrow}
    >
      {children}
    </Tooltip>
  );
};

export default ToolTips;
ToolTips.propTypes = {
  arrow: PropTypes.bool,
  title: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
};

ToolTips.defaultProps = {
  title: 'add',
  arrow: true,
  backgroundColor: '#624BA2',
  color: '#ffff',
  placement: 'bottom',
};
