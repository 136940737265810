import { Box, styled } from '@mui/material';

// Delete Modal CSS

export const DeleteModalWrapper = styled('div')(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: ' rgba(217, 217, 217, 0.30)',
  backdropFilter: 'blur(9.5px)',
  zIndex: 999,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const DeleteModel = styled('div')(() => ({
  backgroundColor: 'white',
  borderRadius: '15px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  maxWidth: '450px',
  paddingTop: '15px',
}));

export const DeletemodalClose = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'end',
  width: '100%',
  paddingRight: '30px',
  cursor: 'pointer',
}));

export const ResetWrapper = styled('div')(() => ({
  fontSize: '16px',
  letterSpacing: '1px',
  fontFamily: 'AvenirNext',
  textAlign: 'center',
  padding: '0px 20px',
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
}));

export const PurpleText = styled('span')(() => ({
  color: '#624BA2',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'Italic',
  fontWeight: 500,
}));
export const ConfirmationText = styled('span')(() => ({
  color: '#11263c',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 500,
}));

export const DeleteModalBtnWrapper = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  fontWeight: 600,
  fontSize: '14px',
  letterSpacing: '1px',
}));

export const DeleteYesButton = styled('button')(() => ({
  background: '#FCFCFE',
  width: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '17px 0',
  cursor: 'pointer',
  border: 'none',
  outline: 'none',
  fontSize: '14px',
  fontFamily: 'Inter',
  fontWeight: 600,
}));

export const DeleteNoButton = styled('button')(() => ({
  backgroundColor: '#624BA2',
  width: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '17px 0',
  borderBottomRightRadius: '15px',
  color: 'white',
  cursor: 'pointer',
  border: 'none',
  outline: 'none',
  fontSize: '14px',
  fontFamily: 'Inter',
  fontWeight: 600,
}));

// Header CSS

export const AnalyticsHeaderWrapper = styled('div')(({ theme }) => ({
  background: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  padding: '50px',
  borderRadius: '10px',
  fontFamily: 'Inter',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const AnalyticsNewUIWrapper = styled('div')(({ _750px }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  flexWrap: _750px ? 'wrap' : 'nowrap',
}));

export const WelcomeImage = styled('img')(() => ({
  width: '100%',
}));

export const WelcomeHeading = styled('span')(() => ({
  fontSize: '15px',
  color: '#624BA2',
}));

export const AnalyticsContentWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const AnalyticsHeading = styled('span')(() => ({
  fontSize: '28px',
  fontWeight: 600,
  color: '#624BA2',
}));

export const AnalyticsContent = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
}));

export const ResetBtnWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'end',
  width: '100%',
}));

export const UnorderList = styled('ul')(() => ({
  fontWeight: 700,
  fontSize: '15px',
  padding: 0,
}));

export const AnlayticsResetBtn = styled('button')(() => ({
  borderRadius: '10px',
  background: '#624BA2',
  outline: 'none',
  fontSize: '14px',
  fontWeight: 600,
  padding: '10px 20px',
  color: 'white',
  border: 'none',
  cursor: 'pointer',
}));

// Analytics scores CSS

export const UserAnalyticsWrapper = styled('div')(({ theme }) => ({
  background: '#11263C',
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: '15px',
  boxShadow: '4px 4px 17px 0px #0000001C',
  padding: '0px 0px',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    padding: '20px 0px',
  },
}));

export const UserAnalyticsHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const UserAnalyticsHeading = styled('span')(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 600,
  color: '#E015A2',
  fontFamily: 'Inter',
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
  },
}));

export const UserAnalyticsContent = styled('span')(({ theme }) => ({
  fontSize: '22px',
  fontWeight: 600,
  color: 'white',
  fontFamily: 'Inter',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '15px',
  },
}));

export const UserAnalyticsResetBtn = styled('div')(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 600,
  color: 'white',
  fontFamily: 'Inter',
  textDecoration: 'underline',
  padding: '20px',
  cursor: 'pointer',
  minWidth: '150px',
  textAlign: 'end',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
}));

export const WrapperContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '10px',
  padding: '20px',
  backgroundColor: '#FFFFFF',
  borderRadius: '10px',
  marginTop: '20px',
  boxShadow: '4px 4px 17px 0px rgba(0, 0, 0, 0.11)',
  alignItems: 'center',
  height: '362px',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
    height: 'auto',
  },
}));

export const ChpaterListWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '3px',
  paddingLeft: '10px',
  fontFamily: 'Inter',
  width: '70%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const TitleBorder = styled('span')(() => ({
  borderBottom: '2px solid #624BA2',
}));

export const GraphWrapper = styled('div')(({ theme }) => ({
  width: '50%',
  minHeight: '450px !important',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const LeftSideWrapper = styled(Box)(({ theme }) => ({
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}));

export const ConceptualRtingText = styled('p')(() => ({
  fontSize: '16px',
  fontWeight: 500,
  fontFamily: 'Inter',
  color: '#624BA2',
}));

export const SnackText = styled('span')(() => ({
  fontSize: '15px',
  fontWeight: 400,
  fontFamily: 'Inter',
  color: ' #11263C',
  fontStyle: 'normal',
}));

export const SnackPercentage = styled('span')(() => ({
  fontSize: '15px',
  fontWeight: 400,
  fontFamily: 'Inter',
  color: ' #624ba2',
  fontStyle: 'normal',
}));

export const QuestionText = styled('p')(() => ({
  backgroundColor: '#EFEDF6',
  borderTopRightRadius: '10px',
  padding: '15px',
  textAlign: 'center',
  color: '#624BA2',
  fontWeight: 600,
  fontFamily: 'Inter',
  fontSize: '14px',
}));

export const YourScoreText = styled('p')(() => ({
  color: '#11263C',
  fontWeight: 600,
  fontFamily: 'Inter',
  fontSize: '15px',
}));

// Chapter Analytics

export const TableWrapper = styled('div')(() => ({
  width: '100%',
  overflow: 'auto',
  marginTop: '20px',
}));

export const TableQuestions = styled('div')(() => ({
  backgroundColor: '#EFEDF6',
  borderTopRightRadius: '10px',
  padding: '15px',
  textAlign: 'center',
  color: '#624BA2',
  fontWeight: 600,
  fontFamily: 'AvenirNext',
}));

export const Table = styled('table')(() => ({
  width: '100%',
  overflow: 'auto',
  textAlign: 'center',
}));

export const TableHeading = styled('th')(() => ({
  borderBottom: '2px solid black',
}));

export const TableExpendWrapper = styled('div')(() => ({
  cursor: 'pointer',
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
}));

export const TableExpend = styled('span')(() => ({
  fontSize: '12px',
  fontFamily: 'AvenirNext',
}));

export const TableTotalQuestions = styled('td')(() => ({
  background: '#EFEDF6',
  color: '#624BA2',
  padding: '30px',
}));

export const TableRemaining = styled('td')(() => ({
  background: '#EFF0F3',
  color: '#11263C',
  padding: '30px',
}));

export const TableAttempted = styled('td')(() => ({
  background: '#EFEDF6',
  color: '#624BA2',
  padding: '30px',
}));

export const TableCorrect = styled('td')(() => ({
  background: '#EAF2F2',
  color: '#448A8D',
  borderBottom: '1px solid #448A8D',
  padding: '30px',
}));

export const TableIncorrect = styled('td')(() => ({
  background: '#F8EEEE',
  color: '#C20F0F',
  borderBottom: '1px solid #C20F0F',
  padding: '30px',
}));

export const TablePercentage = styled('td')(() => ({
  background: '#EFEDF6',
  color: '',
  padding: '30px',
}));

export const BreakdownWrapper = styled('div')(() => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '5px',
}));

// Snack Analytics Style

export const SnackHeaderWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: 'white',
  borderRadius: '10px',
  padding: '30px',
}));

export const SnackHeaderDetails = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  fontFamily: 'Inter',
  fontSize: '15px',
}));

export const SnackTableTitle = styled('td')(() => ({
  textAlign: 'start !important',
  display: 'flex',
  height: '100%',
  justifyContent: 'start',
  alignItems: 'start',
  padding: '40px 0',
  flexDirection: 'column',
  gap: '10px',
}));
