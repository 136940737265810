import React from "react";

const ClickSvg = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_631_3096)">
        <path
          d="M6.15535 6.69106C5.52456 7.17435 5.37523 7.99146 5.79156 8.67988L9.36045 14.6801C9.4571 14.8879 9.52672 15.0496 9.54689 15.207C9.54897 15.2253 9.55081 15.2428 9.55242 15.2593C9.37023 15.1862 9.15712 15.0958 8.9487 15.0072C8.17524 14.6789 7.81962 14.5359 7.57331 14.5098C7.0746 14.4372 6.42479 14.3992 5.79178 14.7285L5.7867 14.7312C5.44931 14.9108 5.11462 15.2955 4.99095 15.6462C4.85698 16.0259 4.86228 16.3618 5.00678 16.6444C5.14745 16.9194 5.40173 17.1133 5.76264 17.2206C6.58151 17.4756 7.41336 17.8728 8.30563 18.4348C8.45527 18.5308 8.62906 18.6276 8.79734 18.7213C9.01083 18.8401 9.23175 18.9631 9.38739 19.0755L9.39605 19.0816C10.0229 19.5173 10.6384 19.6696 11.1022 19.7531C11.1217 19.7568 13.0649 20.1274 14.6231 21.2767C15.3908 21.8394 16.4441 21.8061 17.1866 21.1939L20.3253 18.5798L20.353 18.5553C20.6036 18.3188 20.7483 18.0041 20.7779 17.6796C20.8032 17.4019 20.7442 17.1172 20.5946 16.8688L20.393 16.529C20.2899 16.3554 20.1958 16.174 20.1117 15.9867C19.952 15.631 19.8339 15.2716 19.7602 14.9181C19.4328 13.3463 18.679 11.7907 18.099 10.7551C17.9515 10.4422 17.6686 10.216 17.2981 10.116L17.2693 10.109C14.5237 9.51247 12.0604 10.1386 11.1037 10.4453L8.21016 6.97011C7.69649 6.35389 6.82283 6.2269 6.17793 6.67469L6.15535 6.69106Z"
          fill="#E015A2"
        />
        <path
          d="M4.51411 4.84773C3.06452 6.05507 2.86741 8.21685 4.07475 9.66644C4.28608 9.92018 4.66301 9.95455 4.9166 9.7432C5.17017 9.53201 5.20454 9.15508 4.99334 8.90151C4.20785 7.95823 4.33608 6.55185 5.27922 5.76619C6.22249 4.98069 7.62888 5.10893 8.41454 6.05206C8.62572 6.30579 9.00265 6.34016 9.25624 6.12881C9.38302 6.02321 9.45516 5.87623 9.46906 5.72379C9.48294 5.5715 9.43858 5.4139 9.33298 5.28711C8.12551 3.83736 5.96387 3.64026 4.51411 4.84773Z"
          fill="#E015A2"
        />
        <path
          d="M3.25284 3.19188C2.116 4.13859 1.41603 5.47144 1.28168 6.94491C1.14734 8.41823 1.59472 9.8557 2.54158 10.9925C2.75293 11.2461 3.1297 11.2805 3.38343 11.0693C3.63702 10.858 3.67137 10.4812 3.46019 10.2275C2.71778 9.33591 2.36677 8.20871 2.4721 7.05345C2.57745 5.89804 3.12654 4.85291 4.01794 4.11034C5.8582 2.57762 8.60219 2.82781 10.1349 4.66807C10.3462 4.92182 10.723 4.95617 10.9768 4.74483C11.1035 4.63924 11.1755 4.49224 11.1894 4.33996C11.2033 4.18767 11.1591 4.02993 11.0535 3.90314C9.09896 1.55641 5.59957 1.23734 3.25284 3.19188Z"
          fill="#E015A2"
        />
      </g>
      <defs>
        <clipPath id="clip0_631_3096">
          <rect
            width="20.3902"
            height="20.3902"
            fill="white"
            transform="translate(0 21) rotate(-84.7902)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ClickSvg;
