import Next from './Next.svg';
import Info from './Info.svg';
import Wrong from './Wrong.svg';
import Paper from './Paper.svg';
import Cross from './Cross.svg';
import Octopus from './Octopus.svg';
import Header from './Header.svg';
import Expend from './Expend.svg';
import Correct from './Correct.svg';
import Previous from './Previous.svg';
import QuoteSvg from './QuoteSvg.svg';
import Submitted from './Submitted.svg';
import CloseIcon from './CloseIcon.svg';
import CalenderSvg from './CalenderSvg';
import ExpendClose from './ExpendClose.svg';
import QuestionSVG from './QuestionSVG.svg';
import ConfirmModel from './ConfirmModel.svg';
import DeleteModalIcon from './DeleteModalIcon.svg';

const GRAPHICS = {
  Next,
  Info,
  Wrong,
  Cross,
  Paper,
  Octopus,
  Header,
  Expend,
  Correct,
  Previous,
  QuoteSvg,
  Submitted,
  CloseIcon,
  ExpendClose,
  CalenderSvg,
  QuestionSVG,
  ConfirmModel,
  DeleteModalIcon,
};

export default GRAPHICS;
