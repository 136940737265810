import React from 'react';

const NotesMark = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        width: '29px',
      }}
    >
      <svg
        width="21"
        height="19"
        viewBox="0 0 21 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.38888 13.4507C7.62365 13.1828 8.0048 12.4436 8.21962 12.2116C8.95338 11.4207 9.6196 10.5818 10.289 9.73657C12.2845 7.21489 14.4413 4.82011 16.3829 2.26218C16.7341 1.79899 17.2245 1.19848 17.7675 0.86673C18.1024 0.661878 18.4586 0.553106 18.8194 0.583019C19.182 0.612931 19.5599 0.778354 19.9261 1.17401C20.4179 1.70518 20.3395 2.4489 20.1314 3.07343C19.7013 4.36327 18.8629 5.52123 18.0825 6.61711C15.903 9.6772 13.6555 12.6884 11.238 15.564C10.7594 16.1337 10.2346 16.8593 9.61643 17.4253C8.91757 18.0648 8.10179 18.5013 7.12557 18.4147C6.31839 18.3431 5.67256 17.8659 5.06571 17.3732C3.57147 16.16 2.07088 14.2497 1.21566 12.5243C0.99404 12.0774 0.659115 11.1402 0.710328 10.4014C0.755196 9.75289 1.05522 9.23759 1.76586 9.08485C2.1656 8.99874 2.57077 9.09392 2.95374 9.29605C3.41103 9.53716 3.83614 9.93644 4.16064 10.2147C4.83774 10.7971 5.36619 11.4701 5.92274 12.106C6.35782 12.6036 6.81013 13.079 7.38888 13.4507ZM7.81898 13.7344C7.89648 13.8268 7.93591 13.9487 7.92232 14.0734C7.93999 13.908 7.88153 13.8028 7.81898 13.7344Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default NotesMark;
