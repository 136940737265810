import { styled } from "@mui/system";
import IconButton from "@mui/material/IconButton";

export const StyledBtn = styled(IconButton)(({ theme }) => ({
  padding: 0,
  margin: 0,
  width: '25px',
  height: '25px',
}));

export const MyLibraryThreeDotBtn = styled('div')(({ theme, show, isMenuOpen, style }) => ({
  ...style,
}));
