import React from "react";

const Edit = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 18"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.44994 4.32847L12.3961 8.32522C12.5155 8.4208 12.5358 8.5962 12.4427 8.71839L6.57885 16.3591C6.21023 16.8311 5.66701 17.0982 5.08499 17.108L1.88388 17.1474C1.71315 17.1494 1.56377 17.0312 1.52497 16.8617L0.797442 13.6986C0.671337 13.1172 0.797442 12.5161 1.16605 12.053L7.05901 4.37577C7.15408 4.25259 7.32965 4.23092 7.44994 4.32847"
        fill={color}
      />
      <path
        d="M14.6107 5.94332L13.6571 7.13367C13.5611 7.25488 13.3884 7.27458 13.2691 7.17801C12.1099 6.23992 9.14162 3.83261 8.31806 3.1655C8.19778 3.06696 8.18129 2.89156 8.27829 2.76937L9.19788 1.6273C10.0321 0.553225 11.4872 0.454686 12.6609 1.39081L14.0093 2.46489C14.5622 2.89846 14.9308 3.46998 15.0569 4.07107C15.2024 4.73227 15.0472 5.38165 14.6107 5.94332"
        fill={color}
      />
    </svg>
  );
};

export default Edit;

Edit.defaultProps = {
  color: "white",
};
