import { Box, styled } from '@mui/material';
import TypographyCompo from './../Typography/TypographyCompo';
import Palette from '../../../config/palette';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

export const Container = styled('div')(({ theme, isMyLibrary }) => ({
  marginTop: isMyLibrary ? '20px' : '120px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0px',
    marginRight: '0px',
    marginTop: '20px',
  },
}));

export const StackWrapper = styled('div')(({ theme }) => ({
  background: '#F8F7FC',
  padding: '0px 40px',
  [theme.breakpoints.down('sm')]: {
    padding: '0px 0px',
  },
}));
export const ColDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  [theme.breakpoints.up('md')]: {
    padding: '0 20px',
  },
}));

export const FlashCardInfoTypo = styled(TypographyCompo)(
  ({ theme, isValue, color, active }) => ({
    color: color ? color : active ? '#624BA2' : 'rgba(17, 38, 60, 0.70)',
    textAlign: 'center',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Inter',
    fontSize: isValue ? '18px' : '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '180%',
    [theme.breakpoints.down('sm')]: {
      fontSize: isValue ? '22px' : '12px',
    },
  })
);

export const DividerWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

export const FlipCard = styled('div')(({ theme, flipVertical }) => ({
  perspective: '1000px',
  width: '100%',
  minHeight: 'calc(100vh - 150px)',
  overflow: 'hidden',

  '.flipper': {
    transition: '0.5s ease-in-out',
    transformStyle: 'preserve-3d',
    position: 'relative',
    width: '100%',
    height: '100%',
    transform: flipVertical ? 'rotateX(180deg)' : 'rotateX(0deg)',
    transformOrigin: '100% calc((100vh - 150px) / 2)',
  },

  '.front, .back': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    minHeight: 'calc(100vh - 150px)',
    backfaceVisibility: 'hidden',
  },

  '.front': {
    zIndex: 2,
    transform: 'rotateX(0deg)',
  },

  '.back': {
    transform: 'rotateX(180deg)',
    zIndex: 1,
  },

  '.fade-right': {
    opacity: 0,
    transform: 'translateX(-30px)',
    animation: 'fadeRight 0.25s ease forwards',
  },

  '.fade-left': {
    opacity: 0,
    transform: 'translateX(30px)',
    animation: 'fadeLeft 0.25s ease forwards',
  },

  '@keyframes fadeRight': {
    '0%': {
      opacity: 0,
      transform: 'translateX(30px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },

  '@keyframes fadeLeft': {
    '0%': {
      opacity: 0,
      transform: 'translateX(-30px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
}));

export const CardDivWrapper = styled('div')(({ theme }) => ({
  background: 'white',
  border: '1px solid #624BA2',
  height: 'calc(100vh - 270px)',
  minHeight: 'calc(100vh - 250px)',
  overflow: 'auto',
  paddingTop: '0px',
  margin: '20px',
  boxShadow: '0px 4px 13px 0px #0000000F',
  borderRadius: '15px',
  padding: `${theme.spacing(4)} 0px`,
  paddingRight: '20px',
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(1)} 0px`,
    paddingTop: '30px',
    height: '250px',
  },
}));

export const FlashIconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
  marginTop: '10px',
}));

export const SetperDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingRight: '10px',
}));

export const Typography = styled(TypographyCompo)(({ theme }) => ({
  textAlign: 'center',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}));

export const ButtonTerm = styled(Typography)(({ theme }) => ({
  display: 'flex',
  textTransform: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  background: Palette.colors.selectedColor,
  padding: '0px 10px',
  color: Palette.colors.unselected,
  borderRadius: '50px',
  '&:hover': {
    background: Palette.colors.selectedColor,
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));
export const StepperWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '115px',
  height: '33px',
  padding: 'var(--Spacing-space-3, 4px) 13px',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  borderRadius: '0px 8px 8px 0px',
  background: '#624BA2',
  color: '#ffff',
  fontSize: '12px',
  fontFamily: 'Satoshi',
  [theme.breakpoints.down('sm')]: {
    width: '100px',
    padding: '0px 13px',
  },
}));

export const ButtonTermMobile = styled(Typography)(({ theme }) => ({
  color: 'rgba(98, 75, 162, 1)',
  display: 'inline-flex',
  textAlign: 'center',
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '180%',
  background: 'none',
  borderRadius: '0',
  padding: '0',
  textTransform: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  border: 'none',
  [theme.breakpoints.down('sm')]: {},
}));
export const ButtonDefinitionMobile = styled(Button)(({ theme }) => ({
  display: 'none',
  color: 'var(--Pink-New-E01512, #E015A2)',
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  textTransform: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
  },
}));

export const ButtonDefinition = styled(Button)(({ theme }) => ({
  display: 'flex',
  textTransform: 'none',
  color: Palette.colors.secondaryModified,
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));
export const RightLeftButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
  width: '75%',
  [theme.breakpoints.down('sm')]: {
    gap: '5px',
  },
}));
export const StyleButton = styled(Button)(({ background, theme }) => ({
  background: background,
  borderRadius: '9px',
  marginRight: '10px',
  marginLeft: '10px',
  height: '40px',
  width: '94px',
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    width: '60px',
  },
}));
export const ButtonTextWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));
export const ButtonDefinitionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '5px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0%',
    justifyContent: 'center',
    marginBottom: '20px',
    alignItems: 'center',
  },
}));
export const IconButtonWrapper = styled(IconButton)(({ theme, _fullview }) => ({
  display: 'flex',
  background: _fullview
    ? Palette.colors.selectedColor
    : Palette.colors.primaryModified,

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));
export const IconButtonWrapperMobile = styled(IconButton)(
  ({ theme, _fullview }) => ({
    background: _fullview
      ? 'rgba(229, 99, 189, 0.8)'
      : 'rgba(229, 99, 189, 0.8)',
    height: '35px',
    width: '35px',
    filter: 'drop-shadow(20px 20px 50px rgba(255, 255, 255, 1))',
    marginTop: '-5px',
    backgroundColor: '#E015A2 !important',

    '&:hover': {
      backgroundColor: '#624BA2 !important',
    },
  })
);

export const DiduKnowBtn = styled(Typography)(({ theme }) => ({
  color: 'var(--black, #11263C)',
  textAlign: 'center',
  fontFamily: 'Satoshi',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  marginBottom: '10px',
}));

export const Wrapper = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '10px',
}));
