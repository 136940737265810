import React from "react";

const DashboardSvg = ({ isMobile }) => {
  return (
    <>
      {isMobile ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.11667 0.666504H4.93333C6.10833 0.666504 7.05 1.62484 7.05 2.80067V5.6415C7.05 6.82484 6.10833 7.77484 4.93333 7.77484H2.11667C0.95 7.77484 0 6.82484 0 5.6415V2.80067C0 1.62484 0.95 0.666504 2.11667 0.666504ZM2.11667 10.2246H4.93333C6.10833 10.2246 7.05 11.1754 7.05 12.3588V15.1996C7.05 16.3746 6.10833 17.3329 4.93333 17.3329H2.11667C0.95 17.3329 0 16.3746 0 15.1996V12.3588C0 11.1754 0.95 10.2246 2.11667 10.2246ZM14.5505 0.666504H11.7339C10.5589 0.666504 9.61719 1.62484 9.61719 2.80067V5.6415C9.61719 6.82484 10.5589 7.77484 11.7339 7.77484H14.5505C15.7172 7.77484 16.6672 6.82484 16.6672 5.6415V2.80067C16.6672 1.62484 15.7172 0.666504 14.5505 0.666504ZM11.7339 10.2246H14.5505C15.7172 10.2246 16.6672 11.1754 16.6672 12.3588V15.1996C16.6672 16.3746 15.7172 17.3329 14.5505 17.3329H11.7339C10.5589 17.3329 9.61719 16.3746 9.61719 15.1996V12.3588C9.61719 11.1754 10.5589 10.2246 11.7339 10.2246Z"
            fill="#ADB4C5"
          />
        </svg>
      ) : (
        <div style={{ marginTop: "0.4em", marginLeft: "0.2em" }}>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.78854 0.666016H5.60521C6.78021 0.666016 7.72187 1.62435 7.72187 2.80018V5.64102C7.72187 6.82435 6.78021 7.77435 5.60521 7.77435H2.78854C1.62187 7.77435 0.671875 6.82435 0.671875 5.64102V2.80018C0.671875 1.62435 1.62187 0.666016 2.78854 0.666016ZM2.78854 10.2241H5.60521C6.78021 10.2241 7.72187 11.1749 7.72187 12.3583V15.1991C7.72187 16.3741 6.78021 17.3324 5.60521 17.3324H2.78854C1.62187 17.3324 0.671875 16.3741 0.671875 15.1991V12.3583C0.671875 11.1749 1.62187 10.2241 2.78854 10.2241ZM15.222 0.666016H12.4053C11.2303 0.666016 10.2886 1.62435 10.2886 2.80018V5.64102C10.2886 6.82435 11.2303 7.77435 12.4053 7.77435H15.222C16.3886 7.77435 17.3386 6.82435 17.3386 5.64102V2.80018C17.3386 1.62435 16.3886 0.666016 15.222 0.666016ZM12.4053 10.2241H15.222C16.3886 10.2241 17.3386 11.1749 17.3386 12.3583V15.1991C17.3386 16.3741 16.3886 17.3324 15.222 17.3324H12.4053C11.2303 17.3324 10.2886 16.3741 10.2886 15.1991V12.3583C10.2886 11.1749 11.2303 10.2241 12.4053 10.2241Z"
              fill="#251038"
              fillOpacity="0.6"
            />
          </svg>
        </div>
      )}
    </>
  );
};

export default DashboardSvg;
