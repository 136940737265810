import React from 'react';
import Percentage from './Percentage';
import { YourScoreText } from '../Analytics.style';
import capitalize from '../../../../../../../../helpers/capitalize';
import {
  SnackHeaderDetails,
  SnackHeaderWrapper,
  SnackTableTitle,
  Table,
  TableAttempted,
  TableCorrect,
  TableHeading,
  TableIncorrect,
  TablePercentage,
  TableQuestions,
  TableRemaining,
  TableTotalQuestions,
  TableWrapper,
} from '../Analytics.style';

const SnackAnalytics = ({ names, analyticsData, newSelection }) => {
  const getPercentageColor = (percentage) => {
    if (percentage > 90) {
      return '#12AB33';
    } else if (percentage > 80) {
      return '#128F97';
    } else if (percentage > 70) {
      return '#35C2D5';
    } else if (percentage > 60) {
      return '#AD9C09';
    } else {
      return '#D55555';
    }
  };

  const getBorderColor = (percentage) => {
    if (percentage > 90) {
      return 'rgba(18, 171, 51, 0.5)';
    } else if (percentage > 80) {
      return 'rgba(18, 143, 151, 0.5)';
    } else if (percentage > 70) {
      return 'rgba(53, 194, 213, 0.5)';
    } else if (percentage > 60) {
      return 'rgba(173, 156, 9, 0.5)';
    } else {
      return 'rgba(213, 85, 85, 0.5)';
    }
  };

  const percentage =
    (analyticsData?.correct / analyticsData?.attemptedQuestions) * 100 || 0;

  const color = getPercentageColor(percentage);

  const borderColor = getBorderColor(percentage);

  return (
    <div>
      <SnackHeaderWrapper>
        <SnackHeaderDetails>
          <span style={{ fontWeight: 600 }}>
            Your score on {capitalize(newSelection?.label) || ''}
          </span>

          <YourScoreText>
            1. {capitalize(newSelection?.label) || ''}{' '}
            <span
              style={{
                fontWeight: 600,
                color: color,
              }}
            >
              {' '}
              (
              {Math.floor(
                (analyticsData?.correct / analyticsData?.attemptedQuestions) *
                  100
              ) || 0}
              %)
            </span>{' '}
          </YourScoreText>
        </SnackHeaderDetails>

        <div>
          <div
            style={{
              border: `10px solid ${borderColor}`,
              borderRadius: '100%',
            }}
          >
            <div
              style={{
                height: '150px',
                width: '150px',
                borderRadius: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                fontFamily: 'AvenirNext',
                fontWeight: 600,
                background: color,
              }}
            >
              {Math.floor(
                (analyticsData?.correct / analyticsData?.attemptedQuestions) *
                  100
              ) || 0}
              %
            </div>
          </div>
        </div>
      </SnackHeaderWrapper>

      <TableWrapper>
        <TableQuestions>Questions</TableQuestions>

        <Table>
          <tr>
            <TableHeading>Topic Name</TableHeading>

            <TableHeading>Total</TableHeading>

            <TableHeading>Remaining</TableHeading>

            <TableHeading>Attempted</TableHeading>

            <TableHeading>Correct</TableHeading>

            <TableHeading>Incorrect</TableHeading>

            <TableHeading>Percentage</TableHeading>
          </tr>

          {/* When we click on snack level this show the particular snack analytics */}

          <tr>
            <SnackTableTitle>{names}</SnackTableTitle>

            <TableTotalQuestions>
              {analyticsData?.totalQuestions}
            </TableTotalQuestions>

            <TableRemaining>{analyticsData?.remaining}</TableRemaining>

            <TableAttempted>{analyticsData?.attemptedQuestions}</TableAttempted>

            <TableCorrect>{analyticsData?.correct}</TableCorrect>

            <TableIncorrect>{analyticsData?.incorrect}</TableIncorrect>

            <TablePercentage>
              <Percentage
                value={Math.floor(
                  (analyticsData?.correct * 100) /
                    analyticsData?.attemptedQuestions
                )}
              />
            </TablePercentage>
          </tr>
        </Table>
      </TableWrapper>
    </div>
  );
};

export default SnackAnalytics;
