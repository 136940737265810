import React from "react";
import Accordian from "./../../../../../../UI/Accordian/PrimaryAccordion/PrimaryAccordion";
import { useSelector, useDispatch } from "react-redux";
import { subjectActions } from "./../../../../../../../redux/reducers/subject";
import PrimarySyllabus from "./PrimarySyllabus";
import api from "../../../../../../../Services";
import Loader from "./../../../../../../UI/Loader/Loader";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../../../Errors/ErrorBoundary";
import { SyllabusContext } from "../../../../../../../contexts";
import LogoLoader from "../../../../../../UI/Loader/logoLoader";
import useEstimatedCompletionTime from "../../../../hooks/calulateEstimatedCompletionTime";
export const generateCompletedLabel = (value, total) => `${value}/${total}`;

const SyllabusAccordions = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const courseHasUnits = useSelector((state) => state.course?.haveUnits);
  const [loadingSyllabus, setLoadingSyllabus] = React.useState(false);
  const allExpanded = useSelector((state) => state.subject.expandAccordian);
  const [accordionController, setAccordionController] = React.useState([]);
  const { id: courseId } = useParams();
  const [objectives, setObjectives] = React.useState(null);
  const [fetchStartTime, setFetchStartTime] = React.useState(null);
  const [fetchEndTime, setFetchEndTime] = React.useState(null);
  const estimatedCompletionTime = useEstimatedCompletionTime(
    fetchStartTime,
    3000
  );
  const learningObjectivesCompletedStatus = useSelector(
    (state) => state.learningObjectivesCompleted.learningObjectivesCompleted
  );

  const getPercentage = (data) => {
    let completed = 0;
    let total = 0;
    const chapters = data.chapters;
    chapters?.map((chapter, index) => {
      const completedObjectives = chapter?.learningObjectives?.filter(
        (el) => el.UserLearningObjectives
      )?.length;

      completed = completed + completedObjectives;
      total = total + chapter.learningObjectives.length;
    });

    return Math.floor((completed / total) * 100);
  };
  const generateAccordionData = (resp) => {
    if (!resp?.error) {
      const { data, isUnitsAvailable } = resp;

      if (isUnitsAvailable) {
        let withUnitHeirarchy = data.map((unit, index) => {
          const tempObj = {
            heading: unit.title,
            id: unit.id,
            contentType: `${
              unit.chapters.length > 1 ? `Chapters ` : `Chapter`
            }`,
            description: `${
              unit.chapters.length > 1
                ? `${unit.chapters.length} `
                : `${unit.chapters.length} Chapter`
            }`,
            percentage: getPercentage(unit),
            content: (
              <PrimarySyllabus
                key={index}
                chapters={unit.chapters}
                hasUnits={isUnitsAvailable}
              />
            ),
          };
          return tempObj;
        });
        setAccordionController(withUnitHeirarchy);
        setLoadingSyllabus(false);
      } else {
        const chapters = data[0].chapters;
        const withChapterHierarchy = chapters.map((chapter, index) => {
          const completedObjectives = chapter.learningObjectives.filter(
            (el) => el.UserLearningObjectives
          )?.length;
          let tempObj = {
            heading: chapter.title,
            id: chapter.id,
            description: generateCompletedLabel(
              completedObjectives,
              chapter.learningObjectives.length
            ),
            percentage: Math.floor(
              (completedObjectives / chapter.learningObjectives.length) * 100
            ),
            content: (
              <>
                <PrimarySyllabus
                  key={index}
                  hasUnits={isUnitsAvailable}
                  chapters={chapter.learningObjectives}
                />
              </>
            ),
          };

          return tempObj;
        });
        setAccordionController(withChapterHierarchy);
        setLoadingSyllabus(false);
      }
    }
  };

  const fetchHierarchyWithObjectives = async () => {
    setLoadingSyllabus(true);
    let token = localStorage.getItem("auth");
    let response = await api.getHierarchyWithObjectives(token, courseId);
    setObjectives(response);
  };

  React.useEffect(() => {
    if (objectives) {
      generateAccordionData(objectives);
    }
  }, [objectives]);
  React.useEffect(() => {
    fetchHierarchyWithObjectives();
  }, []);

  const updateCountForLearningObjCompletion = (
    controllerArr,
    referenceArray,
    hasUnits
  ) => {
    for (let i in controllerArr) {
      const { id } = controllerArr[i];
      if (!hasUnits) {
        const filteredRefArr = referenceArray.filter(
          (eachObj) => eachObj.chapterId === id
        );
        const completedVals = filteredRefArr.filter(
          (each) => each.checked
        )?.length;
        const toChange = controllerArr[i].description.split("/");
        toChange[0] = completedVals;
        const joined = toChange.join("/");
        controllerArr[i].description = joined;
      }
    }

    return controllerArr;
  };

  React.useEffect(() => {
    let temp = [...accordionController];
    const controllerUpdated = updateCountForLearningObjCompletion(
      temp,
      learningObjectivesCompletedStatus,
      courseHasUnits
    );
    controllerUpdated?.sort((a, b) => {
      return a.id - b.id;
    });
    setAccordionController(controllerUpdated);
  }, [learningObjectivesCompletedStatus]);

  accordionController?.sort((a, b) => {
    return a.id - b.id;
  });

  return (
    <SyllabusContext.Provider
      value={{
        heirarchy: objectives,
        setHierarchy: setObjectives,
        generateAccordionData,
      }}
    >
      <ErrorBoundary>
        <div id="syllabus-accordions" ref={ref}>
          {loadingSyllabus ? (
            <LogoLoader />
          ) : (
            accordionController.length > 0 && (
              <Accordian
                expandAll={allExpanded}
                controller={accordionController}
                toggleExpandAllOff={(e) =>
                  dispatch(subjectActions.stopExpandAccordion())
                }
              />
            )
          )}
        </div>
      </ErrorBoundary>
    </SyllabusContext.Provider>
  );
});

export default SyllabusAccordions;
