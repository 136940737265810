import React from "react";
import TextComp from "../../Containers/Subject/Tabs/LearningResources/Tabs/Notes/TextComp";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import { Input } from "./HighlightAndCommentMenuWrapper.style.js";
import { useSelector } from "react-redux";
import HighlightTextMenu from "./HighlightTextMenu";
import { Clear } from "@mui/icons-material";
import { Divider } from "@mui/material";

const HighlightAndCommentMenuWrapper = ({
  handleChangeColor,
  handleComment,
  setComment,
  comment,
  closeBox,
  isLearningObjective,
}) => {
  const isHighlightingActive = useSelector((state) => state.learningResources);

  return (
    <>
      <TextComp>
        {!isHighlightingActive.isHighlighting ? (
          <>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "space-between",
                color: "white",
              }}
            >
              <Input
                placeholder="Add Comments..."
                rows={4}
                onChange={(e) => setComment(e.target.value)}
                value={comment}
              ></Input>

              <Divider
                orientation="horizontal"
                sx={{
                  borderColor: "#AAA5B1",
                  marginTop: "5px",
                  opacity: "0.3",
                }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "end",
                  height: "32px",
                  padding: "0px",
                }}
              >
                <div>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={closeBox}
                  >
                    <Clear fontSize="small" sx={{ color: "white" }} />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={comment?.length > 0 ? handleComment : undefined}
                  >
                    <SendIcon fontSize="small" sx={{ color: "white" }} />
                  </IconButton>
                </div>
              </div>
            </div>
          </>
        ) : (
          <HighlightTextMenu
            handleChangeColor={handleChangeColor}
            isLearningObjective={isLearningObjective}
          />
        )}
      </TextComp>
    </>
  );
};

export default HighlightAndCommentMenuWrapper;
