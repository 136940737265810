import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/material";
import api from "../../../../../../../Services";
import BreadCrumb from "../../../../BreadCrumb";
import React, { useEffect, useState } from "react";
import Alert from "../../../../../../UI/Alert/Alert";
import GridComp from "../../../../../../UI/Grid/Grid";
import CardComp from "../../../../../../UI/Card/Card";
import LeftSide from "../../../../LeftSideBar/LeftSide";
import Loader from "../../../../../../UI/Loader/Loader";
import useUserResponseAwait from "../../../../hooks/useUserResponseAwait";
import SimulationCard from "../../../../../../UI/Simulation/SimulationCard";
import FlashCardHeader from "../../../../Headers/LearningResources/FlashCard/FlashCard";
import MobileBottomBar from "../../../../../../mobileBottomBar/MobileBottomBar";
import MobileCourseIndicator from "../../../../../../mobileBottomBar/MobileCourseIndicator";
import MobileDetector from "../../../../../../../helpers/mobileDetector";
import CourseInfo from "../../../../../../coursoInfo/courseInfo";
import Header from "../../../../Headers/LearningResources/Notes/Notes";

const Simulations = () => {
  const params = useParams();
  const isMobile = MobileDetector();
  const { id: courseId } = params;

  const { awaitingUserResponse, toggleLoading } = useUserResponseAwait();

  const [activeTag, setActiveTag] = useState({ title: "all" });
  const [allTags, setAllTags] = useState([]);
  const [selection, setSelection] = useState({});
  const [newSelection, setNewSelection] = useState(null);
  const [getMenuStats, setGetMenuStats] = useState(false);
  const [hierarchyData, setHeirarchyData] = React.useState(null);
  const [simulations, setSimulations] = useState([]);
  const [hasSimulation, setHasSimulation] = useState(true);
  const [showSimulation, setShowSimulation] = useState(false);
  const [showSim, setShowSim] = useState(true);
  const [simulationSrc, setSimulationSrc] = useState("");
  const [openMobileBar, setOpenMobileBar] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [activeItem, setActiveItem] = useState("");
  const [clickedStack, setClickedStack] = useState("");
  const _1000px = useMediaQuery("(min-width: 1000px)");
  const _750px = useMediaQuery("(max-width: 750px)");
  const _420px = useMediaQuery("(max-width: 450px)");
  const [structuredData,setStructuredData] = useState([])

  const left = _1000px ? 3 : _750px ? 12 : 4;
  const right = _1000px ? 9 : _750px ? 12 : 8;

  const handleOpenMobileBar = () => {
    setOpenMobileBar(!openMobileBar);
  };

  const handleExpansion = (item,structure) => {
    switch(item.type) {
      case 'unit':
          const updatedData = structure.map((unit) => {
            if (unit.id === item.id) {
              return { ...unit, isExpanded: !unit.isExpanded , nested : unit.childrenElements   };
            }
            return {...unit,isExpanded:false, nested : unit.childrenElements};
          });
          return updatedData;
        break;

      case 'chapter':  

      if(item.unitId){
          let updatedData = structure.map((unit) => {
            if (unit.id === item.unitId) {
              const modifiedChildren = unit.childrenElements.map((element)=>{
                   if(element.id === item.id){
                    return { ...element, isExpanded: !element.isExpanded, nested : element.childrenElements };
                   }
                   return {...element, isExpanded :false,  nested : element.childrenElements}  
              })
              return {...unit,childrenElements:modifiedChildren ,  nested :modifiedChildren}
            }

            return unit;
          });
          return updatedData;
      }
      else{
          let updatedData = structure.map((chapter) => {
            if (chapter.id === item.id) {
              return { ...chapter, isExpanded: !chapter.isExpanded ,nested : chapter.childrenElements };
            }
            return {...chapter,isExpanded:false,nested : chapter.childrenElements};
          });
          return updatedData;
      }
        break;

        case "snack":

            const updatedDataSnack = structure.map((unit) => {  
              if (unit.id === item.unitId) {
                let modifiedUnitsChildren= unit.childrenElements.map((element)=>{
                     if(element.id === item.chapterId){
                     let modifiedSnacksChildren =element.childrenElements.map((snackElements)=>{
                          if(snackElements.id ===item.id){
                            return { ...snackElements, isExpanded: !snackElements.isExpanded, nested : snackElements.childrenElements };
                          }
                          return snackElements;   
                      })
                      return {...element,childrenElements:modifiedSnacksChildren , nested : modifiedSnacksChildren};   

                    }
                     return element  
                })
                return {...unit,childrenElements:modifiedUnitsChildren, nested : modifiedUnitsChildren};   
              }

              return unit;
            });
            return updatedDataSnack;
      default:
        console.log("Unknown item type");
    }

  };


  function handleSelection(e) {
    const simulationscards = handleExpansion(e,structuredData)
    const simulationscardGlobal = {
      data: simulationscards, 
      tab:"simulationscards",
      selection : e
    };
    localStorage.setItem('simulationscardsGlobalState', JSON.stringify(simulationscardGlobal));
    handleSelectedData(e)
  }

  function handleSelectedData(e) {
    if (e.type === "unit") {
      setSelectedData({
        ...e,
        unitname: e.name,
        type: "unit",
        active: "unit",
      });
    } else if (e.type === "chapter") {
      setSelectedData((prevData) => ({
        ...prevData,
        ...e,
        chaptername: e.name,
        type: "chapter",
        active: "chapter",
      }));
    } else {
      setSelectedData((prevData) => ({
        ...prevData,
        ...e,
        snackname: e.name,
        type: "snack",
        active: "snack",
      }));
    }
  }
  const getSimulationsFromApi = async ({ id, type, token }) => {
    let temp = await api.getSimulations(id, type, token);
    return temp;
  };

  const handleSimulationCall = (payload, criteria) => {
    const { id, type } = payload;

    const token = localStorage.getItem("auth");

    toggleLoading(true);

    getSimulationsFromApi({ id, type, token }).then((el) => {
      if (!el.error) {
        const { data } = el;

        setSimulations(data);

        let temp = [...data];

        if (criteria && criteria.title !== "all") {
          temp = data?.filter((item) => {
            return item?.conceptTags?.some((each) => {
              return each?.id === criteria.id;
            });
          });
        }

        setSimulations(temp);
        setShowSim(false);
        setSelection(payload);
        setShowSimulation(false);

        toggleLoading(false);
      } else {
        console.log("error >>> ", el.error);
      }
    });
  };

  const handleTagSelection = (_, item) => {
    if (newSelection) {
      handleSimulationCall(newSelection, item);
      setActiveTag(item);
    }
  };

  const listItemClickHandler = (selectedListItem, isStorage=false) => {
   if(isStorage){
    setActiveItem(selectedListItem.label);
    setNewSelection(selectedListItem);
    setGetMenuStats(false);
    handleSelectedData(selectedListItem);    
    if (selectedListItem.isExpanded) {
      handleSimulationCall(selectedListItem, activeTag);
      setHasSimulation(false);
    }
   }
   else{
    setActiveItem(selectedListItem.label);
    handleSelection(selectedListItem);
    setNewSelection(selectedListItem);
    setGetMenuStats(false);
    handleSelectedData(selectedListItem);    

    if (selectedListItem.isExpanded) {
      handleSimulationCall(selectedListItem, activeTag);
      setHasSimulation(false);
    }
   }
  };
  const fetchNotesHeirarchy = async () => {
    let token = localStorage.getItem("auth");
    await api
      .getHierarchyForSimlations(token, courseId)
      .then((resp) => setHeirarchyData(resp))
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchNotesHeirarchy();
  }, []);

  useEffect(()=>{
    if(selectedData?.layer === 1 && selectedData?.isExpanded === false) {
      setHasSimulation(true)
    }
  },[selectedData])

  return (
    <>
      <Box
        sx={{
          padding: { xs: "20px 30px", sm: "0px 0px" },
          marginBottom: { xs: "70px", sm: "0px" },
        }}
      >
        <BreadCrumb
          showSimulation={showSimulation}
          setShowSimulation={setShowSimulation}
          handleSelection={selectedData}
        />
        <Header
          activeTag={activeTag}
          allTags={allTags}
          setAllTags={setAllTags}
          baseView={true}
          left={left}
          right={right}
          handleSelection={handleTagSelection}
          simulations={simulations}
          isSimulation={true}
          tab="Simulations"
        />
        <GridComp
          container
          spacing={{ xs: 0, sm: 4 }}
          style={{ marginTop: "10px" }}
        >
          {/* {isMobile && showSimulation ? (
            ""
          ) : (
            <GridComp item container xs={12}>
              <FlashCardHeader
                activeTag={activeTag}
                allTags={allTags}
                setAllTags={setAllTags}
                baseView={true}
                left={left}
                right={right}
                handleSelection={handleTagSelection}
                simulations={simulations}
                isSimulation={true}
              />
            </GridComp>
          )} */}

          <GridComp
            item
            xs={left}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <CardComp nopadding={true}>
              <LeftSide
                selectedFromList={selection}
                onListClick={(e, i) => {
                  listItemClickHandler(e, i);
                }}
                getMenuStats={getMenuStats}
                hierarchyData={hierarchyData}
                isLibrary={false}
                isFlashcard={false}
                isSimulation={true}
                selectedData={selectedData}
                setStructuredData={setStructuredData}
                setSelectedData={setSelectedData}
                tab={"simulationscards"}
              />
            </CardComp>
          </GridComp>
          {isMobile && (
            <GridComp item xs={right}>
              {showSim && (
                <MobileCourseIndicator
                  courseId={courseId}
                  tab={"Simulations"}
                  welcomeDetails="Please select a unit or chapter to view Simulations."
                />
              )}
            </GridComp>
          )}

          <GridComp item xs={right}>
            {hasSimulation ? (
              isMobile ? (
                <></>
              ) : (
                <CourseInfo courseId={courseId} tab="Simulations" />
              )
            ) : (
              <>
                {awaitingUserResponse?.loading ? (
                  <Loader />
                ) : (
                  <>
                    {simulations?.length === 0 ? (
                      <>
                        {newSelection.type === "unit" && (
                          <Alert
                            title="No Simulation found for this unit"
                            severity="warning"
                          />
                        )}
                        {newSelection.type === "chapter" && (
                          <Alert
                            title="No Simulation found for this chapter"
                            severity="warning"
                          />
                        )}
                        {newSelection.type === "snack" && (
                          <Alert
                            title="No Simulation found for this snack"
                            severity="warning"
                          />
                        )}
                      </>
                    ) : showSimulation ? (
                      <Box
                        sx={{
                          height: "90vh",
                          marginBottom: { xs: "30px", sm: "0px" },
                        }}
                      >
                        <iframe
                          style={{ marginTop: isMobile ? "20px" : "0px" }}
                          src={simulationSrc}
                          width="100%"
                          height="100%"
                          allowfullscreen
                          title="Simulation"
                        ></iframe>
                      </Box>
                    ) : (
                      <>
                        <GridComp container spacing={4}>
                          {simulations?.map((item) => {
                            return (
                              <Grid item xs={12} md={6} lg={4}>
                                <SimulationCard
                                  title={item?.title}
                                  imgSrc={item?.imgURL}
                                  conceptTags={item?.conceptTags}
                                  src={item?.src}
                                  newSelection={newSelection}
                                  showSimulation={showSimulation}
                                  setShowSimulation={setShowSimulation}
                                  setSimulationSrc={setSimulationSrc}
                                  isMobile={_750px}
                                />
                              </Grid>
                            );
                          })}
                        </GridComp>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </GridComp>
        </GridComp>
      </Box>

      <MobileBottomBar
        openMobileDropDown={openMobileBar}
        handleOpenMobileDropDown={handleOpenMobileBar}
        selectionText={"Click here to get started"}
        selectedData={selectedData}
        showData={showSim}
        leftSideMenu={
          <LeftSide
            selectedFromList={selection}
            handleOpenMobileDropDown={handleOpenMobileBar}
            onListClick={(e, i) => {
              setShowSimulation(false);
              listItemClickHandler(e, i);
            }}
            getMenuStats={getMenuStats}
            hierarchyData={hierarchyData}
            isLibrary={false}
            isFlashcard={false}
            isSimulation={true}
            selectedData={selectedData}
            setStructuredData={setStructuredData}
            setSelectedData={setSelectedData}
            tab={"simulationscards"}
          />
        }
      />
    </>
  );
};

export default Simulations;
