import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import useContextMenu from './useContextMenu';
import './styles.css';
import ClickAwayListener from 'react-click-away-listener';
import { highlightSelection, uniqueID } from '../List/Utils';
import HighlightAndCommentMenuWrapper from '../Highlight/HighlightAndCommentMenuWrapper';
import Model from '../Modal/Modal';
import HighlightInfoModal from '../../Containers/Subject/Tabs/MyLibrary/Notes/ThreeDotMenu/Models/HighlightInforModal/HighlightInfoModal';
import { HighlightCommentStyleContext } from '../../../contexts';

const TextSelectionComp = ({
  children,
  type,
  sideEffectsOfCommenting,
  sideEffectsOfHighlighting,
  isHighlightingActive,
  multipleBlocks,
  objective,
  index,
  activeIndex,
  setActiveIndex,
  isLearningObjective,
}) => {
  const [comment, setComment] = React.useState();
  const [commentId, setCommentId] = React.useState();
  const [commentRange, setCommentRange] = React.useState();
  const [previousSelection, setPreviousSelection] = React.useState();
  const [openModal, setopenModal] = React.useState(false);
  const [contentAfter, setContentAfter] = React.useState('');
  const [
    positionTopOfHighlightContextMenu,
    setPositionTopOfHighlightContextMenu,
  ] = React.useState();

  const [
    positionLeftOfHighlightContextMenu,
    setPositionLeftOfHighlightContextMenu,
  ] = React.useState();

  const highlightAndCommentMenuWrapperProps = {
    handleChangeColor,
    handleComment,
    setComment,
    closeBox,
    comment,
  };

  const { anchorPoint, show, changeShowHandler, handleContextMenu } =
    useContextMenu();

  function handleChangeColor(color) {
    highlightSelection(color);
    changeShowHandler(false);
    sideEffectsOfHighlighting();
  }

  function handleComment() {
    const data = {
      commentId,
      comment,
      outerHtml: contentAfter,
    };

    sideEffectsOfCommenting(data);
    changeShowHandler(false);
    setComment('');
  }

  useEffect(() => {
    if (activeIndex != index) {
      changeShowHandler(false);
    }
  }, [activeIndex, index]);

  const handleSelection = useCallback(() => {
    setActiveIndex(index);

    const ID = uniqueID();
    if (!window.getSelection().isCollapsed) {
      var range = window.getSelection().getRangeAt(0);
      if (commentRange !== range) {
        setCommentRange(range);

        const selection = window.getSelection().toString();
        var pattern = /\n./;
        const learningObjectiveRegex = /\b\d+\./g;

        if (
          pattern.test(selection) &&
          type === 'syllabus' &&
          selection.match(learningObjectiveRegex)
        ) {
          setopenModal(true);
          changeShowHandler(false);
        }
        if (selection === '') {
          return;
        } else {
          const htmlNode = document.createElement('span');
          htmlNode.setAttribute('id', ID);
          htmlNode.setAttribute('name', 'commentname');

          htmlNode.style.position = 'relative';
          htmlNode.style.zIndex = 100;
          range.insertNode(htmlNode);
          setCommentId(ID);
        }
      }
    }
  });

  document.ontouchend = () => {};
  document.onmouseup = () => {
    handleSelection();
  };

  const containerRef = React.useRef(null);
  const childRef = React.useRef(null);

  React.useEffect(() => {
    setContentAfter(childRef.current.outerHTML);
  }, [commentRange]);

  const setTopPositionOfContextMenu = () => {
    const distanceFromTopOfDocument =
      containerRef.current.getBoundingClientRect().top;

    const distanceOfElementFromWindowTop =
      window.pageYOffset + distanceFromTopOfDocument;

    const distanceFromParentToHighlight =
      anchorPoint.y - distanceOfElementFromWindowTop - 50;

    setPositionTopOfHighlightContextMenu(distanceFromParentToHighlight);
  };

  const setLeftPositionOfContextMenu = () => {
    const distanceFromLeftOfDocument =
      containerRef.current.getBoundingClientRect().left;
    const distanceOfElementFromWindowLeft =
      window.pageXOffset + distanceFromLeftOfDocument;
    const distanceFromParentToHighlight =
      anchorPoint.x - distanceOfElementFromWindowLeft;

    setPositionLeftOfHighlightContextMenu(distanceFromParentToHighlight);

    if (window.innerWidth > 200 && window.innerWidth < 500) {
      setPositionLeftOfHighlightContextMenu(
        distanceFromParentToHighlight > 30 ? 30 : 20
      );
    } else if (window.innerWidth > 1000 && window.innerWidth < 1600) {
      setPositionLeftOfHighlightContextMenu(
        distanceFromParentToHighlight > 950
          ? 900
          : distanceFromParentToHighlight
      );
    } else if (window.innerWidth > 1650) {
      setPositionLeftOfHighlightContextMenu(
        distanceFromParentToHighlight > 1150
          ? 1150
          : distanceFromParentToHighlight
      );
    } else {
      setPositionLeftOfHighlightContextMenu(distanceFromParentToHighlight);
    }
  };

  React.useEffect(() => {
    if (containerRef) {
      const selectionIsCollapsed = window.getSelection().isCollapsed;
      if (!selectionIsCollapsed) {
        setTopPositionOfContextMenu();
        setLeftPositionOfContextMenu();
      }
    }
  }, [anchorPoint]);

  function closeBox() {
    changeShowHandler(false);
    return;
  }

  function closeMenu() {
    if (show) {
      const selectionIsCollapsed = window.getSelection().isCollapsed;
      if (selectionIsCollapsed) {
        changeShowHandler(false);
        return;
      }
    }
  }

  return (
    <HighlightCommentStyleContext.Provider
      value={{
        top: positionTopOfHighlightContextMenu,
        left: positionLeftOfHighlightContextMenu,
      }}
    >
      <div
        ref={containerRef}
        style={{ position: 'relative' }}
        onContextMenu={(e) => {
          e.preventDefault();

          const Newselection = window.getSelection().toString();

          if (Newselection !== previousSelection) {
            setPreviousSelection(Newselection);
            if (isHighlightingActive) {
              const selectionIsCollapsed = window.getSelection().isCollapsed;
              if (selectionIsCollapsed) {
                return;
              }
              handleContextMenu(e);
            }
          }

          handleSelection();
        }}
        onMouseUp={(e) => {
          const Newselection = window.getSelection().toString();

          if (Newselection !== previousSelection) {
            setPreviousSelection(Newselection);
            if (isHighlightingActive) {
              const selectionIsCollapsed = window.getSelection().isCollapsed;
              if (selectionIsCollapsed) {
                return;
              }
              handleContextMenu(e);
            }
          }
        }}
        onTouchEnd={(e) => {}}
      >
        <ClickAwayListener onClickAway={closeMenu}>
          {show && !openModal ? (
            <div
              style={{
                position: 'absolute',
                top: positionTopOfHighlightContextMenu,
                left: positionLeftOfHighlightContextMenu,
              }}
            >
              <HighlightAndCommentMenuWrapper
                isLearningObjective={isLearningObjective}
                {...highlightAndCommentMenuWrapperProps}
              />
            </div>
          ) : (
            <></>
          )}
        </ClickAwayListener>
        <Model open={openModal} setOpen={setopenModal}>
          <HighlightInfoModal setOpen={setopenModal} />
        </Model>
        <div ref={childRef}>{children}</div>
      </div>
    </HighlightCommentStyleContext.Provider>
  );
};

TextSelectionComp.propTypes = {
  children: PropTypes.any,
  multipleBlocks: PropTypes.bool,
  isHighlightingActive: PropTypes.any,
  sideEffectsOfHighlighting: PropTypes.func,
};

TextSelectionComp.defaultProps = {
  children: <>No children provided</>,
  isHighlightingActive: true,
  multipleBlocks: false,
  sideEffectsOfHighlighting: (e) => {
    return;
  },
};

export default TextSelectionComp;
