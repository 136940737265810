import React, { useState } from 'react';
import ThreeDot from './ThreeDotMenu/icons/ThreeDot';
import IconButtonComp from '../../../../../UI/IconButton/IconButton';
import { styled } from '@mui/system';
import Menu from './../../../../../UI/Menu/Menu';
import DeleteSvg from './ThreeDotMenu/icons/DeleteSvg';
import Edit from './ThreeDotMenu/icons/Edit';
import TextEditor from './../../../../../UI/TextEditor/TextEditor';
import api from '../../../../../../Services';
import { useSnackbar } from 'notistack';
import { Provider, useDispatch, useSelector } from 'react-redux';
import SearchableText from './../../../../../UI/SearchableText/SearchableText';
import { myLibraryActions } from './../../../../../../redux/reducers/myLibrary.js';
import ReactDOM from 'react-dom';
import { store } from '../../../../../../redux/store';
import TextComp from '../../LearningResources/Tabs/Notes/TextComp';
import SingeCommentSVG from '../../LearningResources/Tabs/Notes/icons/SingeCommentSVG';
import { IconButtonWrapper } from '../../LearningResources/Tabs/Notes/TextComp.style';
import MulitipleCommentsSVG from '../../LearningResources/Tabs/Notes/icons/MulitipleCommentsSVG';
import TextSelectionComp from '../../../../../UI/TextSelection/TextSelectionComp';
import Loader from '../../../../../UI/Loader/Loader';
import Alert from '../../../../../UI/Alert/Alert';
import {
  closeALLComments,
  handleCloseBox,
} from '../../../../../../helpers/textComp';
import { Mixpanel } from '../../../../../../helpers/Mixpanel.js';
import MobileDetector from '../../../../../../helpers/mobileDetector.jsx';
import LogoLoader from '../../../../../UI/Loader/logoLoader.jsx';
const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
}));
const ThreeDotNoteMenu = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '10px',
  right: '5px',
}));
const TextWrapper = styled('div')(({ theme }) => ({
  flexGrow: 1,
}));

const AccordionContent = (props) => {
  const dispatch = useDispatch();
  const [showIcons, setShowIcons] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [noteError, setNoteError] = React.useState(false);
  const [currentObjective, setCurrentObjective] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { myLibraryHighlight } = useSelector((state) => state);
  const [activeComments, setActiveComments] = useState('');
  const { searchedTextValue: searchingData } = myLibraryHighlight;

  const childRef = React.useRef();

  const addComment = async (payload) => {
    try {
      const result = await api.addComment(payload);
      const { error } = result;
      if (error) {
        throw new Error(result.error);
      } else {
        setNoteError(false);

        return result.data;
      }
    } catch (error) {
      setNoteError(true);
    }
  };

  const deleteComment = async (commentId, resourceId, resource, edit) => {
    try {
      const result = await api.deleteComment(resourceId, resource);

      const { error } = result;

      if (error) {
        throw new Error(result.error);
      } else {
        setNoteError(false);

        if (edit) {
          return commentId;
        }

        currentObjective?.comments?.length > 0 &&
          currentObjective?.comments.forEach((ele) => {
            ReactDOM.unmountComponentAtNode(
              document.getElementById(ele.commentId)
            );
          });
        let updatedBody = await recieveEditsHandler(null, 'comment');

        let temp = currentObjective?.comments.filter(
          (ele) => ele.commentId !== commentId
        );
        setCurrentObjective((currentObjective) => ({
          ...currentObjective,
          comments: temp,
          body: updatedBody,
        }));
      }
    } catch (error) {
      setNoteError(true);
    }
  };

  function handleClick(id) {
    const commentObj = currentObjective?.comments.find(
      (ele) => ele.commentId === id
    );
    currentObjective?.comments?.map((comment) => {
      if (comment.commentId != id) {
        const found = document.getElementById(comment.commentId);

        const child = found ? found?.firstChild : null;

        if (child) {
          ReactDOM.unmountComponentAtNode(
            document.getElementById(comment.commentId)
          );
        }
      }
    });
    const child = document.getElementById(id).firstChild;
    if (child) {
      ReactDOM.unmountComponentAtNode(document.getElementById(id));
    } else {
      ReactDOM.render(
        <Provider store={store}>
          <TextComp
            value={commentObj.content}
            commentId={id}
            noteId={commentObj.noteId}
            handleActiveComment={() => {
              setActiveComments('');
            }}
            deleteComment={(id) => deleteComment(id, id, 'comment')}
            close={() => {
              handleCloseBox(id);
            }}
          />
        </Provider>,
        document.getElementById(id)
      );
    }
  }

  const sideEffectsOfHighlighting = (data, type) => {
    if (type !== 'comment') {
      Mixpanel.track('Highlighted Content in My Library Notes', {
        page: 'My Library Notes',
      });
    }
    currentObjective?.comments?.length > 0 &&
      currentObjective?.comments.forEach((ele) => {
        ReactDOM.unmountComponentAtNode(document.getElementById(ele.commentId));
      });
    recieveEditsHandler(data, type);
  };

  const sideEffectsOfCommenting = async (data) => {
    Mixpanel.track('Added comment in My Library Notes', {
      page: 'My Library Notes',
    });
    currentObjective?.comments?.length > 0 &&
      currentObjective?.comments?.forEach((ele) => {
        ReactDOM.unmountComponentAtNode(document.getElementById(ele.commentId));
      });

    const payload = {
      snackId: props.snackId,
      chapterId: props.chapterId,
      content: data.comment,
      userNotesId: props.id,
      commentId: data.commentId,
      library: true,
    };

    let newComment = await addComment(payload);

    let updatedBody = await recieveEditsHandler(null, 'comment');

    setCurrentObjective((currentObjective) => ({
      ...currentObjective,
      comments: [...currentObjective?.comments, newComment],
      body: updatedBody,
    }));
  };

  const sendEditsToApi = async (payload) => {
    let token = localStorage.getItem('auth');
    let temp = await api.updateNotesOnLibrary(payload, token);
    return temp;
  };

  const recieveEditsHandler = async (e, type) => {
    let data = '';
    if (e) {
      data = e;
    } else {
      data = {
        html: document.getElementById(props.noteId).innerHTML,
        delta: JSON.parse(currentObjective?.body).delta,
      };
    }

    try {
      Mixpanel.track(
        `Edited content in My Library | Course : ${props.courseName} | Unit : ${props.unitName} | Snack : ${props.snackName} `,
        {
          button_name: `Edit Button in my Library`,
          page: 'My Library',
        }
      );

      let el = await sendEditsToApi({
        id: props.id,
        body: JSON.stringify(data),
      });
      if (!el.error) {
        let commentIdsToFilter = [];
        let newdata = el.data[1][0].body;
        if (type === undefined) {
          await Promise.all(
            currentObjective?.comments?.map(async (comment) => {
              if (!newdata.includes(comment.commentId)) {
                let a = await deleteComment(
                  comment.commentId,
                  comment.commentId,
                  'comment',
                  true
                );
                commentIdsToFilter.push(a);
              }
              return commentIdsToFilter;
            })
          );
        }

        const temp = el.data[1][0];
        let body = temp.body;
        if (props.editMode) {
          let remainingComments;
          if (commentIdsToFilter.length !== 0) {
            remainingComments = currentObjective?.comments?.filter(
              (comment) => !commentIdsToFilter.includes(comment.commentId)
            );
          } else {
            remainingComments = currentObjective?.comments;
          }

          setCurrentObjective((currentObjective) => ({
            ...currentObjective,
            comments: remainingComments,
            body: body,
          }));
        } else {
          setCurrentObjective((currentObjective) => ({
            ...currentObjective,
            body: temp.body,
          }));
        }

        props.setEditMode(false);

        return body;
      } else {
        setIsLoading(false);
        enqueueSnackbar('Edits failed, please try again!', {
          variant: 'error',
        });
      }
    } catch (error) {}
  };
  const fetchNotes = async () => {
    setIsLoading(true);
    if (props.snackId) {
      await api
        .myLibraryNoteBySnackandTag({
          tagId: props.tagId,
          snackId: props.snackId,
        })
        .then((resp) => {
          setIsLoading(false);
          setCurrentObjective(resp.data);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      await api
        .myLibraryNoteByChapterandTag({
          tagId: props.tagId,
          chapterId: props.chapterId,
        })
        .then((resp) => {
          setIsLoading(false);
          setCurrentObjective(resp.data);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };
  React.useEffect(() => {
    if (
      parseInt(props.expanded) === parseInt(props.tagId) &&
      !currentObjective
    ) {
      fetchNotes();
    }
  }, [props.id, props.tagId, props]);

  React.useEffect(() => {
    setIsLoading(true);
    fetchNotes();
  }, [props.tagId, props.expanded]);

  return (
    <Wrapper>
      <TextWrapper>
        {props.editMode ? (
          <TextEditor
            closeEditMode={() => {
              props.setEditMode(false);
            }}
            edit={true}
            defaultContent={JSON.parse(currentObjective?.body).html}
            getContent={(e) => recieveEditsHandler(e, undefined)}
          />
        ) : isLoading ? (
          <LogoLoader />
        ) : noteError ? (
          <Alert message={'Error Occured.. !'} severity={'error'} />
        ) : (
          <TextSelectionComp
            sideEffectsOfCommenting={sideEffectsOfCommenting}
            sideEffectsOfHighlighting={() =>
              sideEffectsOfHighlighting(null, 'highlight')
            }
            isHighlightingActive={true}
            index={props.index}
            setActiveIndex={props.setActiveIndex}
            activeIndex={props.activeIndex}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: '20px',
              }}
            >
              {currentObjective && (
                <SearchableText
                  searchedTextValue={searchingData}
                  htmlData={currentObjective?.body}
                  ref={childRef}
                  noteId={props.noteId}
                />
              )}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                {currentObjective?.comments?.length === 0 ? null : (
                  <IconButtonWrapper
                    style={{
                      marginBottom: '0px',
                      marginTop: '30px',
                      padding: '10px 0px 10px 0px',
                    }}
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      if (showIcons) {
                        closeALLComments(currentObjective?.comments);
                        setActiveComments('');
                      }

                      setShowIcons(!showIcons);
                    }}
                  >
                    <MulitipleCommentsSVG active={showIcons} />
                  </IconButtonWrapper>
                )}

                {showIcons &&
                  currentObjective?.comments?.map((ele, index) => {
                    return (
                      <IconButtonWrapper
                        key={index}
                        style={{
                          padding: '2px 0px 0px 0px',
                        }}
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          if (activeComments === ele?.commentId) {
                            setActiveComments('');
                          } else {
                            setActiveComments(ele?.commentId);
                          }

                          handleClick(ele.commentId);
                        }}
                      >
                        <SingeCommentSVG
                          active={activeComments === ele?.commentId}
                        />
                      </IconButtonWrapper>
                    );
                  })}
              </div>
            </div>
          </TextSelectionComp>
        )}
      </TextWrapper>
    </Wrapper>
  );
};

export default AccordionContent;
