import React from 'react'

export default function MobileListIcon({ isactive }) {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                <g opacity="0.3" filter="url(#filter0_d_6167_34630)">
                    <circle cx="20" cy="20" r="8" fill={isactive ? '#E015A2' : "#624BA2"} />
                </g>
                <circle cx="20" cy="20" r="3.5" fill={isactive ? '#E015A2' : "#624BA2"} stroke={isactive ? '#E015A2' : "#624BA2"} />
            </svg>
           

        </>
    )
}
