import React from "react";
import AlertComp from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useNavigate } from "react-router";

const Alert = ({ severity, message, title, link }) => {
  const navigate = useNavigate();

  return (
    <AlertComp severity={severity}>
      <AlertTitle>
        {title}
        {"  "}
        {link && (
          <span
            style={{
              color: "purple",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/octibot");
            }}
          >
            click Here
          </span>
        )}
      </AlertTitle>
      {message}
    </AlertComp>
  );
};
export default Alert;

Alert.defaultProps = {
  severity: "info",
  message: "",
  title: "",
};
