import React from 'react'

export default function MobileDropDownSvg({ open }) {
    return (
        <>
            {open ? <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
                < path d="M7.32926 7.09702L12.8704 1.55589C13.0475 1.37245 13.0425 1.08013 12.859 0.902968C12.6801 0.730133 12.3964 0.730133 12.2175 0.902968L7.0028 6.11763L1.78813 0.902968C1.60783 0.722692 1.31551 0.722692 1.13521 0.902968C0.954936 1.0833 0.954936 1.37559 1.13521 1.55589L6.67634 7.09702C6.85667 7.27729 7.14896 7.27729 7.32926 7.09702Z" fill="#E015A2" stroke="#E015A2" />
            </svg > :<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
  <path d="M6.67074 0.902982L1.12962 6.44411C0.952451 6.62755 0.957538 6.91987 1.14098 7.09703C1.31993 7.26987 1.60361 7.26987 1.78254 7.09703L6.9972 1.88236L12.2119 7.09703C12.3922 7.27731 12.6845 7.27731 12.8648 7.09703C13.0451 6.9167 13.0451 6.62441 12.8648 6.44411L7.32366 0.902981C7.14333 0.722706 6.85104 0.722706 6.67074 0.902982Z" fill="#E015A2" stroke="#E015A2"/>
</svg>
            }
        </>
    )
}
