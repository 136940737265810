import React, { useState, useEffect } from 'react';
import StackCompo from '../../../../../UI/Stack/Stack';
import TypographyCompo from '../../../../../UI/Typography/TypographyCompo';
import Divider from '@mui/material/Divider';
import AddSvg from './../../LearningResources/icons/AddSvg';
import GridComp from '../../../../../UI/Grid/Grid';
import {
  FlashCardHeaderWrapper,
  FlashCardTagStack,
  FlashCardTagsButton,
  SelectedHeading,
  TotalFlashCard,
} from './FlashCard.Style';
import api from './../../../../../../Services';
import Loader from './../../../../../UI/Loader/Loader';
import { useParams } from 'react-router-dom';
import ErrorBoundary from './../../../../../../Errors/ErrorBoundary';
import { Box } from '@mui/material';
import { SearchBarText } from '../Notes/Notes.style';

const FlashCardHeader = ({
  left,
  activeTag,
  right,
  baseView,
  allTags,
  setAllTags,
  handleSelection,
  flashcards,
  showUnit,
  showChapter,
  stackName,
  isSimulation,
  simulations,
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const { name } = useParams();

  const getTagsFromDbB = async (token) => {
    if (isSimulation) {
      let temp = await api.getAllConceptTags(token);
      return temp;
    } else {
      let temp = await api.getAllTags(token);
      return temp;
    }
  };

  const getTagsApi = async () => {
    let token = localStorage.getItem('auth');
    getTagsFromDbB(token)
      .then((el) => {
        if (el.error) {
          return;
        }
        const { data } = el;
        setAllTags([...data]);
        setShowLoader(false);
      })
      .catch((error) => console.error(error));
  };

  const selectTagHandler = (e, item) => {
    handleSelection(e, item);
  };

  useEffect(() => {
    getTagsApi();
    setShowLoader(true);
  }, []);

  return (
    <>
      <FlashCardHeaderWrapper container spacing={2} baseView={baseView}>
        <GridComp item sx={{ display: 'flex' }}>
          <StackCompo direction="row">
            {/* <TotalFlashCard>
              {name} {isSimulation ? "Simulations" : "Flashcards"}
            </TotalFlashCard> */}

            {showUnit && (
              <TypographyCompo variant="body2" sx={{ minWidth: 'max-content' }}>
                <strong>Unit: </strong>
                {showUnit}
              </TypographyCompo>
            )}

            {showChapter && (
              <TypographyCompo variant="body2" sx={{ minWidth: 'max-content' }}>
                <strong>Chapter: </strong> {showChapter}
              </TypographyCompo>
            )}

            <TotalFlashCard>
              {isSimulation ? (
                <>
                  All Units Cards:{' '}
                  {simulations?.length > 0
                    ? simulations?.length < 9
                      ? '0' + simulations?.length
                      : simulations?.length
                    : null}
                </>
              ) : flashcards?.length === 1 ? (
                'Total Flash Card : '
              ) : (
                'Total Flash Cards : '
              )}{' '}
              {flashcards?.length > 0
                ? flashcards?.length < 9
                  ? '0' + flashcards?.length
                  : flashcards?.length
                : null}
            </TotalFlashCard>
          </StackCompo>
        </GridComp>

        {baseView && (
          <FlashCardTagStack direction={'row'}>
            {showLoader ? (
              <Loader />
            ) : (
              <>
                <ErrorBoundary>
                  <SearchBarText
                    sx={{
                      minWidth: '120px',
                      display: 'flex',
                    }}
                  >
                    Filters
                  </SearchBarText>
                  <FlashCardTagsButton
                    variant={'text'}
                    small="small"
                    isActive={!activeTag.id && activeTag.title === 'all'}
                    onClick={(e) => selectTagHandler(e, { title: 'all' })}
                  >
                    All
                  </FlashCardTagsButton>
                  {allTags.map((item, index) => (
                    <FlashCardTagsButton
                      variant="text"
                      size="small"
                      isActive={activeTag.id === item.id}
                      onClick={(e) => {
                        selectTagHandler(e, item);
                      }}
                      key={index}
                    >
                      {item.title}
                    </FlashCardTagsButton>
                  ))}
                </ErrorBoundary>
              </>
            )}
          </FlashCardTagStack>
        )}
      </FlashCardHeaderWrapper>
    </>
  );
};

export default FlashCardHeader;
