import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import pallette from '../../../../config/palette';
import ExpandMobileIcon from '../assets/expandMobileIcon';

export const Accordian = styled(MuiAccordion)(({ theme }) => ({
  background: pallette.colors.whiteColor,
  borderRadius: theme.spacing(1.5),
  boxShadow: '0px 0px 5px 0px rgba(0,0,0,0)',

  '&:before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    sx={{
      gap: '15px',
      alignItems: 'baseline',
      justifyContent: 'center',
    }}
    expandIcon={
      <ExpandMobileIcon
        expanded={props.expanded}
        sx={{ marginRight: '10px' }}
      />
    }
    {...props}
  />
))(({ theme, expanded }) => ({
  // backgroundColor: pallette.colors.whiteColor,
  flexDirection: 'row-reverse',
  borderBottom: `1px solid ${pallette.colors.selectedColor}`,
  padding: '5px 20px 5px 20px',
  backgroundColor: expanded ? '' : 'white !important',
  '&:hover': {
    backgroundColor: 'rgba(98, 75, 162, 0.1) !important', // Replace with your desired hover color
  },
  '&:focus': {
    backgroundColor: pallette.colors.selectedColor,
    marginTop: '-10px !important',
    '&:focus': {
      borderLeft: expanded ? `2px solid #E015A2` : '2px solid white !important',
    },
    borderLeft: `3px solid ${pallette.colors.primaryModified} `,
    '& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {},
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {},
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: pallette.colors.whiteColor,
  '& *::selection': {
    background: pallette.colors.yellow,
  },
}));
export const HeaderText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  color: '#251038',
  fontFeatureSettings: "'clig' off, 'liga' off",
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
}));

export const SecondaryAccordianDetials = styled('div')(({ theme, color }) => ({
  color: color ? color : '#ADB4C5',
  alignItems: 'center',
  fontFamily: 'Helvetica Neue',
  fontStyle: 'normal',
  fontSize: '16px',
  fontWeight: 400,
  fontFeatureSettings: "'clig' off, 'liga' off",
  [theme.breakpoints.down('sm')]: {
    color: '#ADB4C5',
    fontSize: '12px',
  },
}));
export const HeaderWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
