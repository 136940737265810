/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import BotSvg from '../Containers/Home/Sidebar/icons/BotSvg';

export default function IframeComp({ showBot, HandleBotClick, url }) {
  return (
    <>
      <iframe
        src={url}
        width="100%"
        style={{
          display: `${showBot ? 'block' : 'none'}`,
          overflowY: 'scroll',
          border: 'none',
          position: 'fixed',
          flexDirection: 'column',
          justifyContent: 'space-between',
          boxShadow:
            'rgba(150, 150, 150, 0.2) 0px 10px 30px 0px, rgba(150, 150, 150, 0.2) 0px 0px 0px 1px',
          bottom: '80px',
          right: '1rem',
          width: '450px',
          height: '85vh',
          maxHeight: '824px',
          borderRadius: '0.75rem',
          zIndex: '999999999',
          overflow: 'hidden',
          left: 'unset',
          color: 'red',
        }}
        frameborder="0"
      ></iframe>
      <div
        style={{
          position: 'fixed',
          bottom: '1rem',
          right: '1rem',
          width: '50px',
          height: '50px',
          borderRadius: '25px',
          backgroundColor: 'rgb(86, 36, 129)',
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px',
          cursor: 'pointer',
          display: `block`,
          zIndex: '999999999',
          transition: 'all 0.2s ease-in-out 0s',
          left: 'unset',
          transform: 'scale(1.08)',
        }}
      >
        <div
          onClick={HandleBotClick}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            zIndex: 2147483646,
          }}
        >
          {showBot ? (
            <svg
              id="closeIcon"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2.3"
              stroke="white"
              width="24"
              height="24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              ></path>
            </svg>
          ) : (
            <BotSvg />
          )}
        </div>
      </div>
    </>
  );
}
