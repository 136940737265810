const defaultController = [
  {
    content: <>no content given</>,
    description: "default desc",
    heading: "first",
  },
  {
    content: <>no content given</>,
    description: "default desc",
    heading: "second",
  },
];
const expandAll = false;
const obj = {
  defaultController,
  expandAll,
};

export default obj;
