import React from "react";
import {
  HalfCircle,
  MobileCourseImageC,
  MobileCourseImageCWrapper,
  MobileCourseWelcomeArrowDown,
  MobileCourseWelcomeHeading,
  MobileCourseWelcomeWrapper,
  MobileCourseWelcomedetails,
  MobileScreenNotesDropUp,
  MobileScreenNotesDropUpOval,
  MobileScreenNotesIcon,
  MobileScreenNotesSelectionText,
  MobileScreenSelectedWrapper,
  MobileScreenUnitName,
} from "../Containers/Subject/Tabs/LearningResources/Tabs/Notes/octiNotes.style";
import MobileDropDownSvg from "../Containers/Subject/Tabs/LearningResources/Tabs/Notes/icons/MobileDropdownsvg";
import { Box } from "@mui/material";

export default function MobileBottomBar({
  openMobileDropDown,
  handleOpenMobileDropDown,
  showData,
  selectedData,
  leftSideMenu,
  selectionText,
}) {
  return (
    <MobileScreenNotesDropUp open={openMobileDropDown}>
      <MobileScreenNotesDropUpOval onClick={handleOpenMobileDropDown}>
        <MobileScreenNotesIcon>
          <MobileDropDownSvg open={openMobileDropDown} />
        </MobileScreenNotesIcon>
      </MobileScreenNotesDropUpOval>
      <Box
        sx={{
          overflow: "scroll",
          height: "100%",
          display: openMobileDropDown ? "block" : "none",
        }}
      >
        {leftSideMenu}
      </Box>
      {!showData ? (
        <MobileScreenSelectedWrapper>
          <MobileScreenUnitName isactive={selectedData?.type === "unit"}>
            {selectedData?.unitname}
          </MobileScreenUnitName>
          <MobileScreenUnitName isactive={selectedData?.type === "chapter"}>
            {selectedData?.chaptername?.length > 0 ? " > " : ""}{" "}
            {selectedData?.chaptername}
          </MobileScreenUnitName>
          <MobileScreenUnitName isactive={selectedData?.type === "snack"}>
            {selectedData?.snackname?.length > 0 ? " > " : ""}{" "}
            {selectedData?.snackname}
          </MobileScreenUnitName>
        </MobileScreenSelectedWrapper>
      ) : (
        <MobileScreenNotesSelectionText>
          {selectionText}
        </MobileScreenNotesSelectionText>
      )}
    </MobileScreenNotesDropUp>
  );
}
