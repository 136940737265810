import React from 'react';
import Filter from '../components/Filter';
import { Link, useParams } from 'react-router-dom';
import capitalize from '../../../../../../../../helpers/capitalize';
import GRAPHICS from '../../../../../../../../assets/images/assessment';
import {
  ImgWrapper,
  StartButton,
  Wrapper,
  ContentWrapper,
  DetailWrapper,
  HeadingTwo,
  DetailBox,
  DetailContent,
  StartButtonWrapper,
  Image,
  AssessmentCompletionWrapper,
  AssessmentModalHeading,
  BoldText,
  PinkText,
  AssessmentCompleteModal,
  AssesementModalClose,
  AssessmentModalContent,
  AssessmentModalTip,
  AssessmentAnalyticsWrapper,
  AssessmentAnalyticsHint,
} from '../Assessment.style';

const AssessmentScreen = ({
  newSelection,
  updatedFilter,
  setAssessmentView,
  totalQuestions,
  updateZIndex,
  attemptedQuestions,
  remainingQuestions,
  completionModal,
  setCompletionModel,
  setFiler,
  setUpdatedFilter,
  filter,
}) => {
  const { name, id, value } = useParams();

  return (
    <>
      {completionModal === true && (
        <AssessmentCompletionWrapper>
          <AssessmentCompleteModal>
            <AssesementModalClose onClick={() => setCompletionModel(false)}>
              <img src={GRAPHICS.Cross} alt="Close" />
            </AssesementModalClose>

            <div>
              <img src={GRAPHICS.ConfirmModel} alt="Confirm" />
            </div>

            <AssessmentModalHeading>
              Assessment Completed!
            </AssessmentModalHeading>

            <AssessmentModalContent>
              You have attempted all questions on{' '}
              <BoldText>{capitalize(newSelection?.label || '')}</BoldText> from{' '}
              {updatedFilter?.from} to {updatedFilter?.to}.
            </AssessmentModalContent>

            <AssessmentModalTip>
              Change the
              <BoldText>
                <PinkText> year range </PinkText>
              </BoldText>
              to continue attempting more questions on the same topic or
              <BoldText>
                <PinkText> select a new topic.</PinkText>
              </BoldText>
            </AssessmentModalTip>
          </AssessmentCompleteModal>
        </AssessmentCompletionWrapper>
      )}

      <Wrapper>
        <Filter
          setFiler={setFiler}
          setUpdatedFilter={setUpdatedFilter}
          filter={filter}
        />

        <ContentWrapper>
          <ImgWrapper>
            <Image src={GRAPHICS.Octopus} alt="Img" />
          </ImgWrapper>

          <DetailWrapper>
            <HeadingTwo>
              {capitalize(newSelection?.type)}:{' '}
              {capitalize(newSelection?.label)}
            </HeadingTwo>

            <DetailBox>
              <span>Year</span>

              <DetailContent>
                {updatedFilter?.from} to {updatedFilter?.to}
              </DetailContent>
            </DetailBox>

            <DetailBox>
              <span>Total Questions</span>

              <DetailContent>{totalQuestions?.length || 0}</DetailContent>
            </DetailBox>

            <DetailBox>
              <span>Questions Attempted</span>

              <DetailContent>{attemptedQuestions?.length || 0}</DetailContent>
            </DetailBox>

            <DetailBox>
              <span>Questions Remaining</span>

              <DetailContent>{remainingQuestions?.length || 0}</DetailContent>
            </DetailBox>
          </DetailWrapper>
        </ContentWrapper>

        {remainingQuestions?.length === 0 && totalQuestions?.length > 0 ? (
          <AssessmentAnalyticsWrapper>
            <AssessmentAnalyticsHint>
              You can view analytics of your assessment
            </AssessmentAnalyticsHint>

            <Link to={`/dashboard/${name}/${id}/${value}/octilearnlibrary/4`}>
              <StartButton>View Analytics</StartButton>
            </Link>
          </AssessmentAnalyticsWrapper>
        ) : totalQuestions?.length > 0 ? (
          <StartButtonWrapper>
            <StartButton
              onClick={() => {
                updateZIndex(true);
                setAssessmentView(true);
              }}
              disabled={remainingQuestions?.length === 0}
            >
              Start
            </StartButton>
          </StartButtonWrapper>
        ) : (
          ''
        )}
      </Wrapper>
    </>
  );
};

export default AssessmentScreen;
