import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  data: {
    layer: "",
    id: "",
    open: false,
    name: "",
    type: "",
    isExpanded: false,
    label: "",
    isFlashcard: false,
    chapterId: null,
    nested: [],
    setopen: false,
  },

  myLibraryData: {
    layer: "",
    id: "",
    open: false,
    name: "",
    type: "",
    isExpanded: false,
    label: "",
    isFlashcard: false,
    chapterId: null,
    nested: [],
    setopen: false,
  },
};

const notesDropdown = createSlice({
  name: "notesdropdown",
  initialState,
  reducers: {

    setDataforOpen: (state, action) => {
      state.data = action.payload;
    },

    setMyLibraryData: (state, action) => {
      state.myLibraryData = action.payload;
    },

    resetData: (state) => {
      state.data = initialState.data;
    },

  },
});

export const notesDropdownActions = notesDropdown.actions;

export const notesDropdownReducer = notesDropdown.reducer;
