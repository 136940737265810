import React, { useEffect, useState } from 'react';
import ProgressBar from '../components/ProgressBar';
import Loader from '../../../../../../../UI/Loader/Loader';
import capitalize from '../../../../../../../../helpers/capitalize';
import MobileModal from '../../../../../../../mobileModal/mobileModal';
import GRAPHICS from '../../../../../../../../assets/images/assessment';
import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import {
  AdjacentText,
  BoldText,
  ChapterTitle,
  Content,
  Header,
  Icon,
  LoaderWrapper,
  PinkText,
  QuestionContainer,
  QuestionImage,
  QuestionImgWrapper,
  QuestionText,
  RadioBtnCustomLabel,
  ScoreContainer,
  ScoreContainerWrapper,
  StartButton,
  SubHeading,
  SubmitBtnWrapper,
} from '../Assessment.style';

const QuestionScreen = ({
  newSelection,
  setAssessmentView,
  allQuestions,
  updateZIndex,
  userId,
  userAttemptedQuestins,
  setUserAttemptedQuestions,
  setActiveScreen,
  addEditQuiz,
}) => {
  const [questions, setQuestions] = useState({
    value: 0,
    totalValue: allQuestions?.length,
  });

  const [loading, setLoading] = useState(false);
  const [questionCount, setQuestionCount] = useState(1);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const cachedAttemptedQuestions = allQuestions?.filter((item) => {
    return userAttemptedQuestins?.some((q) => q?.questionId === item?.id);
  });

  const handleCorrectAnswerChange = (event) => {
    const questionId = allQuestions[questions.value]?.id;

    const selectedOption = event.target.value;

    const isCorrect =
      allQuestions[questions?.value].correctAnswer === selectedOption
        ? true
        : false;

    const question = {
      questionId: questionId,
      isCorrect: isCorrect,
      selectedOption: selectedOption,
      userId: userId,
    };

    const isPresent = userAttemptedQuestins?.find((item) => {
      return item?.questionId === question?.questionId;
    });

    if (isPresent) {
      setUserAttemptedQuestions((prev) => {
        const updatedAttemptedQuestions = prev?.map((item) => {
          if (item?.questionId === question?.questionId) {
            return question;
          }
          return item;
        });

        localStorage.setItem(
          'attemptedQuestions',
          JSON.stringify(updatedAttemptedQuestions)
        );
        return updatedAttemptedQuestions;
      });
    } else {
      setUserAttemptedQuestions((prev) => {
        const all = [...prev, question];
        localStorage.setItem('attemptedQuestions', JSON.stringify(all));

        return all;
      });
    }
  };

  const current =
    questions?.value === questions?.totalValue
      ? questions?.value - 1
      : questions?.value;

  //  Add || Edit Quiz

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  });

  return (
    <>
      <QuestionContainer>
        <Header>
          <Box>
            <ChapterTitle>
              {capitalize(newSelection?.type)}: {newSelection?.label}
            </ChapterTitle>

            <SubHeading>
              <Icon src={GRAPHICS.Paper} alt="Paper" opacity="0.2" />{' '}
              <PinkText>
                {allQuestions[current]?.pastPaperReferenceText}
              </PinkText>
            </SubHeading>
          </Box>

          <Box
            onClick={() => {
              updateZIndex(false);
              setAssessmentView(false);
            }}
          >
            <Icon src={GRAPHICS.CloseIcon} alt="Close" />
          </Box>
        </Header>

        <Content>
          {loading ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            <>
              <QuestionText>{allQuestions[current]?.text}</QuestionText>
              <QuestionImgWrapper>
                <QuestionImage src={allQuestions[current]?.publicKey} />
              </QuestionImgWrapper>

              <RadioGroup
                aria-label="correct-answer"
                name="correct-answer"
                value={
                  userAttemptedQuestins?.find(
                    (question) =>
                      question?.questionId === allQuestions[questions.value]?.id
                  )?.selectedOption || ''
                }
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'center',
                  gap: '10px',
                }}
                onChange={handleCorrectAnswerChange}
              >
                <FormControlLabel
                  value="A"
                  control={
                    <Radio
                      sx={{
                        color: '#624BA2',
                        '&.Mui-checked': {
                          color: '#BF3895',
                        },
                      }}
                    />
                  }
                  label={
                    <RadioBtnCustomLabel
                      selected={
                        userAttemptedQuestins?.find(
                          (question) =>
                            question?.questionId ===
                            allQuestions[questions.value]?.id
                        )?.selectedOption === 'A'
                      }
                    >
                      A
                    </RadioBtnCustomLabel>
                  }
                  name="correctAnswer"
                />

                <FormControlLabel
                  value="B"
                  control={
                    <Radio
                      sx={{
                        color: '#624BA2',
                        '&.Mui-checked': {
                          color: '#BF3895',
                        },
                      }}
                    />
                  }
                  label={
                    <RadioBtnCustomLabel
                      selected={
                        userAttemptedQuestins?.find(
                          (question) =>
                            question?.questionId ===
                            allQuestions[questions.value]?.id
                        )?.selectedOption === 'B'
                      }
                    >
                      B
                    </RadioBtnCustomLabel>
                  }
                  name="correctAnswer"
                />

                <FormControlLabel
                  value="C"
                  control={
                    <Radio
                      sx={{
                        color: '#624BA2',
                        '&.Mui-checked': {
                          color: '#BF3895',
                        },
                      }}
                    />
                  }
                  label={
                    <RadioBtnCustomLabel
                      selected={
                        userAttemptedQuestins?.find(
                          (question) =>
                            question?.questionId ===
                            allQuestions[questions.value]?.id
                        )?.selectedOption === 'C'
                      }
                    >
                      C
                    </RadioBtnCustomLabel>
                  }
                  name="correctAnswer"
                />

                <FormControlLabel
                  color="red !important"
                  value="D"
                  control={
                    <Radio
                      sx={{
                        color: '#624BA2',
                        '&.Mui-checked': {
                          color: '#BF3895',
                        },
                      }}
                    />
                  }
                  label={
                    <RadioBtnCustomLabel
                      selected={
                        userAttemptedQuestins?.find(
                          (question) =>
                            question?.questionId ===
                            allQuestions[questions.value]?.id
                        )?.selectedOption === 'D'
                      }
                    >
                      D
                    </RadioBtnCustomLabel>
                  }
                  name="correctAnswer"
                />
              </RadioGroup>

              <ScoreContainerWrapper>
                <Box sx={{ display: { md: 'none', lg: 'flex' } }}></Box>
                <ScoreContainer>
                  {questions.value > 0 && (
                    <>
                      <AdjacentText>Previous</AdjacentText>

                      <Icon
                        onClick={() => {
                          if (questions.value > 0) {
                            setQuestions((prev) => {
                              return {
                                ...prev,
                                value: questions.value - 1,
                              };
                            });

                            setQuestionCount((prev) => prev - 1);
                            setLoading(true);
                          }
                        }}
                        src={GRAPHICS.Previous}
                        alt="Previous"
                      />
                    </>
                  )}

                  <AdjacentText>
                    <BoldText>{questionCount}</BoldText> /{''}
                    {questions.totalValue}
                  </AdjacentText>

                  {questions.value < questions.totalValue - 1 && (
                    <>
                      <Icon
                        style={{
                          cursor:
                            current < userAttemptedQuestins?.length
                              ? 'pointer'
                              : 'not-allowed',
                        }}
                        onClick={() => {
                          if (
                            questions.value < questions.totalValue - 1 &&
                            current < userAttemptedQuestins?.length
                          ) {
                            setQuestions((prev) => {
                              return {
                                ...prev,
                                value: questions.value + 1,
                              };
                            });

                            setQuestionCount((prev) => prev + 1);
                            setLoading(true);
                          }
                        }}
                        src={GRAPHICS.Next}
                        alt="Next"
                      />

                      <AdjacentText>Next</AdjacentText>
                    </>
                  )}
                </ScoreContainer>

                <SubmitBtnWrapper>
                  <StartButton onClick={() => setConfirmationModal(true)}>
                    Submit
                  </StartButton>
                </SubmitBtnWrapper>
              </ScoreContainerWrapper>

              <ProgressBar
                value={cachedAttemptedQuestions?.length}
                totalValue={questions?.totalValue}
              />
            </>
          )}
        </Content>
      </QuestionContainer>

      {confirmationModal && (
        <>
          <MobileModal
            open={confirmationModal}
            cancelText="Submit"
            okText="Review"
            modaltext="Do you want to review your responses before submission?"
            warningtext=" "
            isCopy={true}
            close={() => {
              setConfirmationModal(false);
            }}
            succes={() => {
              setAssessmentView(false);
              setConfirmationModal(false);
              setActiveScreen(1);
              updateZIndex(false);
            }}
            reset={() => {
              addEditQuiz();
              setAssessmentView(false);
              setConfirmationModal(false);
              updateZIndex(false);
              setActiveScreen(2);
            }}
          />
          {/* <ConfirmModalWrapper>
            <ConformationModal>
              <CrossButtonWrapper>
                <img
                  src={GRAPHICS.Cross}
                  alt="close"
                  style={{ cursor: "pointer" }}
                  onClick={() => setConfirmationModal(false)}
                />
              </CrossButtonWrapper>

              <img src={GRAPHICS.ConfirmModel} alt="confirm" />

              <TextWrapper>
                <span>
                  Do you want to review your responses before submission?
                </span>
              </TextWrapper>

              <ConformationBtnWrapper>
                <ReviewButton
                  onClick={() => {
                    setAssessmentView(false);
                    setConfirmationModal(false);
                    setActiveScreen(1);
                    updateZIndex(false);
                  }}
                >
                  Yes, I want to review.
                </ReviewButton>

                <ProceedButton
                  onClick={() => {
                    addEditQuiz();
                    setAssessmentView(false);
                    setConfirmationModal(false);
                    updateZIndex(false);
                    setActiveScreen(2);
                  }}
                >
                  No, I want to submit.
                </ProceedButton>
              </ConformationBtnWrapper>
            </ConformationModal>
          </ConfirmModalWrapper> */}
        </>
      )}
    </>
  );
};

export default QuestionScreen;
