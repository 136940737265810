import { styled } from "@mui/system";
import pallate from "../../../../config/palette";
import Accordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "../../Box/Box";
import MobileDetector from "../../../../helpers/mobileDetector";
import ExpandMobileIcon from "../assets/expandMobileIcon";

export const AccordianWrapper = styled(Accordion)(
  ({ theme, isMobile, expanded }) => ({
    background: pallate.colors.whiteColor,
    borderRadius: theme.spacing(1.5),
    padding: expanded ? "0px" : theme.spacing(1.6),
    marginBottom: theme.spacing(2),
    // boxShadow: "0px 0px 5px 0px rgba(0,0,0,0)",
    boxShadow: "0px 4px 13px 0px rgba(0, 0, 0, 0.06)",

    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    '&:hover': {
      backgroundColor: 'rgba(98, 75, 162, 0.1)', // Replace with your desired hover color
    },
  })
);

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expanded={props.expanded}
    expandIcon={
      <ExpandMobileIcon
        expanded={props.expanded}
        sx={{ marginRight: "10px" }}
      />
    }
    {...props}
  />
))(({ theme, expanded }) => ({
  flexDirection: "row-reverse",
  gap: "25px",
  alignItems: "baseline",
  justifyContent: "center",
  background: expanded ? "rgba(56, 25, 144, 0.20)" : "",
  borderRadius: "10px 10px 0px 0px", 
}));

export const SummaryDiv = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginRight: "5px",
}));

export const HeaderName = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "18px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  fontFeatureSettings: "'clig' off, 'liga' off",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "5px",
    fontSize: "14px",
  },
}));

export const Subheading = styled(Box)(({ theme, expanded }) => ({
  color: expanded ? "rgba(17, 38, 60, 0.7)" : "#624BA2",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Satoshi",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  '&:hover': {
    backgroundColor: 'rgba(98, 75, 162, 0.1)', // Replace with your desired hover color
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));
export const SubheadingTotal = styled(Box)(({ theme, expanded }) => ({
  color: expanded ? "rgba(17, 38, 60, 0.7)" : "#624BA2",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Satoshi",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 800,
  lineHeight: "normal",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));
