import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import pallate from "../../../config/palette";
import { Box } from "@mui/material";

export const Accordian = styled(MuiAccordion)(({ theme }) => ({
  background: "white",
  boxShadow: "0px 0px 5px 0px rgba(0,0,0,0)",
  "&:before": {
    display: "none ",
  },
  marginTop: "7px",
  borderBottom: "1px solid rgba(173, 180, 197, 0.30);",

  [theme.breakpoints.down("sm")]: {
    marginTop: "0px",
    borderBottom: "1px solid rgba(173, 180, 197, 0.30);",
  },
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ExpandMoreIcon sx={{ fontSize: "1.9rem", color: pallate.unselected }} />
    }
    {...props}
  />
))(({ theme }) => ({
  marginTop: "20px",
  height: "32px",
  minHeight: "32px",
  backgroundColor: "#f4f3f5",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));
export const AccordionSummaryMobile = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="7"
        viewBox="0 0 11 7"
        fill="none"
      >
        <path
          d="M9.29134 1.60417L5.49967 5.39584L1.70801 1.60417"
          stroke="#251038"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    }
    {...props}
  />
))(({ theme, expanded, isOctiLibrary }) => ({
  height: "70px",
  minHeight: "32px",
  backgroundColor: expanded ? "rgba(98, 75, 162, 0.15)" : "#FFFF",
  paddingLeft: "0px",
  paddingRight: isOctiLibrary
    ? expanded
      ? "30px"
      : "30px"
    : expanded
    ? "70px"
    : "30px",
  borderLeft: expanded ? "2px solid #624BA2" : "",
  // marginRight: theme.spacing(1),
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1.5),
  },
  [theme.breakpoints.down("sm")]: {
    background: "#FFFF",
  },
}));
export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: "#ffff",
  maxWidth: "100%",
}));

export const HeaderText = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontWeight: "700",
}));
export const HeaderTextMobile = styled(Typography)(({ theme, expanded }) => ({
  display: "flex",
  alignItems: "center",
  color: "#624BA2",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: expanded ? "700" : "600",
  lineHeight: "normal",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));
export const SaveContainer = styled(Box)(
  ({ theme, expanded, isOctiLibrary }) => ({
    position: "absolute",
    right: "20px",
    top: "10px",
    display: expanded ? "flex" : "none",
    width: "40px",
    height: "30px",
    padding: "11px 21px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    flexShrink: 0,
    borderRadius: "8px",
    background: " var(--violet, #624BA2)",
    zIndex: "999",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  })
);
export const EditContainer = styled(Box)(
  ({ theme, expanded, isOctiLibrary }) => ({
    position: "absolute",
    right: "-5px",
    top: "10px",
    display: !isOctiLibrary && expanded ? "flex" : "none",
    width: "40px",
    height: "30px",
    padding: "11px 21px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    flexShrink: 0,
    borderRadius: "8px",
    zIndex: "999",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  })
);

export const HeaderWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  textTransform: "capitalize",
  justifyContent: "flex-start",

  [theme.breakpoints.down("sm")]: {
    justifyContent: "flex-start",
  },
}));

export const MasterWrapper = styled("div")(({ theme }) => ({
  paddingBottom: "1rem",
  marginTop: "5px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "0px",
  },
}));
