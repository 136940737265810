import React from "react";
import { PropTypes } from "prop-types";
const HighlightSvg = ({ fillColor, isActive }) => {
  return (
    <>
      {isActive ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="10"
            cy="10"
            r="10"
            fill="#725DAB"
            fill-opacity="0.0666667"
          />
          <path
            d="M14.1296 9.78981C14.1296 12.1566 12.2003 14.0796 9.81482 14.0796C7.42935 14.0796 5.5 12.1566 5.5 9.78981C5.5 7.42307 7.42935 5.5 9.81482 5.5C12.2003 5.5 14.1296 7.42307 14.1296 9.78981Z"
            fill="#624BA2"
            stroke="white"
          />
        </svg>
      ) : (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10" cy="10" r="10" fill="#EEF0F1" />
          <path
            d="M14.1296 9.78981C14.1296 12.1566 12.2003 14.0796 9.81482 14.0796C7.42935 14.0796 5.5 12.1566 5.5 9.78981C5.5 7.42307 7.42935 5.5 9.81482 5.5C12.2003 5.5 14.1296 7.42307 14.1296 9.78981Z"
            fill="#11263C"
            stroke="white"
          />
        </svg>
      )}
    </>
  );
};

export default HighlightSvg;

HighlightSvg.propTypes = {
  fillColor: PropTypes.string,
  isActive: PropTypes.bool,
};

HighlightSvg.defaultProps = {
  fillColor: "#251038",
};
