import React from 'react';
import EachFlashcardHandler from './EachFlashcardHandler';

const FlashcardHandler = ({
  course,
  stacksArr,
  clicked,
  selection,
  selectedTag,
  isInLibrary,
  flashcardStatus,
  activeTag,
  stackId,
  handleSetStackId,
  title,
  specificFlashcards,
  heading,
  snackId,
  snackTagId,
  setCompletedFlashcards,
  chapterId,
  unitId,
}) => {
  return (
    <>
      {stacksArr.map((eachStack, index) => {
        return (
          <EachFlashcardHandler
            handleSetStackId={handleSetStackId}
            stackId={stackId}
            flashcardStatus={flashcardStatus}
            index={selection.id}
            saveLibraryInfo={course}
            isInLibrary={isInLibrary}
            type={selection.type}
            title={title}
            questions={eachStack.length}
            clicked={clicked}
            questionsArr={eachStack}
            key={index}
            activeTag={activeTag}
            selectedTag={selectedTag}
            specificFlashcards={specificFlashcards}
            heading={heading}
            snackId={snackId}
            snackTagId={snackTagId}
            setCompletedFlashcards={setCompletedFlashcards}
            chapterId={chapterId}
            unitId={unitId}
          />
        );
      })}
    </>
  );
};

export default FlashcardHandler;
