import React from 'react';

const NotesConfimation = ({ markColor, background }) => {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 82 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <circle cx="40.9776" cy="41.6461" r="40.9195" fill="#E015A2"  /> */}
      <circle cx="40.9776" cy="41.6461" r="40.9195" fill={background} />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.3276 48.3369C34.7971 47.8012 35.5594 46.3228 35.9891 45.8587C37.4566 44.277 38.789 42.5992 40.1278 40.9087C44.1188 35.8654 48.4325 31.0758 52.3157 25.9599C53.0182 25.0335 53.9989 23.8325 55.0848 23.169C55.7547 22.7593 56.4671 22.5418 57.1886 22.6016C57.9138 22.6614 58.6697 22.9923 59.4021 23.7836C60.3856 24.8459 60.2288 26.3334 59.8127 27.5824C58.9525 30.1621 57.2757 32.478 55.7148 34.6698C51.3558 40.79 46.8608 46.8123 42.0259 52.5636C41.0687 53.703 40.0191 55.1542 38.7827 56.2863C37.385 57.5653 35.7534 58.4382 33.801 58.265C32.1866 58.1218 30.8949 57.1673 29.6812 56.182C26.6927 53.7555 23.6916 49.9349 21.9811 46.4842C21.5379 45.5904 20.868 43.7159 20.9705 42.2385C21.0602 40.9414 21.6603 39.9107 23.0815 39.6053C23.881 39.4331 24.6914 39.6234 25.4573 40.0277C26.3719 40.5099 27.2221 41.3085 27.8711 41.865C29.2253 43.0298 30.2822 44.3758 31.3953 45.6475C32.2655 46.6428 33.1701 47.5936 34.3276 48.3369ZM35.1878 48.9043C35.3428 49.0892 35.4217 49.3331 35.3945 49.5823C35.4298 49.2515 35.3129 49.0412 35.1878 48.9043Z"
        fill={markColor}
      />
    </svg>
  );
};

export default NotesConfimation;
