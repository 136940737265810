import React from "react";

const SetToOrigionalSvg = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.95066 9.24375H7.54142C7.81204 9.24375 8.03647 9.03125 8.03647 8.775C8.03647 8.51875 7.81204 8.3125 7.54142 8.3125H3.95066C3.68003 8.3125 3.45561 8.51875 3.45561 8.775C3.45561 9.03125 3.68003 9.24375 3.95066 9.24375ZM6.18168 5.1875H3.95066C3.68003 5.1875 3.45561 5.4 3.45561 5.65625C3.45561 5.9125 3.68003 6.11875 3.95066 6.11875H6.18168C6.45231 6.11875 6.67673 5.9125 6.67673 5.65625C6.67673 5.4 6.45231 5.1875 6.18168 5.1875ZM10.8922 4.64101C11.0474 4.63933 11.2163 4.6375 11.3698 4.6375C11.5348 4.6375 11.6668 4.7625 11.6668 4.91875V9.94375C11.6668 11.4938 10.3401 12.75 8.70313 12.75H3.44901C1.73284 12.75 0.333496 11.4312 0.333496 9.80625V3.06875C0.333496 1.51875 1.66683 0.25 3.31039 0.25H6.83515C7.00676 0.25 7.13878 0.38125 7.13878 0.5375V2.55C7.13878 3.69375 8.13548 4.63125 9.3434 4.6375C9.62554 4.6375 9.87428 4.63948 10.092 4.64121C10.2613 4.64255 10.4119 4.64375 10.5447 4.64375C10.6387 4.64375 10.7605 4.64243 10.8922 4.64101ZM11.0742 3.72875C10.5316 3.73063 9.89204 3.72875 9.43198 3.72438C8.70195 3.72438 8.10063 3.155 8.10063 2.46375V0.81625C8.10063 0.546875 8.44584 0.413125 8.6432 0.6075C9.00036 0.959144 9.49116 1.44249 9.97974 1.92365C10.4672 2.40373 10.9525 2.88163 11.3006 3.22438C11.4934 3.41375 11.3521 3.72813 11.0742 3.72875Z"
        fill="#7C7088"
      />
    </svg>
  );
};

export default SetToOrigionalSvg;
