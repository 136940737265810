import React from 'react';
import { Box } from '@mui/material';
import QuestionCard from '../components/QuestionCard';
import {
  BtnSection,
  ClickAwayWrapper,
  HintSection,
  ReviewCardWrapper,
  ReviewHeading,
  ReviewSubmitButton,
  ReviewSubmitSection,
  ReviewText,
  SubmitSection,
} from '../Assessment.style';

const ReviewScreen = ({
  allQuestions,
  course,
  setActiveScreen,
  userAttemptedQuestins,
  setUserAttemptedQuestions,
  userId,
  addEditQuiz,
  newSelection,
}) => {
  const reviewQuestions = allQuestions?.filter((item) => {
    return userAttemptedQuestins?.some((q) => item?.id === q?.questionId);
  });

  return (
    <Box>
      <ReviewSubmitSection>
        <SubmitSection>
          <BtnSection>
            <ClickAwayWrapper>
              <ReviewHeading>"{newSelection?.label || ''}"</ReviewHeading>

              <ReviewText>You can submit your assessment</ReviewText>
            </ClickAwayWrapper>

            <Box>
              <ReviewSubmitButton
                onClick={() => {
                  setActiveScreen(2);
                  addEditQuiz();
                }}
              >
                Submit Assessment
              </ReviewSubmitButton>
            </Box>
          </BtnSection>

          <div
            style={{
              width: '60%',
              maxHeight: '120px',
            }}
          >
            <img
              src={course?.img}
              alt="img"
              style={{
                width: '100%',
                height: '100%',
                maxHeight: '120px',
                objectFit: 'cover',
              }}
            />
          </div>
        </SubmitSection>

        <HintSection>
          Hint! You can change your answers by selecting another choice.
        </HintSection>
      </ReviewSubmitSection>

      <ReviewCardWrapper>
        {reviewQuestions &&
          reviewQuestions?.map((item, index) => {
            return (
              <QuestionCard
                item={item}
                userId={userId}
                index={index}
                totalQuestions={reviewQuestions?.length}
                userAttemptedQuestins={userAttemptedQuestins}
                setUserAttemptedQuestions={setUserAttemptedQuestions}
              />
            );
          })}
      </ReviewCardWrapper>
    </Box>
  );
};

export default ReviewScreen;
