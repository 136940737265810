import * as React from "react";
import { useState } from "react";
import Divider from "@mui/material/Divider";
import Stack from "../../../../../UI/Stack/Stack";
import Typography from "../../../../../UI/Typography/TypographyCompo";
import ToolTip from "../../../../../UI/Tooltip/ToolTip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HighlightSvg from "../../../../../../assets/images/Subject/HighlightSvg";
import CommentSvg from "../../../../../../assets/images/Subject/CommentSvg";
import { useParams } from "react-router-dom";
import capitalize from "../../../../../../helpers/capitalize";
import { subjectActions } from "../../../../../../redux/reducers/subject";
import { useDispatch, useSelector } from "react-redux";
import constants from "../../../../../../config/constants";
import { learningResourcesHighlightActions } from "./../../../../../../redux/reducers/learningResourcesHighlight";
import { CommentResourcesHighlightActions } from "../../../../../../redux/reducers/commentResourceHiglight";
import {
  StackWrapper,
  HeaderButton,
  ExpandButton,
  HeaderDiv,
  TotalUnits,
} from "./Syllabus.style";
import MobileDetector from "../../../../../../helpers/mobileDetector";

const Header = () => {
  const isMobile = MobileDetector();
  const accordionsAreExpanded = useSelector(
    (state) => state.subject.expandAccordian
  );
  const { name, value } = useParams();
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const expandHandler = () => {
    setIsExpanded((prevValue) => !prevValue);
    dispatch(subjectActions.accordianExpandHandler());
  };
  const { syllabus } = constants.learningResources;
  const isHighlightingActive = useSelector(
    (state) => state.learningResources.isHighlighting
  );
  const isCommentHiglightingActive = useSelector(
    (state) => state.commentResources.isHighlighting
  );
  return (
    <StackWrapper>
      <TotalUnits>
        {" "}
        {capitalize(name)} {syllabus.syllabus} ({value} Units)
      </TotalUnits>
      <Divider light style={{ marginBottom: "-12px" }} />
      <HeaderDiv>
        <Stack
          direction="row"
          spacing={{ xs: 0, sm: 3, md: 3 }}
          sx={{
            width: "100%",
            overflowX: "auto",
            justifyContent: "space-between",
          }}
        >
          <Stack direction="row" spacing={1}>
            <ToolTip title={syllabus.highlightTooltip}>
              <HeaderButton
                variant="text"
                onClick={(e) => {
                  isCommentHiglightingActive &&
                    dispatch(
                      CommentResourcesHighlightActions.toggleHighlightState()
                    );
                  dispatch(
                    learningResourcesHighlightActions.toggleHighlightState()
                  );
                }}
                isActive={isHighlightingActive}
                startIcon={
                  <HighlightSvg
                    fillColor="#251038"
                    isActive={isHighlightingActive}
                  />
                }
              >
                {isMobile
                  ? constants.HighlightTextMobile
                  : constants.HighlightText}
              </HeaderButton>
            </ToolTip>
            <Divider orientation="vertical" variant="string" flexItem />
            <ToolTip title={syllabus.commentTooltip}>
              <HeaderButton
                variant="text"
                startIcon={
                  <CommentSvg
                    fillColor="#251038"
                    isActive={isCommentHiglightingActive}
                  />
                }
                isActive={isCommentHiglightingActive}
                onClick={() => {
                  isHighlightingActive &&
                    dispatch(
                      learningResourcesHighlightActions.toggleHighlightState()
                    );
                  dispatch(
                    CommentResourcesHighlightActions.toggleHighlightState()
                  );
                }}
              >
                {isMobile ? constants.CommentTextMobile : constants.CommentText}
              </HeaderButton>
            </ToolTip>
          </Stack>
          <Divider orientation="vertical" variant="string" flexItem />

          <ExpandButton
            variant="text"
            onClick={expandHandler}
            isActive={isExpanded}
            endIcon={
              <KeyboardArrowDownIcon
                sx={{
                  transform: `scale(1.3) ${
                    accordionsAreExpanded ? "rotate(180deg)" : "rotate(0deg)"
                  }`,
                }}
              />
            }
          >
            {accordionsAreExpanded
              ? constants.CollapseAllUnits
              : isMobile
              ? constants.ExpandAllUnitsMobile
              : constants.ExpandAllUnits}
          </ExpandButton>
        </Stack>
      </HeaderDiv>

      <Divider light style={{ marginTop: "2px" }} />
    </StackWrapper>
  );
};

export default Header;
