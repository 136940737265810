import React from "react";

function NotesSvg({color,width, height}) {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 15 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.18161 13.3412H10.1189C10.491 13.3412 10.7996 13.0437 10.7996 12.685C10.7996 12.3262 10.491 12.0375 10.1189 12.0375H5.18161C4.80949 12.0375 4.50091 12.3262 4.50091 12.685C4.50091 13.0437 4.80949 13.3412 5.18161 13.3412ZM8.24926 7.6625H5.18161C4.80949 7.6625 4.50091 7.96 4.50091 8.31875C4.50091 8.6775 4.80949 8.96625 5.18161 8.96625H8.24926C8.62137 8.96625 8.92995 8.6775 8.92995 8.31875C8.92995 7.96 8.62137 7.6625 8.24926 7.6625ZM14.7262 6.89741C14.9396 6.89506 15.1719 6.8925 15.3829 6.8925C15.6098 6.8925 15.7913 7.0675 15.7913 7.28625V14.3212C15.7913 16.4913 13.9671 18.25 11.7163 18.25H4.49184C2.1321 18.25 0.208008 16.4037 0.208008 14.1287V4.69625C0.208008 2.52625 2.04134 0.75 4.30124 0.75H9.14778C9.38375 0.75 9.56527 0.93375 9.56527 1.1525V3.97C9.56527 5.57125 10.9357 6.88375 12.5966 6.8925C12.9846 6.8925 13.3266 6.89527 13.6259 6.89769C13.8587 6.89957 14.0658 6.90125 14.2484 6.90125C14.3776 6.90125 14.5451 6.89941 14.7262 6.89741ZM14.9765 5.62025C14.2305 5.62288 13.351 5.62025 12.7184 5.61413C11.7146 5.61413 10.8878 4.817 10.8878 3.84925V1.54275C10.8878 1.16562 11.3625 0.978375 11.6338 1.2505C12.1253 1.74311 12.8007 2.42034 13.4729 3.09439C14.1427 3.76607 14.8094 4.43459 15.2878 4.91413C15.5528 5.17925 15.3586 5.61938 14.9765 5.62025Z"
          fill={color}
        />
      </svg>
    </div>
  );
}

export default NotesSvg;
