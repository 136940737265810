import React from 'react';

const OpenFullView = ({ isMobile, open }) => {
  return (
    <>
      {isMobile ? (
        <>
          {!open ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
            >
              <g clip-path="url(#clip0_6782_38549)">
                <path
                  d="M9.28613 3.25006V5.84126e-05H10.2147V2.78577H13.0004V3.71436H9.75042C9.49379 3.71436 9.28613 3.5067 9.28613 3.25006V3.25006Z"
                  fill="white"
                />
                <path
                  d="M3.25001 3.71436H0V2.78578H2.78572V6.03199e-05H3.7143V3.25007C3.7143 3.5067 3.50664 3.71436 3.25001 3.71436V3.71436Z"
                  fill="white"
                />
                <path
                  d="M2.78572 10.2143H0V9.2857H3.25001C3.50664 9.2857 3.7143 9.49336 3.7143 9.74999V13H2.78572V10.2143Z"
                  fill="white"
                />
                <path
                  d="M10.2147 13H9.28613V9.74999C9.28613 9.49336 9.49379 9.2857 9.75042 9.2857H13.0004V10.2143H10.2147V13Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_6782_38549">
                  <rect
                    width="13"
                    height="13"
                    fill="white"
                    transform="matrix(-1 0 0 1 13 0)"
                  />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <g clip-path="url(#clip0_6782_38326)">
                <path
                  d="M12 0.415183V3.32144H11.1428V0.830366H8.57142V0H11.5714C11.8083 0 12 0.185691 12 0.415183V0.415183Z"
                  fill="#E015A2"
                />
                <path
                  d="M0.428705 0H3.42871V0.830365H0.85728V3.32144H0.000128269V0.415183C0.000128269 0.185691 0.19181 0 0.428705 0V0Z"
                  fill="#E015A2"
                />
                <path
                  d="M0.85728 10.7948H3.42871V11.6251H0.428705C0.19181 11.6251 0.000128269 11.4395 0.000128269 11.21V8.30371H0.85728V10.7948Z"
                  fill="#E015A2"
                />
                <path
                  d="M11.1428 8.30371H12V11.21C12 11.4395 11.8083 11.6251 11.5714 11.6251H8.57142V10.7948H11.1428V8.30371Z"
                  fill="#E015A2"
                />
              </g>
              <defs>
                <clipPath id="clip0_6782_38326">
                  <rect
                    width="12"
                    height="11.625"
                    fill="white"
                    transform="matrix(-1 0 0 1 12 0)"
                  />
                </clipPath>
              </defs>
            </svg>
          )}
        </>
      ) : (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_631_1536)">
            <path
              d="M16 0.571434V4.57144H14.8571V1.14287H11.4286V0H15.4286C15.7444 0 16 0.255574 16 0.571434V0.571434Z"
              // fill="#ADB4C5"
              fill="#ffffff"
            />
            <path
              d="M0.570304 0H4.57031V1.14287H1.14174V4.57144H-0.0011313V0.571434C-0.0011313 0.255574 0.254444 0 0.570304 0V0Z"
              // fill="#ADB4C5"
              fill="#ffffff"
            />
            <path
              d="M1.14174 14.8563H4.57031V15.9992H0.570304C0.254444 15.9992 -0.0011313 15.7436 -0.0011313 15.4277V11.4277H1.14174V14.8563Z"
              // fill="#ADB4C5"
              fill="#ffffff"
            />
            <path
              d="M14.8571 11.4277H16V15.4277C16 15.7436 15.7444 15.9992 15.4286 15.9992H11.4286V14.8563H14.8571V11.4277Z"
              // fill="#ADB4C5"
              fill="#ffffff"
            />
          </g>
          <defs>
            <clipPath id="clip0_631_1536">
              <rect
                width="16"
                height="16"
                fill="white"
                transform="matrix(-1 0 0 1 16 0)"
              />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};

export default OpenFullView;
