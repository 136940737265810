import { styled } from "@mui/system";

export const Input = styled("textarea")(({ theme, height }) => ({
  backgroundColor: "transparent",
  color: "white",
  width: "98%",
  height: "60px",
  border: "unset",
  resize: "none",
  paddingLeft: "10px",
  paddingTop: "12px",
  fontSize: "10px",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  fontFeatureSettings: "'clig' off, 'liga' off",
  color: "white",
  "::placeholder": {
    color: "white",
  },
  ":focus-visible": {
    outline: "unset",
  },
  [theme.breakpoints.down("sm")]: {
    height: "98%",
  },
}));
