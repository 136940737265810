import React, { useEffect, useRef } from 'react';
import DatePicker from 'react-date-picker';
import { FilterDiv, FilterWrapper, Range } from '../Assessment.style';
import GRAPHICS from '../../../../../../../../assets/images/assessment';

const Filter = ({ setFiler, setUpdatedFilter, filter }) => {
  useEffect(() => {
    if (filter?.to < filter.from) {
      setFiler((prev) => ({
        ...prev,
        to: filter.from,
      }));

      setUpdatedFilter((prev) => ({
        ...prev,
        to: filter.from?.getFullYear(),
      }));
    }
  }, [filter]);

  return (
    <>
      <FilterWrapper>
        <FilterDiv>
          <Range>From</Range>

          <DatePicker
            onChange={(value) => {
              setFiler((prev) => {
                return {
                  ...prev,
                  from: value,
                };
              });

              setUpdatedFilter((prev) => {
                return {
                  ...prev,
                  from: value?.getFullYear(),
                };
              });
            }}
            value={filter?.from}
            maxDetail="decade"
            format="yyyy"
            yearPlaceholder="From"
            maxDate={new Date(2023, 11, 31)}
            minDate={new Date(2010, 0, 1)}
            clearIcon={false}
            calendarIcon={GRAPHICS.CalenderSvg}
          />
        </FilterDiv>

        <FilterDiv>
          <Range>To</Range>

          <DatePicker
            onChange={(value) => {
              setFiler((prev) => {
                return {
                  ...prev,
                  to: value,
                };
              });

              setUpdatedFilter((prev) => {
                return {
                  ...prev,
                  to: value?.getFullYear(),
                };
              });
            }}
            value={filter?.to}
            maxDetail="decade"
            format="yyyy"
            yearPlaceholder="To"
            maxDate={new Date(2023, 11, 31)}
            minDate={new Date(2010, 0, 1)}
            clearIcon={false}
            calendarIcon={GRAPHICS.CalenderSvg}
          />
        </FilterDiv>
      </FilterWrapper>
    </>
  );
};

export default Filter;
