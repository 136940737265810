import React from "react";
import Typography from "./../../../../../UI/Typography/TypographyCompo";
import Stack from "./../../../../../UI/Stack/Stack";
import { styled } from "@mui/system";
import ErrorBoundary from "./../../../../../../Errors/ErrorBoundary";
import ThreeDotMenu from "./ThreeDotMenu/ThreeDotMenu";
import {
  MobileDivider,
  PrimaryNameMobile,
  SecondaryNameMobile,
} from "../../LearningResources/Tabs/Notes/NotesContent.style";
import { Box } from "@mui/material";
import capitalize from "../../../../../../helpers/capitalize";

const HeadingContainer = styled("div")(({ theme }) => ({
  background: "rgba(98, 75, 162, 0.10)",
  borderRadius: "10px 10px 0px 0px",
  minHeight: "93px",
  display: "flex",
  alignItems: "center",
  paddingLeft: "15px",

  [theme.breakpoints.down("sm")]: {
    paddingLeft: "7px",
  },
}));

const PrimaryName = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "18px",
}));
const SecondaryName = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "13px",
}));
const TertiaryName = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "13px",
  opacity: 0.4,
}));

const NotesHeading = ({
  unit,
  chapter,
  snack,
  snackId,
  subjectName,
  chapterId,
  unitId,
  handleResetNotes,
  handleDelete,
  selection,
}) => {
  return (
    <ErrorBoundary>
      <HeadingContainer
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingRight: "20px",
        }}
      >
        <Stack
          direction="column"
          alignItems="flex-start"
          spacing={0}
          width="100%"
          sx={{ padding: "10px 10px", display: "flex" }}
        >
          <PrimaryNameMobile variant="h6">
            {selection.type === "unit"
              ? `Unit: ${selection.name}`
              : selection.type === "chapter"
              ? `Chapter: ${chapter}`
              : `Snack: ${snack}`}
          </PrimaryNameMobile>
          <Box>
            <SecondaryNameMobile variant="body">
              {selection.type === "unit"
                ? `Chapter: ${chapter}`
                : selection.type === "chapter"
                ? `Snack: ${capitalize(snack)}`
                : null}
            </SecondaryNameMobile>
            {selection.type === "unit" ? <MobileDivider /> : ""}
            <SecondaryNameMobile variant="body">
              {selection.type === "unit"
                ? `Snack: ${capitalize(snack)}`
                : selection.type === "chapter"
                ? ""
                : selection.type === "snack"
                ? ""
                : null}
            </SecondaryNameMobile>
          </Box>
        </Stack>

        <ThreeDotMenu
          snack={snack}
          chapterId={chapterId}
          unitId={unitId}
          snackId={snackId}
          subjectName={subjectName}
          handleResetNotes={handleResetNotes}
          handleDelete={handleDelete}
        />
      </HeadingContainer>
    </ErrorBoundary>
  );
};

export default NotesHeading;
