import React from 'react';

const Dashboard = ({ focused }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.09106 1H2.9854C1.88889 1 1 1.8888 1 2.98519V8.09032C1 9.18672 1.88889 10.0755 2.9854 10.0755H8.09106C9.18757 10.0755 10.0765 9.18672 10.0765 8.09032V2.98519C10.0765 1.8888 9.18757 1 8.09106 1Z"
        stroke={focused ? 'white' : '#ADB4C5'}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.0149 1H15.9092C14.8127 1 13.9238 1.8888 13.9238 2.98519V8.09032C13.9238 9.18672 14.8127 10.0755 15.9092 10.0755H21.0149C22.1114 10.0755 23.0003 9.18672 23.0003 8.09032V2.98519C23.0003 1.8888 22.1114 1 21.0149 1Z"
        stroke={focused ? 'white' : '#ADB4C5'}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.09106 13.9219H2.9854C1.88889 13.9219 1 14.8107 1 15.9071V21.0122C1 22.1086 1.88889 22.9974 2.9854 22.9974H8.09106C9.18757 22.9974 10.0765 22.1086 10.0765 21.0122V15.9071C10.0765 14.8107 9.18757 13.9219 8.09106 13.9219Z"
        stroke={focused ? 'white' : '#ADB4C5'}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.0149 13.9219H15.9092C14.8127 13.9219 13.9238 14.8107 13.9238 15.9071V21.0122C13.9238 22.1086 14.8127 22.9974 15.9092 22.9974H21.0149C22.1114 22.9974 23.0003 22.1086 23.0003 21.0122V15.9071C23.0003 14.8107 22.1114 13.9219 21.0149 13.9219Z"
        stroke={focused ? 'white' : '#ADB4C5'}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Dashboard;
