import React from "react";
import * as StyledComponents from "./PrimaryAccordian.style";
import PropTypes from "prop-types";
import AccordionDetails from "@mui/material/AccordionDetails";
import defaultProps from "./defaultProps";
import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";
import MobileDetector from "../../../../helpers/mobileDetector";
import { Mixpanel } from '../../../../helpers/Mixpanel.js';

const {
  AccordianWrapper,
  AccordionSummary,
  SummaryDiv,
  HeaderName,
  Subheading,
  SubheadingTotal,
} = StyledComponents;

const Accordians = ({ controller, expandAll, toggleExpandAllOff }) => {
  const isMobile = MobileDetector();
  const [expandArr, setExpandArr] = React.useState([]);
  const changeAccordionHandler = (e, index) => {
    let temp = [...expandArr];
    const alreadyExists = temp.includes(index);
    toggleExpandAllOff && toggleExpandAllOff();

    if (alreadyExists) {
      temp.splice(
        temp.findIndex((e) => e === index),
        1
      );
      Mixpanel.track(
        `Learning Objective ${controller[index].heading} | Status : In-Active`,
        {
          button_name: `${controller[index].heading} Button`,
          page: "Syllabus",
        }
      );
      return setExpandArr(temp);
    } else {
      Mixpanel.track(
        `Learning Objective ${controller[index].heading} | Status : Active`,
        {
          button_name: `${controller[index].heading} Signup Button`,
          page: "Syllabus",
        }
      );
      temp.push(index);
      return setExpandArr(temp);
    }
  };

  React.useEffect(() => {
    let temp = [];
    if (expandAll) {
      temp = controller.map((el, index) => index);
    }
    setExpandArr(temp);
  }, [expandAll, setExpandArr]);
  return (
    <div>
      {controller.map((accordion, index) => (
        <>
          <AccordianWrapper
            isMobile={isMobile}
            TransitionProps={{ unmountOnExit: true }}
            expanded={expandArr.includes(index)}
            onChange={(e) => changeAccordionHandler(e, index)}
            square={true}
            key={index}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              isMobile={isMobile}
              expanded={expandArr.includes(index)}
            >
              <SummaryDiv>
                <HeaderName>
                  <strong style={{ fontFamily: "Inter", color: "#251038" }}>
                    {accordion.heading}
                  </strong>
                  {isMobile ? (
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <Subheading>{accordion.description}</Subheading>
                      <SubheadingTotal expanded={expandArr.includes(index)}>
                        {accordion.contentType}
                      </SubheadingTotal>
                    </Box>
                  ) : (
                    ""
                  )}
                </HeaderName>

                <Box
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  {!isMobile ? (
                    <>
                      <SubheadingTotal expanded={expandArr.includes(index)}>
                        {accordion.description}
                      </SubheadingTotal>
                      <Subheading expanded={expandArr.includes(index)}>
                        {accordion.contentType}
                      </Subheading>
                    </>
                  ) : (
                    ""
                  )}
                  {accordion.percentage > 99 ? (
                    <Box style={{ position: "relative", paddingTop: "8px" }}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <ellipse
                          cx="10"
                          cy="10"
                          rx="10"
                          ry="10"
                          fill="#E015A2"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.36861 12.0901C8.49023 11.9514 8.68767 11.5685 8.79896 11.4483C9.17906 11.0386 9.52418 10.604 9.87094 10.1662C10.9047 8.85987 12.0219 7.61932 13.0277 6.29425C13.2097 6.05431 13.4637 5.74323 13.745 5.57138C13.9185 5.46526 14.103 5.40891 14.2899 5.42441C14.4777 5.43991 14.6735 5.5256 14.8632 5.73056C15.1179 6.00571 15.0773 6.39098 14.9695 6.7145C14.7467 7.38267 14.3124 7.98252 13.9081 8.5502C12.7791 10.1354 11.6148 11.6952 10.3626 13.1849C10.1146 13.48 9.84276 13.8559 9.52253 14.1491C9.16051 14.4804 8.73791 14.7065 8.23221 14.6616C7.81407 14.6245 7.47952 14.3773 7.16516 14.1221C6.3911 13.4936 5.61376 12.504 5.17074 11.6103C5.05594 11.3788 4.88244 10.8933 4.90897 10.5106C4.93221 10.1746 5.08763 9.90767 5.45576 9.82855C5.66283 9.78395 5.87272 9.83325 6.07111 9.93796C6.30799 10.0629 6.52821 10.2697 6.69631 10.4138C7.04707 10.7155 7.32081 11.0642 7.60912 11.3936C7.8345 11.6513 8.06881 11.8976 8.36861 12.0901ZM8.59141 12.2371C8.63156 12.285 8.65199 12.3482 8.64494 12.4127C8.6541 12.327 8.62381 12.2726 8.59141 12.2371Z"
                          fill="white"
                        />
                      </svg>
                    </Box>
                  ) : (
                    <Box style={{ position: "relative", paddingTop: "8px" }}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          opacity="0.1"
                          cx="9.5"
                          cy="9.5"
                          r="9.5"
                          fill="#e015a2"
                        />
                      </svg>
                      <CircularProgress
                        sx={{ width: 20, height: 20 }}
                        style={{
                          color: "#e015a2",
                          width: "20px",
                          height: "20px",
                          position: "absolute",
                          left: "0px",
                        }}
                        value={accordion.percentage}
                        variant="determinate"
                      />
                    </Box>
                  )}
                </Box>
              </SummaryDiv>
            </AccordionSummary>
            <AccordionDetails sx={{ fontFamily: "Inter", padding: "0px" }}>
              {accordion.content}
            </AccordionDetails>
          </AccordianWrapper>
        </>
      ))}
    </div>
  );
};

Accordians.propTypes = {
  controller: PropTypes.shape({
    map: PropTypes.func,
  }),
  expandAll: PropTypes.bool,
  toggleExpandAllOff: PropTypes.func,
};

export default Accordians;

Accordians.defaultProps = {
  ...defaultProps,
};
