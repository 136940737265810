import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./reducers/auth";
import { subjectReducer } from "./reducers/subject";
import { userReducer } from "./reducers/user";
import { learningResourcesHighlightReducer } from "./reducers/learningResourcesHighlight";
import { myLibraryHighlightReducer } from "./reducers/myLibraryHighlight";
import { commentsInLocalStorageReducer } from "./reducers/commentsInLocalStorage";
import { breadCrumbStatsReducer } from "./reducers/breadCrumbStats";
import { learningResourcesNotesListControllerReducer } from "./reducers/learningResourcesNotesListController";
import { learningObjectivesCompletedReducer } from "./reducers/learningObjectivesCompleted";
import { myLibraryReducer } from "./reducers/myLibrary";
import { courseReducer } from "./reducers/course";
import { notesDropdownReducer } from "./reducers/notesDropdown";
import { notesSnackDropdownReducer } from "./reducers/notesSnackDropdown";
import { CommentResourcesHighlightReducer } from "./reducers/commentResourceHiglight";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { assessmentReducer } from "./reducers/assessment";
import { dashboardReducer } from "./reducers/dashboard";

const persistConfig = {
  key: "root",
  storage,
};

const persistConfig2 = {
  key: "notesSnackPersisted",
  storage,
};

const persistConfig3 = {
  key: "coursePersist",
  storage,
};

const persistedReducer = persistReducer(persistConfig, notesDropdownReducer);
const notesSnackPersisted = persistReducer(
  persistConfig2,
  notesSnackDropdownReducer
);
const coursePersist = persistReducer(persistConfig3, courseReducer);

export const store = configureStore({
  reducer: {
    auth: authReducer,
    subject: subjectReducer,
    user: userReducer,
    notesDropdown: persistedReducer,
    notesSnack: notesSnackPersisted,
    commentResources: CommentResourcesHighlightReducer,
    learningResources: learningResourcesHighlightReducer,
    myLibraryHighlight: myLibraryHighlightReducer,
    commentsInLocalStorage: commentsInLocalStorageReducer,
    breadCrumbStats: breadCrumbStatsReducer,
    assessment: assessmentReducer,
    learningObjectivesCompleted: learningObjectivesCompletedReducer,
    learningResourcesNotesListController:
      learningResourcesNotesListControllerReducer,

    myLibrary: myLibraryReducer,
    dashboard: dashboardReducer,
    course: coursePersist,
  },
});
export const persistor = persistStore(store);
