import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isHighlighting: false,
  isFromIcons:false
};

const learningResourcesHighlight = createSlice({
  name: "learningResourcesHighlight",
  initialState,
  reducers: {
    toggleHighlightState: (state) => {
      state.isHighlighting = !state.isHighlighting;
      state.isFromIcons=false;
      return state;
    },
    highlightHandler: (state, action) => {
      state.isHighlighting = action.payload.isHighlighting;
      state.isFromIcons=action.payload.isFromIcons;
      return state;
    },
  },
});

export const learningResourcesHighlightActions =
  learningResourcesHighlight.actions;

export const learningResourcesHighlightReducer =
  learningResourcesHighlight.reducer;
