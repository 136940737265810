const controller = [
  {
    heading: "first",
    description: "default",
    content: <>no content provided</>,
  },
  {
    heading: "second",
    description: "default",
    content: <>no content provided</>,
  },
];
const obj = { controller };

export default obj;
