import React from 'react';

export default function BotSvg({ focused, isSidebar }) {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4353 18.8345V7.61458C24.4353 5.91171 23.0531 4.53125 21.3502 4.53125H7.65007C5.94719 4.53125 4.56494 5.91171 4.56494 7.61458V18.8345C4.56494 20.5374 5.9454 21.9178 7.64828 21.9178H9.53253V24.4041C9.53253 25.3198 10.6396 25.7784 11.2871 25.1309L13.7726 22.6453C14.2384 22.1795 14.8702 21.9178 15.5289 21.9178H21.352C23.0549 21.9178 24.4353 20.5374 24.4353 18.8345Z"
        stroke={focused ? 'white' : isSidebar ? '#ADB4C5' : 'white'}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.016 11.9844H9.53223"
        stroke={focused ? 'white' : isSidebar ? '#ADB4C5' : 'white'}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.4677 11.9844H16.9839"
        stroke={focused ? 'white' : isSidebar ? '#ADB4C5' : 'white'}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.0277 16.9531L13.9722 16.9531"
        stroke={focused ? 'white' : isSidebar ? '#ADB4C5' : 'white'}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.32275 16.0017L3.32275 11.8906"
        stroke={focused ? 'white' : isSidebar ? '#ADB4C5' : 'white'}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.6772 16.0017L25.6772 11.8906"
        stroke={focused ? 'white' : isSidebar ? '#ADB4C5' : 'white'}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
