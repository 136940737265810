import React from "react";
import capitalize from "../../../../../../helpers/capitalize";
import RightBarAccordian from "../../../../../UI/RightBarAccordian/RightBarAccordian";
import AccordionContent from "./AccordionContent";
import ErrorBoundary from "./../../../../../../Errors/ErrorBoundary";

const RenderAccordionData = ({ getNotes, deleteSingleNote, content }) => {
  const [controller, setController] = React.useState([]);
  const [activeIndex, setActiveIndex] = React.useState(null);

  const buildAccordionController = () => {
    let temp = content.map((each, index) => ({
      title: capitalize(each.tagName),
      ...each,
      content: (
        <AccordionContent
          getNotes={getNotes}
          {...each}
          deleteSingleNote={deleteSingleNote}
          index={index}
          setActiveIndex={setActiveIndex}
          activeIndex={activeIndex}
        />
      ),
    }));
    setController(temp);
  };
  React.useEffect(() => {
    buildAccordionController();
  }, [content]);

  return (
    <div>
      <ErrorBoundary>
        {controller.length > 0 ? (
          <RightBarAccordian controller={controller} isOctiLibrary={false} />
        ) : null}
      </ErrorBoundary>
    </div>
  );
};

export default RenderAccordionData;
