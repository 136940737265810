import { useEffect, useState } from "react";

// Custom hook for calculating estimated completion time
const useEstimatedCompletionTime = (
  startTime,
  totalDuration,
  dependencies = []
) => {
  const [estimatedCompletionTime, setEstimatedCompletionTime] = useState(null);

  useEffect(() => {
    const timeElapsed = startTime ? Date.now() - startTime : 0;

    if (timeElapsed < totalDuration) {
      const remainingTimeSeconds = Math.ceil(
        (totalDuration - timeElapsed) / 1000
      );
      setEstimatedCompletionTime(remainingTimeSeconds + "s");
    }
  }, [startTime, totalDuration, ...dependencies]);

  return estimatedCompletionTime;
};

export default useEstimatedCompletionTime;
