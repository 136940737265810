import React from "react";

const CrossSvg = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.59151 0.413208L6.19953 3.80519C6.08934 3.91538 5.91066 3.91538 5.80047 3.80519L2.40849 0.413208C1.85755 -0.137736 0.964151 -0.137736 0.413208 0.413208C-0.137736 0.964151 -0.137736 1.85755 0.413208 2.40849L3.80519 5.80047C3.91538 5.91066 3.91538 6.08934 3.80519 6.19953L0.413208 9.59151C-0.137736 10.1425 -0.137736 11.0358 0.413208 11.5868C0.964151 12.1377 1.85755 12.1377 2.40849 11.5868L5.80047 8.19481C5.91066 8.08462 6.08934 8.08462 6.19953 8.19481L9.59151 11.5868C10.1425 12.1377 11.0358 12.1377 11.5868 11.5868C12.1377 11.0358 12.1377 10.1425 11.5868 9.59151L8.19481 6.19953C8.08462 6.08934 8.08462 5.91066 8.19481 5.80047L11.5868 2.40849C12.1377 1.85755 12.1377 0.964151 11.5868 0.413208C11.0358 -0.137736 10.1425 -0.137736 9.59151 0.413208Z"
        fill="white"
      />
    </svg>
  );
};

export default CrossSvg;
