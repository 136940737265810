import { styled } from "@mui/system";

export const MobileCourseImageCWrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
  margin: "10px 10px",
  // marginBottom:'85px',
  display: "flex",
  justifyContent: "center",
}));

export const MobileCourseImageC = styled("img")(({ theme, height }) => ({
  width: "75%",
  height: height ? height : "150px",
  objectFit: "cover",
}));

export const MobileCourseWelcomeWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  marginTop: "17px",
}));

export const MobileCourseWelcomeHeading = styled("div")(({ theme }) => ({
  color: "var(--black, #11263C)",
  textAlign: "center",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Inter",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
  },
}));
export const MobileCourseWelcomedetails = styled("div")(({ theme }) => ({
  marginTop: "3px",
  color: "#11263C",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    color: "#AAA5B1",
  },
}));

export const MobileCourseWelcomeArrowDown = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "10px",
}));

export const MobileScreenNotesDropUp = styled("div")(({ theme, open }) => ({
  zIndex: 100,
  position: "fixed",
  bottom: "0",
  top: open ? "30%" : "",
  display: "block",
  width: "99%",
  height: "auto",
  minHeight: open ? "70vh" : "auto",
  background: "#FFF",
  filter: "drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.06))",
  padding: "20px 0px",
  transition: "top 5s ease, height 5s ease", // Adjust the duration as needed
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

export const MobileScreenNotesDropUpOval = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: "50%",
  transform: "translateX(-50%)",
  width: "75px",
  height: "40px",
  background: "#fff",
  borderRadius: "100px 100px 0 0",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  top: "-29px",
  //Todo  add Filter
  // fill: '#FFF',
  // filter:'drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.06))'
}));

export const MobileScreenNotesIcon = styled("div")(({ theme }) => ({
  marginTop: "6px",
}));

export const MobileScreenNotesSelectionText = styled("div")(
  ({ theme, show }) => ({
    display: show ? "none" : "block",
    textAlign: "center",
    color: "var(--black, #11263C)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "180%",
  })
);
export const MobileScreenUnitName = styled("span")(
  ({ theme, show, isactive }) => ({
    color: isactive ? "#624BA2" : "#11263C",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "180%",
  })
);
export const MobileScreenSelectedWrapper = styled("div")(({ theme, show }) => ({
  marginLeft: "20px",
}));
