import { styled } from '@mui/system';
import PaperComp from '../../../UI/Paper/Paper';

export const AppContent = styled(PaperComp)(
  ({ appbarheight, drawerwidth, isXs }) => ({
    paddingTop: appbarheight,
    paddingLeft: isXs ? '0' : drawerwidth,
    minHeight: `calc(100vh - ${appbarheight})`,
  })
);

export const Main = styled('main')(({}) => ({}));
