import React from "react";
import Typography from "././../../../../../UI/Typography/TypographyCompo";
import Loader from "././../../../../../UI/Loader/Loader";
import Stack from "././../../../../../UI/Stack/Stack";
import { styled } from "@mui/system";
import NoteContent from "./NoteContent";

const spacing = "17px 24px";

const Wrapper = styled(Stack)(({ theme }) => ({}));

const HeaderContainer = styled(Stack)(({ theme }) => ({
  padding: spacing,
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const NotesContent = ({
  subjectName,
  handleResetNotes,
  getNotes,
  deleteSingleNote,
  selectedFromList,
  handleDelete,
  notes,
  course,
  recieveEditsHandler,
  showNotes,
  unit,
}) => {
  const [datalength, setDataLength] = React.useState([]);
  return (
    <React.Fragment>
      <Wrapper>
        {showNotes ? (
          <Loader />
        ) : (
          <NoteContent
            subjectName={subjectName}
            handleResetNotes={handleResetNotes}
            handleDelete={handleDelete}
            getNotes={getNotes}
            deleteSingleNote={deleteSingleNote}
            selectedFromList={selectedFromList}
            notes={notes}
            course={course}
            unit={unit}
            setDataLength={setDataLength}
            recieveEditsHandler={recieveEditsHandler}
          />
        )}
      </Wrapper>
    </React.Fragment>
  );
};

export default NotesContent;
