import React from "react";
import BreadCrumb from "../../../../BreadCrumb";
import Header from "../../../../Headers/LearningResources/Syllabus/Syllabus";
import SyllabusAccordions from "./SyllabusAccordions";
import { Container } from "@mui/material";
const Syllabus = () => {
  const componentRef = React.createRef();

  return (
    <Container sx={{padding:{xs:'25px',sm:'0px'},minWidth:"100%"}}>
      <BreadCrumb />
      <Header />
      <SyllabusAccordions ref={componentRef} />
    </Container>
  );
};

export default Syllabus;
