import React from "react";

const AnalaticsSvg = ({ color, isMobile, isSelected }) => {
  return (
    <div style={{ marginRight: "0.5rem", marginTop: "2px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.00008 12.8337C10.2217 12.8337 12.8334 10.222 12.8334 7.00033H7.00008V1.16699C3.77842 1.16699 1.16675 3.77866 1.16675 7.00033C1.16675 10.222 3.77842 12.8337 7.00008 12.8337Z"
          stroke={color}
          stroke-opacity="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.94434 5.05583V1.5C10.6046 2.08938 11.9108 3.3956 12.5002 5.05583H8.94434Z"
          stroke={color}
          stroke-opacity="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default AnalaticsSvg;
