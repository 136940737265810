import ReactDOM from "react-dom";

const handleCloseBox = (id) => {
  ReactDOM.unmountComponentAtNode(document.getElementById(id));
  return;
};

const closeALLComments = (comments) => {
  comments?.map((comment) => {
    const found = document.getElementById(comment.commentId);

    const child = found ? found?.firstChild : null;

    if (child) {
      ReactDOM.unmountComponentAtNode(
        document.getElementById(comment.commentId)
      );
    }
  });
};

export { handleCloseBox, closeALLComments };
