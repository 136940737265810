import React, { useState, useCallback, useEffect } from 'react';
import FlashCardHeader from '../../../../Headers/LearningResources/FlashCard/FlashCard';
import LeftSide from '../../../../LeftSideBar/LeftSide';
import GridComp from '../../../../../../UI/Grid/Grid';
import CardComp from '../../../../../../UI/Card/Card';
import api from './../../../../../../../Services';
import { Box, useMediaQuery } from '@mui/material';
import FlashCardOpen from './../../../../FlashcardOpen/FlashcardOpen';
import Loader from '../../../../../../UI/Loader/Loader';
import { useSnackbar } from 'notistack';
import Alert from '../../../../../../UI/Alert/Alert';
import FlashcardHandler from './FlashcardHandler';
import errorFunctions from '../../../../../../../helpers/createResponses';
import ErrorBoundary from './../../../../../../../Errors/ErrorBoundary';
import debounce from 'lodash.debounce';
import useUserResponseAwait from './../../../../hooks/useUserResponseAwait.js';
import { isItArray } from '../../../../../../../helpers/checkifArray';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import BreadCrumb from '../../../../BreadCrumb.jsx';
import MobileBottomBar from '../../../../../../mobileBottomBar/MobileBottomBar.jsx';
import MobileCourseIndicator from '../../../../../../mobileBottomBar/MobileCourseIndicator.jsx';
import MobileDetector from '../../../../../../../helpers/mobileDetector.jsx';
import CourseInfo from '../../../../../../coursoInfo/courseInfo.jsx';
import { useSelector } from 'react-redux';

const FlashCards = () => {
  const isMobile = MobileDetector();
  const params = useParams();
  const { id: courseId } = params;
  const { awaitingUserResponse, toggleLoading } = useUserResponseAwait();
  const { createError, createLoading, initialError, initialLoading } =
    errorFunctions;
  const { enqueueSnackbar } = useSnackbar();
  const [allTags, setAllTags] = useState([]);
  const [baseView, setBaseView] = useState(true);
  const _1000px = useMediaQuery('(min-width: 1000px)');
  const _750px = useMediaQuery('(max-width: 750px)');
  const [selection, setSelection] = useState({});
  const [newSelection, setNewSelection] = useState(null);
  const [selectedTag, setSelectedTag] = useState({ title: 'all' });
  const [flashcards, setFlashcards] = useState([]);
  const [listHandle, setListHandle] = useState({});
  const [error, setError] = useState(initialError);
  const [loading, setLoading] = useState(initialLoading);
  const [selectedStack, setSelectedStack] = useState({});
  const [isInLibrary, setIsInLibrary] = useState(null);
  const [showFlashcard, setShowFlashcard] = useState(true);
  const [flashcardId, setFlashcardId] = useState(null);
  const [chapterOrSnackId, setChapterOrSnackId] = useState(null);
  const [getMenuStats, setGetMenuStats] = useState(false);
  const [flashcardStatus, setFlashcardStatus] = useState({});
  const [hierarchyData, setHeirarchyData] = React.useState(null);
  const [title, setTitle] = useState(null);
  const [breadCrumbFC, setBreadCrumbFC] = useState(null);
  const [stackId, setStackId] = useState(null);
  const [openMobileBar, setOpenMobileBar] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [activeItem, setActiveItem] = useState('');
  const [clickedStack, setClickedStack] = useState('');
  const [attemptedCards, setAttemptedCards] = useState([]);
  const [structuredData, setStructuredData] = useState([]);
  const [completedFlashcards, setCompletedFlashcards] = useState([]);
  const user = useSelector((state) => state.user);
  const course = useSelector((state) => state.course);
  const [updatedHierarchy, setUpdatedHierarchy] = useState(null);
  const [completedFC, setCompletedFC] = useState(null);
  const [allFC, setAllFC] = useState(null);

  const handleOpenMobileBar = () => {
    setOpenMobileBar(!openMobileBar);
  };

  const getFlashcardsFromApi = async ({ id, type, token }) => {
    let temp = await api.getFlashcards(id, type, token);
    return temp;
  };
  function extractNumericPart(str) {
    if (!str) return Infinity;
    return parseInt(str.substring(1)) || Infinity;
  }

  const handleFlashcardCall = (payload, criteria) => {
    const { id, type } = payload;
    const token = localStorage.getItem('auth');
    getFlashcardsFromApi({ id, type, token }).then((el) => {
      if (!el.error) {
        const { doneFlashcards, status } = el.data;
        const statusObj = { doneFlashcards: doneFlashcards, status: status };
        setAttemptedCards(doneFlashcards);

        const isQuestionsAnArray = isItArray(el.data.questions);
        setFlashcardStatus(statusObj);

        if (isQuestionsAnArray) {
          if (el.data.questions.length > 0) {
            setError(initialError);
            setIsInLibrary(el.data.isInLibrary);
            const data = el.data.questions;
            let temp = [...data];

            if (criteria && criteria.title !== 'all') {
              temp = data.filter((each) => each.tagId === criteria.id);
            }
            if (temp.length === 0) {
              setError(
                createError(
                  true,
                  'info',
                  'No Question Pair against this selection!'
                )
              );
            }
            setStackId(el?.data?.stackId);
            setFlashcards(temp);
            setSelection(payload);
            setBaseView(true);
            setLoading(initialLoading);
          } else {
            setLoading(false, '');
            setError(
              createError(
                true,
                'No flashcard found against this selection!',
                'info'
              )
            );
          }
        } else {
          const flashcardArray = Object.values(el?.data.questions)[0];
          if (flashcardArray !== undefined) {
            setError(initialError);
            setIsInLibrary(el.data.isInLibrary);
            let temp = [...flashcardArray];
            if (criteria && criteria.title !== 'all') {
              temp = flashcardArray.filter(
                (each) => each.tagId === criteria.id
              );
            } else {
            }
            if (temp.length === 0) {
              setError(
                createError(
                  true,
                  'info',
                  'No Question Pair against this selection!'
                )
              );
            }

            setFlashcards(temp);
            setSelection(payload);
            setBaseView(true);
          } else {
            setError(
              createError(
                true,
                'No flashcard found against this selection!',
                'info'
              )
            );
          }
        }
      } else {
        setError(createError(true, 'Unknown error occured!', 'error'));
      }
    });
  };

  const handleExpansion = (item, structure) => {
    switch (item.type) {
      case 'unit':
        const updatedData = structure.map((unit) => {
          if (unit.id === item.id) {
            return {
              ...unit,
              isExpanded: !unit.isExpanded,
              nested: unit.childrenElements,
            };
          }
          return { ...unit, isExpanded: false, nested: unit.childrenElements };
        });
        return updatedData;
        break;

      case 'chapter':
        if (item.unitId) {
          let updatedData = structure.map((unit) => {
            if (unit.id === item.unitId) {
              const modifiedChildren = unit.childrenElements.map((element) => {
                if (element.id === item.id) {
                  return {
                    ...element,
                    isExpanded: !element.isExpanded,
                    nested: element.childrenElements,
                  };
                }
                return {
                  ...element,
                  isExpanded: false,
                  nested: element.childrenElements,
                };
              });
              return {
                ...unit,
                childrenElements: modifiedChildren,
                nested: modifiedChildren,
              };
            }

            return unit;
          });
          return updatedData;
        } else {
          let updatedData = structure.map((chapter) => {
            if (chapter.id === item.id) {
              return {
                ...chapter,
                isExpanded: !chapter.isExpanded,
                nested: chapter.childrenElements,
              };
            }
            return {
              ...chapter,
              isExpanded: false,
              nested: chapter.childrenElements,
            };
          });
          return updatedData;
        }
        break;

      case 'snack':
        const updatedDataSnack = structure.map((unit) => {
          if (unit.id === item.unitId) {
            let modifiedUnitsChildren = unit.childrenElements.map((element) => {
              if (element.id === item.chapterId) {
                let modifiedSnacksChildren = element.childrenElements.map(
                  (snackElements) => {
                    if (snackElements.id === item.id) {
                      return {
                        ...snackElements,
                        isExpanded: !snackElements.isExpanded,
                        nested: snackElements.childrenElements,
                      };
                    }
                    return snackElements;
                  }
                );
                return {
                  ...element,
                  childrenElements: modifiedSnacksChildren,
                  nested: modifiedSnacksChildren,
                };
              }
              return element;
            });
            return {
              ...unit,
              childrenElements: modifiedUnitsChildren,
              nested: modifiedUnitsChildren,
            };
          }

          return unit;
        });
        return updatedDataSnack;
      default:
        console.log('Unknown item type');
    }
  };

  function handleSelection(e) {
    const flashcard = handleExpansion(e, structuredData);
    const flashcardGlobal = {
      data: flashcard,
      tab: 'flashcards',
      selection: e,
    };
    localStorage.setItem(
      'flashcardsGlobalState',
      JSON.stringify(flashcardGlobal)
    );
    handleSelectedData(e);
  }

  function handleSelectedData(e) {
    if (e.type === 'unit') {
      setSelectedData({
        ...e,
        unitname: e.name,
        type: 'unit',
        active: 'unit',
      });
    } else if (e.type === 'chapter') {
      setSelectedData((prevData) => ({
        ...prevData,
        ...e,
        chaptername: e.name,
        type: 'chapter',
        active: 'chapter',
      }));
    } else {
      setSelectedData((prevData) => ({
        ...prevData,
        ...e,
        snackname: e.name,
        type: 'snack',
        active: 'snack',
      }));
    }
  }
  const listItemClickHandler = (selectedListItem, isStorage = false) => {
    if (isStorage) {
      handleSelectedData(selectedListItem);
      setActiveItem(selectedListItem.label);
      setNewSelection(selectedListItem);
      if (selectedListItem.type === 'chapter' && !selectedListItem.nested) {
        setChapterOrSnackId(selectedListItem.id);
      } else if (selectedListItem.type === 'snack') {
        setChapterOrSnackId(selectedListItem.id);
      }
      setGetMenuStats(false);
      setShowFlashcard(false);
      setListHandle({ listSelection: selectedListItem });
      if (selectedListItem.isExpanded) {
        setLoading(createLoading(true, 'list-click'));
        handleFlashcardCall(selectedListItem, selectedTag);
        setTitle(selectedListItem);
      }

      if (selectedListItem.layer === 2 && !selectedListItem.isExpanded) {
        setLoading(createLoading(true, 'list-click'));

        const firstLevelData = structuredData?.filter((unit) => {
          return unit.id === selectedListItem.unitId;
        });
        firstLevelData[0].nested = firstLevelData[0]?.childrenElements;
        handleFlashcardCall(firstLevelData[0], selectedTag);
        setTitle(firstLevelData[0]);
      }
    } else {
      setActiveItem(selectedListItem.label);
      handleSelectedData(selectedListItem);
      setNewSelection(selectedListItem);
      handleSelection(selectedListItem);

      if (selectedListItem.type === 'chapter' && !selectedListItem.nested) {
        setChapterOrSnackId(selectedListItem.id);
      } else if (selectedListItem.type === 'snack') {
        setChapterOrSnackId(selectedListItem.id);
      }
      setGetMenuStats(false);
      setShowFlashcard(false);
      setListHandle({ listSelection: selectedListItem });
      if (selectedListItem.isExpanded) {
        setLoading(createLoading(true, 'list-click'));
        handleFlashcardCall(selectedListItem, selectedTag);
        setTitle(selectedListItem);
      }
      if (selectedListItem.layer === 2 && !selectedListItem.isExpanded) {
        setLoading(createLoading(true, 'list-click'));

        const firstLevelData = structuredData?.filter((unit) => {
          return unit.id === selectedListItem.unitId;
        });
        firstLevelData[0].nested = firstLevelData[0]?.childrenElements;
        handleFlashcardCall(firstLevelData[0], selectedTag);
        setTitle(firstLevelData[0]);
      }
    }
  };
  const handleTagSelection = (_, item) => {
    handleFlashcardCall(selection, item);
    setLoading(createLoading(true, 'tag-click'));
    setSelectedTag(item);
  };

  const flashcardSelectionHandler = (e) => {
    const stackSelection = {
      stack: selection,
      questions: e.specificFlashcards,
      userSelection: e,
    };
    setSelectedStack(stackSelection);
    setBaseView(false);
    setBreadCrumbFC(e?.specificFlashcards);
    setClickedStack(e?.name);
  };

  const sideEffectHandler = useCallback(
    debounce((response, id) => {
      flashCardButtonApiHandler(response, id);
    }, 2000),
    []
  );

  const flashCardIdSideEffect = (responseId) => {
    setFlashcardId(responseId);
  };
  const flashCardButtonApiCall = async ({
    contentId,
    response,
    flashcardId,
  }) => {
    let token = localStorage.getItem('auth');

    let temp = api.postFlashcardButtonStatus(
      token,
      contentId,
      response,
      flashcardId
    );
    return temp;
  };
  const flashCardButtonApiHandler = (response, id) => {
    flashCardButtonApiCall({
      response,
      flashcardId,
      contentId: id,
    })
      .then((el) => {
        if (!el.error) {
        } else {
          enqueueSnackbar(el.response, {
            variant: 'error',
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: 'error',
        });
      });

    toggleLoading(false);
  };

  const getMenuState = (getMenu) => {
    setLoading(createLoading(true, 'list-click'));

    // changes done here
    handleFlashcardCall(
      { id: selection.id, type: selection.type, name: selection.name },
      selectedTag
    );
    if (getMenu === true) {
      fetchNotesHeirarchy();
    }
  };

  const fetchNotesHeirarchy = async () => {
    let token = localStorage.getItem('auth');
    await api
      .getHierarchyForFlashCards(token, courseId)
      .then((resp) => setHeirarchyData(resp))
      .catch((err) => console.error(err));
  };

  const handleSetStackId = (stackId) => {
    setStackId(stackId);
  };

  React.useEffect(() => {
    fetchNotesHeirarchy();
  }, []);

  useEffect(() => {
    if (baseView) {
      handleFlashcardCall(
        { id: selection.id, type: selection.type, name: selection.name },
        selectedTag
      );
    }
  }, [baseView]);

  const left = _1000px ? 3 : _750px ? 12 : 4;
  const right = _1000px ? 9 : _750px ? 12 : 8;

  const uniqueId =
    title?.type === 'unit'
      ? [...new Set(flashcards?.map((obj) => obj?.chapterId))]
      : title?.type === 'snack'
      ? [...new Set(flashcards?.map((obj) => obj.tagId))]
      : [...new Set(flashcards?.map((obj) => obj.snackId))];

  const uniqueFlashCards = uniqueId?.map((id) => {
    if (title?.nested) {
      return title?.nested?.find((obj) => obj?.id === id);
    } else {
      return title;
    }
  });

  useEffect(() => {
    if (selectedData?.layer === 1 && selectedData?.isExpanded === false) {
      setShowFlashcard(true);
    }
  }, [selectedData]);

  // Handle Saved Cards

  const uniqueSavedCards =
    title?.type === 'unit'
      ? [...new Set(isInLibrary && isInLibrary?.map((obj) => obj?.chapterId))]
      : title?.type === 'snack'
      ? [...new Set(isInLibrary && isInLibrary?.map((obj) => obj.tagId))]
      : [...new Set(isInLibrary && isInLibrary?.map((obj) => obj.snackId))];

  const getUserCompletedFC = async (payload) => {
    let token = localStorage.getItem('auth');

    let temp = api.getUserCompleteFC(payload, token).then((res) => {
      if (res?.error) {
        return console.log('error');
      }

      setCompletedFC(res?.data);
    });
    return temp;
  };

  const getAllFlashcards = async (payload) => {
    let token = localStorage.getItem('auth');

    let temp = api.getAllFC(payload, token).then((res) => {
      if (res?.error) {
        return console.log('error');
      }

      setAllFC(res?.data);
    });
    return temp;
  };

  useEffect(() => {
    let payload;

    if (hierarchyData) {
      if (hierarchyData?.isUnitsAvailable === true) {
        const unitIds = hierarchyData.data.map((item) => item.id);

        payload = {
          courseId,
          unitId: unitIds,
          userId: user?.id,
        };

        getUserCompletedFC(payload);
        getAllFlashcards(payload);
      } else {
        const data = [hierarchyData?.data];

        const chapterIds = data[0]?.chapters?.map((item) => item?.id);

        if (chapterIds) {
          payload = {
            courseId,
            chapterId: chapterIds,
            userId: user?.id,
          };

          getUserCompletedFC(payload);
          getAllFlashcards(payload);
        }
      }
    }
  }, [hierarchyData]);

  function groupFlashcardsBySnack(flashcards) {
    if (flashcards)
      return flashcards.reduce((acc, flashcard) => {
        const snackId = flashcard.snackId;
        if (!acc[snackId]) {
          acc[snackId] = [];
        }
        acc[snackId].push(flashcard);
        return acc;
      }, {});
  }

  function calculateCompletionPercentage(
    hierarchyData,
    flashcards,
    completedFC
  ) {
    if (!hierarchyData?.data || !flashcards || !completedFC)
      return hierarchyData;

    // Group flashcards by snackId
    const flashcardsBySnack = groupFlashcardsBySnack(flashcards);

    if (flashcardsBySnack) {
      const completedFlashcards = new Set(completedFC.map((fc) => fc.fcId));

      if (hierarchyData?.isUnitsAvailable) {
        hierarchyData?.data.forEach((unit) => {
          unit.chapters.forEach((chapter) => {
            chapter.snacks.forEach((snack) => {
              const snackFlashcards = flashcardsBySnack[snack.id] || [];
              const totalFlashcards = snackFlashcards.length;

              const completedFlashcardsCount = snackFlashcards.filter((fc) =>
                completedFlashcards.has(fc.id)
              ).length;

              // Calculate the completion percentage for each snack
              snack.completionPercentage = totalFlashcards
                ? Math.floor((completedFlashcardsCount / totalFlashcards) * 100)
                : 0;
            });

            // Calculate chapter completion
            const totalSnacks = chapter.snacks.length || 0;
            const totalSnackCompletion = chapter.snacks.reduce(
              (acc, snack) => acc + snack.completionPercentage,
              0
            );
            chapter.completionPercentage = totalSnacks
              ? Math.floor(totalSnackCompletion / totalSnacks)
              : 0;
          });

          // Calculate unit completion

          const totalChapters = unit.chapters.length || 0;
          const totalChapterCompletion = unit.chapters.reduce(
            (acc, chapter) => acc + chapter.completionPercentage,
            0
          );
          unit.completionPercentage = totalChapters
            ? Math.floor(totalChapterCompletion / totalChapters)
            : 0;
        });

        return hierarchyData;
      } else {
        const data = [hierarchyData?.data];

        data.forEach((unit) => {
          unit.chapters.forEach((chapter) => {
            chapter.snacks.forEach((snack) => {
              const snackFlashcards = flashcardsBySnack[snack.id] || [];
              const totalFlashcards = snackFlashcards.length;

              const completedFlashcardsCount = snackFlashcards.filter((fc) =>
                completedFlashcards.has(fc.id)
              ).length;

              // Calculate the completion percentage for each snack
              snack.completionPercentage = totalFlashcards
                ? Math.floor((completedFlashcardsCount / totalFlashcards) * 100)
                : 0;
            });

            // Calculate chapter completion
            const totalSnacks = chapter.snacks.length || 0;
            const totalSnackCompletion = chapter.snacks.reduce(
              (acc, snack) => acc + snack.completionPercentage,
              0
            );
            chapter.completionPercentage = totalSnacks
              ? Math.floor(totalSnackCompletion / totalSnacks)
              : 0;
          });
        });

        return hierarchyData;
      }
    }
  }

  useEffect(() => {
    if (completedFC && allFC) {
      const updatedHierarchy = calculateCompletionPercentage(
        hierarchyData,
        allFC,
        completedFC
      );

      setUpdatedHierarchy(updatedHierarchy);
    }
  }, [completedFC, allFC, hierarchyData]);

  return (
    <>
      <Box
        sx={{
          padding: { xs: '25px 30px', md: '0px' },
          paddingBottom: { xs: '100px', sm: '0px' },
        }}
      >
        <GridComp item xs={12}>
          <BreadCrumb
            baseView={baseView}
            setBaseView={setBaseView}
            flashcards={breadCrumbFC}
            showStack={true}
            isFlashcard={true}
            handleSelection={selectedData}
          />
        </GridComp>

        <GridComp item container spacing={{ xs: 0, sm: 4 }}>
          <div style={{ marginLeft: '25px', marginTop: '30px', width: '100%' }}>
            <FlashCardHeader
              activeTag={selectedTag}
              allTags={allTags}
              setAllTags={setAllTags}
              baseView={baseView}
              left={left}
              right={right}
              handleSelection={handleTagSelection}
              flashcards={flashcards}
              stackName={clickedStack}
            />
          </div>
          <GridComp item container xs={12} sx={{ padding: '0px 20px' }}>
            {showFlashcard && (
              <MobileCourseIndicator
                courseId={courseId}
                tab={'Flash Cards'}
                welcomeDetails="Please select a unit, chapter or snack to view Flash Cards."
              />
            )}
          </GridComp>
          <GridComp item xs={left} sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <CardComp nopadding={true}>
              <LeftSide
                selectedFromList={selection}
                onListClick={(e, i) => {
                  listItemClickHandler(e, i);
                }}
                getMenuStats={getMenuStats}
                hierarchyData={updatedHierarchy}
                isLibrary={false}
                isFlashcard={true}
                isSimulation={false}
                selectedData={selectedData}
                completedFC={completedFC}
                setSelectedData={setSelectedData}
                setStructuredData={setStructuredData}
                tab={'flashcards'}
              />
            </CardComp>
          </GridComp>
          <GridComp item xs={right}>
            <ErrorBoundary>
              {showFlashcard ? (
                <>
                  {' '}
                  {isMobile ? (
                    ''
                  ) : (
                    <CourseInfo courseId={courseId} tab="Flashcards" />
                  )}{' '}
                </>
              ) : (
                <>
                  {!loading.value ? (
                    <>
                      {!error.value ? (
                        <>
                          {baseView ? (
                            <>
                              <Grid container spacing={{ xs: 0, sm: 5 }}>
                                {flashcards?.length > 0 && (
                                  <>
                                    {uniqueFlashCards?.map((item, index) => {
                                      const specificFlashcards =
                                        title?.type === 'unit'
                                          ? flashcards?.filter((flashcard) => {
                                              return (
                                                flashcard?.chapterId ===
                                                item?.id
                                              );
                                            })
                                          : flashcards?.filter((flashcard) => {
                                              return (
                                                flashcard?.snackId === item?.id
                                              );
                                            });

                                      const doneFlashCards =
                                        specificFlashcards.filter((item) => {
                                          return flashcardStatus?.doneFlashcards?.some(
                                            (doneFC) =>
                                              doneFC?.fcId === item?.id
                                          );
                                        });

                                      // For Snack Only

                                      const snackFC =
                                        title?.type === 'snack' &&
                                        uniqueId?.map((id) =>
                                          specificFlashcards?.filter(
                                            (item) => item?.tagId === id
                                          )
                                        );

                                      const snackSpecificTag =
                                        title?.type === 'snack' &&
                                        selectedTag?.title === 'all' &&
                                        uniqueId?.map((id) => {
                                          return flashcards?.find(
                                            (item) => item?.tagId === id
                                          );
                                        });

                                      const snackDoneFC =
                                        title?.type === 'snack' &&
                                        uniqueId?.map((id) => {
                                          return flashcardStatus?.doneFlashcards?.filter(
                                            (item) => item?.tagId === id
                                          );
                                        });

                                      const snackSpecificTagTitle =
                                        title?.type === 'snack' &&
                                        uniqueId?.map((id) => {
                                          return flashcards?.find(
                                            (item) => item?.tagId === id
                                          );
                                        });

                                      const snackId = uniqueId[index];

                                      const isSaved =
                                        title?.type === 'unit'
                                          ? uniqueSavedCards?.find((id) => {
                                              return id === item?.id;
                                            })
                                          : title?.type === 'chapter'
                                          ? uniqueSavedCards?.filter((id) => {
                                              return id === item?.id;
                                            })
                                          : uniqueSavedCards?.filter((id) => {
                                              return (
                                                snackSpecificTagTitle &&
                                                snackSpecificTagTitle?.some(
                                                  (fc) => fc?.tagId === id
                                                )
                                              );
                                            });

                                      const completedFC =
                                        completedFlashcards?.find((fc) => {
                                          return fc?.snackId === item?.id;
                                        });

                                      return (
                                        <>
                                          <Grid item xs={12} md={6}>
                                            <FlashcardHandler
                                              stackId={stackId}
                                              handleSetStackId={
                                                handleSetStackId
                                              }
                                              setCompletedFlashcards={
                                                setCompletedFlashcards
                                              }
                                              flashcardStatus={{
                                                doneFlashcards: snackDoneFC
                                                  ? snackDoneFC[index]?.length
                                                  : doneFlashCards.length,
                                              }}
                                              course={listHandle}
                                              selection={selection}
                                              selectedTag={
                                                snackSpecificTag
                                                  ? snackSpecificTag[index]?.tag
                                                  : selectedTag
                                              }
                                              stacksArr={[
                                                snackFC
                                                  ? snackFC[index]
                                                  : specificFlashcards,
                                              ]}
                                              activeTag={selectedTag}
                                              show={false}
                                              isInLibrary={
                                                title?.type === 'unit'
                                                  ? isSaved === item?.id
                                                  : title?.type === 'chapter'
                                                  ? isSaved?.length
                                                  : isSaved?.find(
                                                      (tagId) =>
                                                        tagId ===
                                                        snackSpecificTagTitle[
                                                          index
                                                        ]?.tagId
                                                    )
                                              }
                                              clicked={
                                                flashcardSelectionHandler
                                              }
                                              _1000px={_1000px}
                                              title={
                                                snackSpecificTagTitle
                                                  ? snackSpecificTagTitle[index]
                                                      ?.tag?.title
                                                  : item?.name
                                              }
                                              specificFlashcards={
                                                snackFC
                                                  ? snackFC[index]
                                                  : specificFlashcards
                                              }
                                              heading={title?.label}
                                              snackId={snackId}
                                              chapterId={
                                                newSelection?.type ===
                                                  'chapter' && newSelection?.id
                                              }
                                              unitId={
                                                newSelection?.type ===
                                                  'chapter' &&
                                                newSelection?.unitId
                                              }
                                              snackTagId={
                                                snackSpecificTagTitle
                                                  ? snackSpecificTagTitle[index]
                                                      ?.tagId
                                                  : null
                                              }
                                            />
                                          </Grid>
                                        </>
                                      );
                                    })}
                                  </>
                                )}
                              </Grid>
                            </>
                          ) : (
                            <>
                              <FlashCardOpen
                                loadCards={handleFlashcardCall}
                                doneFlashCards={attemptedCards}
                                sideEffect={(e) => {
                                  toggleLoading(true);
                                  sideEffectHandler(e, chapterOrSnackId);
                                }}
                                awaitingUserResponse={awaitingUserResponse}
                                selectedStack={selectedStack}
                                flashCardIdSideEffect={flashCardIdSideEffect}
                                getMenuState={getMenuState}
                                loading={awaitingUserResponse?.loading}
                                setCompletedFC={setCompletedFC}
                                title={title}
                              />
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {newSelection.type === 'unit' && (
                            <Alert
                              title="No flashcard Stack for this unit"
                              severity="warning"
                            />
                          )}
                          {newSelection.type === 'chapter' && (
                            <Alert
                              title="No flashcard Stack for this chapter"
                              severity="warning"
                            />
                          )}
                          {newSelection.type === 'snack' && (
                            <Alert
                              title="No flashcard Stack for this snack"
                              severity="warning"
                            />
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <Loader />
                  )}
                </>
              )}
            </ErrorBoundary>
          </GridComp>
        </GridComp>
      </Box>
      <MobileBottomBar
        selectionText="Click here to get started "
        openMobileDropDown={openMobileBar}
        handleOpenMobileDropDown={handleOpenMobileBar}
        showData={showFlashcard}
        selectedData={selectedData}
        leftSideMenu={
          <LeftSide
            selectedFromList={selection}
            onListClick={(e, i) => {
              listItemClickHandler(e, i);
            }}
            getMenuStats={getMenuStats}
            hierarchyData={hierarchyData}
            isLibrary={false}
            isFlashcard={true}
            isSimulation={false}
            active={activeItem}
            handleOpenMobileDropDown={handleOpenMobileBar}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            setStructuredData={setStructuredData}
            tab={'flashcards'}
          />
        }
      />
    </>
  );
};

export default FlashCards;
