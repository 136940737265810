import React from "react";

function NewBackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
    >
      <path
        d="M12.3017 5.15224C12.6108 4.84308 12.6108 4.34182 12.3017 4.03266C11.9925 3.72349 11.4912 3.72349 11.1821 4.03266L6.43207 8.78266C6.13236 9.08236 6.12187 9.56495 6.40828 9.8774L10.7624 14.6274C11.0579 14.9497 11.5587 14.9715 11.881 14.676C12.2033 14.3806 12.2251 13.8798 11.9296 13.5575L8.08764 9.36626L12.3017 5.15224Z"
        fill="#624BA2"
      />
    </svg>
  );
}

export default NewBackIcon;
