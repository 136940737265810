import React, { Fragment } from 'react'

export default function AccordianMobileSvg({ open, click }) {

    return (
        <span onClick={click} style={{ cursor: 'pointer', marginTop: open ? '-10px' : '7px' }}>
            {open ?
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="2" viewBox="0 0 15 2" fill="none">
                    <path d="M14.1667 1.83178H0.83332C0.372715 1.83178 0 1.45881 0 0.9988C0 0.538791 0.372715 0.165817 0.83332 0.165817H14.1666C14.6273 0.165817 15 0.538791 15 0.9988C15 1.45881 14.6273 1.83178 14.1667 1.83178Z" fill="#E015A2" />
                </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                    <path d="M7.49738 15.5C7.03678 15.5 6.66406 15.1269 6.66406 14.6667V1.33333C6.66406 0.873131 7.03678 0.5 7.49738 0.5C7.95799 0.5 8.3307 0.873131 8.3307 1.33333V14.6667C8.3307 15.1269 7.95799 15.5 7.49738 15.5Z" fill="#E015A2" />
                    <path d="M14.1667 8.83177H0.83332C0.372715 8.83177 0 8.4588 0 7.99879C0 7.53878 0.372715 7.16581 0.83332 7.16581H14.1666C14.6273 7.16581 15 7.53878 15 7.99879C15 8.4588 14.6273 8.83177 14.1667 8.83177Z" fill="#E015A2" />
                </svg>
            }

        </span>
    )
}
