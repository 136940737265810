import { styled } from "@mui/material";

export const Div = styled("div")(({ theme }) => ({
  display: "block",
  minHeight: 353,
  width: "100%",
  boxShadow: "10px 10px 60px 0px rgba(173, 180, 197, 0.26)",
  borderRadius: 15,
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    boxShadow: "0px 4px 13px 0px rgba(0, 0, 0, 0.06)",
    minHeight: 180,
    background: "#ffff",
  },
}));

export const Image = styled("img")(({ theme }) => ({
  width: "100%",
  height: 200,
  objectFit: "cover",
  borderTopLeftRadius: 15,
  borderTopRightRadius: 15,
  boxShadow: "10px 10px 60px 0px rgba(173, 180, 197, 0.26)",
  [theme.breakpoints.down("sm")]: {
    marginLeft: "10px",
    borderRadius: "10px",
    maxWidth: 172,
    height: 156,
  },
}));

export const ContentWrapper = styled("div")(({ theme }) => ({
  padding: "18px 10px",
  display: "flex",
  flexDirection: "column",
  gap: 5,
}));

export const Level = styled("div")(({ theme }) => ({
  fontFamily: "Inter",
  color: "#624BA2",
  fontSize: 16,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
}));

export const Title = styled("div")(({ theme }) => ({
  fontFamily: "Inter",
  color: "#251038",
  fontWeight: 700,
  fontSize: 20,
  fontFeatureSettings: "'clig' off, 'liga' off",
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
  },
}));

export const ConceptTagWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: 8,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export const ConceptTag = styled("div")(({ theme }) => ({
  fontFamily: "Inter",
  color: "#ADB4C5",
  fontSize: 14,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontStyle: "italic",
  lineHeight: "180%",
  fontWeight: 400,
}));
