import React from "react";

const BreadCrumpSeparator = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <g clip-path="url(#clip0_5847_33063)">
        <g opacity="0.5">
          <path d="M9.09708 5.67074L3.55595 0.129616C3.37251 -0.0475488 3.08019 -0.0424622 2.90303 0.140979C2.73019 0.31993 2.73019 0.603615 2.90303 0.782538L8.1177 5.9972L2.90303 11.2119C2.72276 11.3922 2.72276 11.6845 2.90303 11.8648C3.08336 12.0451 3.37565 12.0451 3.55595 11.8648L9.09708 6.32366C9.27736 6.14333 9.27736 5.85104 9.09708 5.67074Z" fill="#251038" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5847_33063">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BreadCrumpSeparator;
