import { Box, styled } from "@mui/material";

export const MainContainer = styled(Box)(({ theme }) => ({
  borderRadius: "10px",
  background: " rgba(98, 75, 162, 0.18)",
  boxShadow: "0px 4px 13px 0px rgba(0, 0, 0, 0.06)",
  height: "400px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px 40px",
  flexDirection: "column",
}));
