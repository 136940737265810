import React, { useState } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "./../../../../../../UI/IconButton/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SetToOrigionalSvg from "./icons/SetToOrigionalSvg";
import DeleteSvg from "./icons/DeleteSvg";
import { MobileBox, TextSpan } from "./ThreeDotMenu.style";
import Modal from "./../../../../../../UI/Modal/Modal";
import ShareModal from "./Models/ShareModel/ShareModal";
import DeleteModal from "./Models/DeleteModal/DeleteModal";
import UndoNotes from "./Models/DeleteModal/UndoNotes";
import SetToOrigional from "./Models/SetToOrigionalModel/SetToOrigional";
import ThreeDotsSvg from "./icons/threedotsSvg";
import MobileModal from "../../../../../../mobileModal/mobileModal";
import MobileDetector from "../../../../../../../helpers/mobileDetector";
import MobileUndoModal from "../../../../../../mobileModal/mobileundoModal";
import MobileRestoreModal from "../../../../../../mobileModal/mobileRestoreModal";

export default function ThreeDotMenu({
  snack,
  chapterId,
  unitId,
  subjectName,
  handleResetNotes,
  handleDelete,
  snackId,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openShareModel, setOpenShareModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openOrigonalModel, setOpenOrigonalModel] = useState(false);
  const [openUndoModel, setOpenUndoModel] = useState(false);
  const isMobile = MobileDetector();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MobileBox style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '40px',
        height: '40px',
        background: Boolean(anchorEl)
          ? Boolean(anchorEl)
            ? '#624BA2'
            : '#E015A2'
          : '#E015A2',
        borderRadius: '100%' ,
        '&:hover': {
          backgroundColor: '#e0dbec!important', // Example hover background color
        },
      }} onClick={handleClick} sx={{ cursor: "pointer" }}>
        <ThreeDotsSvg hideFill={true} />
      </MobileBox>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          sx: {
            overflow: "visible",
            background: "#251038",
            borderRadius: "10px",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            ml: -4,
            mt: 2.5,

            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 20,
              width: 15,
              height: 15,
              bgcolor: "#251038",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem onClick={() => setOpenDeleteModel(true)}>
          <DeleteSvg /> <TextSpan>Delete</TextSpan>
        </MenuItem>
        <MenuItem onClick={() => setOpenOrigonalModel(true)}>
          <SetToOrigionalSvg /> <TextSpan>Restore</TextSpan>
        </MenuItem>
      </Menu>
      {isMobile ? (
        <>
          <MobileModal
            open={openDeleteModel}
            succes={() => {
              setOpenDeleteModel(false);
              setOpenUndoModel(true);
            }}
            reset={() => {
              setOpenDeleteModel(false);
            }}
            close={setOpenDeleteModel}
            isDelete={true}
            modaltext={
              "Are you sure you want to delete this snack from My Library?"
            }
          />
          <Modal open={openUndoModel} setOpen={setOpenUndoModel}>
            <MobileUndoModal
              snackId={snackId}
              snack={snack}
              subjectName={subjectName}
              handleDelete={handleDelete}
              open={openUndoModel}
              setOpen={setOpenUndoModel}
            />
          </Modal>
          <Modal open={openOrigonalModel} setOpen={setOpenOrigonalModel}>
            <MobileRestoreModal
              open={openOrigonalModel}
              snack={snack}
              chapterId={chapterId}
              unitId={unitId}
              snackId={snackId}
              handleResetNotes={handleResetNotes}
              setOpen={setOpenOrigonalModel}
            />
          </Modal>
        </>
      ) : (
        <>
          <Modal open={openDeleteModel} setOpen={setOpenDeleteModel}>
            <DeleteModal
              setOpen={setOpenDeleteModel}
              setOpenUndo={setOpenUndoModel}
            />
          </Modal>

          <Modal open={openUndoModel} setOpen={setOpenUndoModel}>
            <UndoNotes
              snackId={snackId}
              snack={snack}
              subjectName={subjectName}
              handleDelete={handleDelete}
              open={openUndoModel}
              setOpen={setOpenUndoModel}
            />
          </Modal>
          <Modal open={openOrigonalModel} setOpen={setOpenOrigonalModel}>
            <SetToOrigional
              snack={snack}
              chapterId={chapterId}
              unitId={unitId}
              snackId={snackId}
              handleResetNotes={handleResetNotes}
              setOpen={setOpenOrigonalModel}
            />
          </Modal>
        </>
      )}
      <Modal
        open={openShareModel}
        setOpen={setOpenShareModel}
        modelWidth="600px"
      >
        <ShareModal />
      </Modal>
    </>
  );
}
