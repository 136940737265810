import { styled } from "@mui/system";
import Button from "../../../../../UI/Button/ButtonComp";
import Stack from "../../../../../UI/Stack/Stack";
import palette from "../../../../../../config/palette";

export const StackWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    margin: "20px 0px",
    padding: theme.spacing(2),
    paddingRight: "0px",
    borderRadius: "12px",
  },
}));

export const HeaderButton = styled(Button)(({ theme, isActive }) => ({
  textTransform: "capitalize",
  fontFamily: theme.fontFamily,
  color: isActive ? "white" : palette.colors.unselected,
  cursor: "pointer",
  background: isActive ? palette.colors.primaryModified : "white",
  paddingTop: "0.2rem",
  paddingBottom: "0.2rem",
  height: "40px",
  fontSize: "1rem",
  // minWidth: "145px",
  "&:hover": {
    color: isActive ? "white" : "black",
    background: isActive
      ? palette.colors.primaryModified
      : palette.colors.selectedColor,
  },
  [theme.breakpoints.down("sm")]: {
    height: "60px",
    width: "155px",
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: "12px",
    fontFeatureSettings: "'clig' off, 'liga' off",
    color: isActive ? "white" : "#11263C",

    //  paddingTop:'1.2rem',
    //  paddingBottom:'1.2rem'
  },
}));

export const HeaderDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
export const TagsDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  overflowX: "auto",
}));

export const TagsButton = styled(Button)(({ theme, isselected }) => ({
  fontFamily: theme.fontFamily,
  textTransform: "capitalize",
  color: isselected ? "white" : palette.colors.unselected,
  backgroundColor: isselected
    ? palette.colors.primaryModified
    : palette.colors.lightColor,
  borderRadius: "50px",
  marginRight: "0.4rem",
  marginLeft: "0.4rem",
  paddingTop: "0.2rem",
  paddingBottom: "0.2rem",
  "&:hover": {
    color: "unselected",
    backgroundColor: isselected
      ? palette.colors.primaryModified
      : palette.colors.lightColor,
  },
  [theme.breakpoints.down("lg")]: {
    margin: theme.spacing(1),
  },
  minWidth: "max-content",
  width: "max-content",

  [theme.breakpoints.down("sm")]: {
    color: isselected ? "white" : "rgba(37, 16, 56, 0.70)",
    fontFamily: "Inter",
    fontSize: "10px",
    margin: "3px",
    padding: "3px 8px",
    fontWeight: "400",
    backgroundColor: isselected
      ? palette.colors.primaryModified
      : "rgba(173, 180, 197, 0.50)",

    // font-feature-settings: 'clig' off, 'liga' off;
    // font-size: 10px;
    // font-style: normal;
    // font-weight: 400;
    // line-height: 180%
  },
}));

export const SearchDiv = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  position: "relative",
}));
export const PhysicsImageContainer = styled("div")(({ theme }) => ({
  height: "170px",
  width: "300px",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    display: "none !important",
  },
}));
export const PhysicsImgae = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  marginTop: "-20px",
  overflow: "hidden",
  objectPosition: "top-right",
  [theme.breakpoints.down("md")]: {
    display: "none !important",
  },
}));
export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "top",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    paddingRight: "9px",
  },
}));

export const LeftContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  marginTop: "0px",
  justifyContent: "space-evenly",
}));
